import React, {useEffect, useState} from 'react';
import {Button, Container, Stack, Typography} from '@mui/material';
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import DoodleGrid from '../DoodleGrid';
import Center from '../Center';
import {Link} from 'react-router-dom';

const ProfileTabCollected = ({doodlerId, isUsersProfile}) => {
  const [doodles, setDoodles] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (doodlerId) {
      setDoodles([]);
      loadDoodles();
    }
  }, [doodlerId]);

  const loadDoodles = async () => {
    setLoading(true);

    const doodlesSnap = await getDocs(
      query(
        collection(db, 'doodles'),
        where('doodler', '!=', doodlerId),
        where('owner', '==', doodlerId),
        where('deleted', '==', false),
        where('public', '==', true),
        orderBy('doodler', 'asc'),
        orderBy('createdAt', 'desc'),
        limit(12),
      ),
    );

    setDoodles(
      doodlesSnap.docs.map(snap => ({
        id: snap.id,
        ...snap.data(),
      })),
    );

    setLoading(false);
  };

  if (!loading && doodles.length === 0)
    return (
      <Container maxWidth="sm" disableGutters>
        <Center height={300}>
          <Stack spacing={2}>
            {isUsersProfile ? (
              <Typography color="darkGrey">
                To start your collection{' '}
                <Link className="blue-links blue" to="/">
                  browse our artists
                </Link>{' '}
                and commission a Getadoodle today!
              </Typography>
            ) : (
              <Typography color="darkGrey">
                This collection is empty.
              </Typography>
            )}
          </Stack>
        </Center>
      </Container>
    );

  return <DoodleGrid doodles={doodles} />;
};

export default ProfileTabCollected;

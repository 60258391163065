import React, {useEffect, useRef, useState} from 'react';
import {
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Slider,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import {toColor} from '../../utils';
import parse from 'parse-svg-path';
import * as svgScale from 'scale-svg-path';
import serialize from 'serialize-svg-path';
import PageHeader from '../../containers/PageHeader';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import {useParams} from 'react-router-dom';

const DoodlePlayGroundScreen = () => {
  const {doodleId} = useParams();

  const [showCanvas, setShowCanvas] = useState(true);
  const [scale, setScale] = useState(1);
  const paths = useRef([]);

  useEffect(() => {
    fetchPaths();
  }, [showCanvas, scale]);

  const fetchPaths = async () => {
    const doodle = await getDoc(doc(db, 'doodles', doodleId));

    const {CanvasKitInit} = window;

    const ckLoaded = CanvasKitInit({
      locateFile: file => 'https://unpkg.com/canvaskit-wasm@0.19.0/bin/' + file,
    });

    ckLoaded.then(async CanvasKit => {
      const surface = CanvasKit.MakeCanvasSurface('foo');

      const paint = new CanvasKit.Paint();

      paint.setStyle(CanvasKit.PaintStyle.Stroke);
      //paint.setPathEffect(CanvasKit.PathEffect.MakeDash([10, 10]));
      paint.setAntiAlias(true);

      if (paths.current.length === 0) {
        paths.current = await new Promise((resolve, reject) => {
          fetch(
            `https://firebasestorage.googleapis.com/v0/b/getadoodle-69.appspot.com/o/paths%2f${doodleId}.json?alt=media&token=0afb0b7b-b3e7-4ac4-aff3-2aee7909273a`,
          )
            .then(response => response.json())
            .then(async json => {
              if (json.error && json.error.code === 404) {
                const pathDocs = await getDocs(
                  query(
                    collection(db, `doodles/${doodleId}/paths`),
                    orderBy('order', 'asc'),
                  ),
                );

                resolve(pathDocs.docs.map(p => p.data()));
                return;
              }

              resolve(json);
            });
        });
      }

      surface.drawOnce(canvas => {
        canvas.clear(showCanvas ? CanvasKit.WHITE : CanvasKit.TRANSPARENT);

        const lScale =
          (358 / (doodle.data().canvas ? doodle.data().canvas.width : 358)) *
          scale;

        paths.current.forEach(path => {
          const parsedPath = parse(path.path);

          const x = svgScale(parsedPath, lScale);

          switch (path.pathType) {
            case 'dashed':
              paint.setPathEffect(CanvasKit.PathEffect.MakeDash([10, 10]));
              break;
            case 'discreted':
              paint.setPathEffect(CanvasKit.PathEffect.MakeDiscrete(2, 5, 0));
              break;
          }

          paint.setStyle(CanvasKit.PaintStyle.Stroke);

          paint.setStrokeWidth(path.size * lScale);

          // paint.setStrokeCap(StrokeCap.Round);

          const rgbaArray = toColor(path.color)
            .replace(/[^0-9.,]/g, '')
            .split(',')
            .map(a => parseFloat(a) / 255);

          paint.setColor(
            CanvasKit.Color4f(rgbaArray[0], rgbaArray[1], rgbaArray[2], 1),
          );

          canvas.drawPath(CanvasKit.MakePathFromSVGString(serialize(x)), paint);
        });
      });
    });
  };

  return (
    <Container>
      <PageHeader>DOODLE PLAYGROUND</PageHeader>
      <canvas id="foo" width={358 * scale} height={358 * scale} />

      <Container maxWidth="sm">
        <Stack spacing={2}>
          <Button variant="contained" fullWidth onClick={fetchPaths}>
            TEST
          </Button>
          <Slider
            max={10}
            min={0.1}
            step={0.1}
            aria-label="Volume"
            value={scale}
            onChange={(e, newValue) => setScale(newValue)}
          />
          <FormControl fullWidth>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                style={{
                  marginLeft: 0,
                  width: '100%',
                  justifyContent: 'space-between',
                }}
                onChange={() => setShowCanvas(!showCanvas)}
                value={showCanvas}
                control={<Switch color="primary" />}
                label={<Typography variant="text">No Canvas</Typography>}
                labelPlacement="start"
              />
            </FormGroup>
          </FormControl>
        </Stack>
      </Container>
    </Container>
  );
};

export default DoodlePlayGroundScreen;

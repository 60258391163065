import React, {useEffect, useRef, useState} from 'react';
import {createStructuredSelector} from 'reselect';
import {makeSelectUser} from '../../containers/App/selectors';
import {connect} from 'react-redux';
import {
  Avatar,
  Container,
  TextField,
  Typography,
  Stack,
  useTheme,
  LinearProgress,
} from '@mui/material';
import Center from '../../containers/Center';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import {updateUser} from '../../containers/App/actions';
import {LoadingButton} from '@mui/lab';
import {doc, updateDoc} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import FunctionClient from '../../clients/Functions';
import {useNavigate} from 'react-router-dom';
import CropImageDialog from '../../containers/CropImageDialog';

const EditDoodlerScreen = ({user, dispatch}) => {
  const theme = useTheme();
  const [isUploading, setIsUploading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [invalidUsername, setInvalidUsername] = useState(false);
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [displayName, setDisplayName] = useState(user.displayName || '');
  const [username, setUsername] = useState(user.username || '');
  const [bio, setBio] = useState(user.bio || '');
  const charLimit = useRef(140).current;

  const navigate = useNavigate();

  useEffect(() => {
    if (username) {
      const pattern = /^(?=[a-zA-Z0-9._]{2,26}$)(?!.*[_.]{2})[^_.].*[^_.]$/;
      setInvalidUsername(!pattern.test(username));
    } else {
      setInvalidUsername(false);
    }
  }, [username]);

  const handleUpdateAvatar = blob => {
    setFile(null);

    const storage = getStorage();

    const storageRef = ref(storage, `users/${user.uid}/avatar.png`);

    setIsUploading(true);

    const uploadTask = uploadBytesResumable(storageRef, blob);

    uploadTask.on(
      'state_changed',
      snapshot => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
        }
      },
      err => {
        alert(err.message);
        setIsUploading(false);
        setProgress(0);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(avatar => {
          dispatch(updateUser({avatar}));
          setIsUploading(false);
          setProgress(0);
        });
      },
    );
  };

  const saveProfileAsync = async e => {
    if (e) e.preventDefault();
    try {
      setSaving(true);
      await updateDoc(doc(db, 'users', user.uid), {displayName, bio});
      if (username && username !== user.username) {
        await FunctionClient.updateUsernameAsync(username.toLowerCase().trim());
      }
      setSaving(false);
      navigate(`/doodlers/${username || user.id}`);
    } catch (err) {
      setSaving(false);
      alert(err.message);
    }
  };

  return (
    <Container maxWidth="sm">
      <CropImageDialog
        onClose={() => setFile(null)}
        onCroppingComplete={handleUpdateAvatar}
        file={file}
      />
      <br />
      <Center height={300}>
        <div style={{position: 'relative', textAlign: 'center'}}>
          <Stack spacing={2}>
            <Avatar
              src={user.avatar || require('../../assets/avatar.svg')}
              style={{
                height: 161,
                width: 161,
                backgroundColor: 'transparent',
                alignSelf: 'center',
              }}
            />
            <div style={{color: theme.palette.blue, fontWeight: 500}}>
              Change Profile Pic
            </div>
            {isUploading && (
              <LinearProgress value={progress} variant="determinate" />
            )}
          </Stack>
          <input
            onChange={e => setFile(e.target.files[0])}
            type="file"
            className="secret"
          />
        </div>
      </Center>
      <form onSubmit={saveProfileAsync}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            value={displayName}
            label="Name"
            onChange={e => setDisplayName(e.target.value)}
          />
          <TextField
            error={invalidUsername}
            fullWidth
            inputProps={{
              type: 'text',
              autoCapitalize: 'none',
              autoCorrect: 'off',
            }}
            value={username}
            label="Username"
            onChange={e => setUsername(e.target.value)}
            helperText={invalidUsername ? 'Invalid username' : ''}
          />
          <div>
            <TextField
              fullWidth
              multiline
              maxRows={4}
              value={bio}
              onChange={e => {
                if (e.target.value.length > charLimit) {
                  return;
                }
                setBio(e.target.value);
              }}
              label="Bio"
            />
            <div style={{textAlign: 'right'}}>
              <Typography
                color={charLimit === bio.length ? 'red' : 'textSecondary'}>
                {charLimit - bio.length}
              </Typography>
            </div>
          </div>
          <LoadingButton
            disabled={invalidUsername}
            onClick={saveProfileAsync}
            size="large"
            variant="contained"
            fullWidth
            loading={saving}>
            SAVE
          </LoadingButton>
        </Stack>
      </form>
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDoodlerScreen);

import React from 'react';
import {Link} from 'react-router-dom';
import {Box, Typography} from '@mui/material';
import Center from '../Center';
import DoodlerFooter from '../DoodlerFooter';

const DoodleRow = ({doodles, title}) => {
  return (
    <div>
      <Typography variant="h3" paragraph>
        {title}
      </Typography>
      <div className="scrolling-wrapper-container">
        <Box className="scrolling-wrapper">
          {!doodles.length && (
            <Center height={200}>
              <Typography color="darkGrey">
                No Getadoodles to see here
              </Typography>
            </Center>
          )}
          {doodles.map((doodle, index) => (
            <div className="card" key={doodle.id + index}>
              <Link to={`/doodles/${doodle.id}`}>
                <div>
                  <img
                    alt={doodle.title}
                    style={{width: '100%'}}
                    src={doodle.uri}
                  />
                </div>
              </Link>
              <DoodlerFooter doodle={doodle} />
            </div>
          ))}
        </Box>
      </div>
    </div>
  );
};

export default DoodleRow;

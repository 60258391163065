import {
  RECORD_ERROR,
  SET_INITIAL_AUTH_COMPLETE,
  SET_IS_AUTHENTICATING,
  SET_REG_PARAMS,
  SET_USER,
  UPDATE_USER,
  FETCH_NEW_NOTIFICATION_COUNT,
  SET_NEW_NOTIFICATION_COUNT,
} from './constants';

export const setUser = user => ({
  type: SET_USER,
  user,
});

export const updateUser = payload => ({
  type: UPDATE_USER,
  payload,
});

export const setIsAuthenticating = isAuthenticating => ({
  type: SET_IS_AUTHENTICATING,
  isAuthenticating,
});

export const setRegParams = regParams => ({
  type: SET_REG_PARAMS,
  regParams,
});

export const setInitialAuthComplete = initialAuthComplete => ({
  type: SET_INITIAL_AUTH_COMPLETE,
  initialAuthComplete,
});

export const recordError = (err, eventName) => ({
  type: RECORD_ERROR,
  err,
  eventName,
});

export const setNewNotificationCount = newNotificationCount => ({
  type: SET_NEW_NOTIFICATION_COUNT,
  newNotificationCount,
});

export const fetchNewNotificationCount = () => ({
  type: FETCH_NEW_NOTIFICATION_COUNT,
});

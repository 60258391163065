import React from 'react';
import {Link} from 'react-router-dom';
import {Grid} from '@mui/material';
import DoodlerFooter from '../DoodlerFooter';
import DoodleImage from '../../components/DoodleImage';

const DoodleGrid = ({doodles}) => {
  return (
    <div>
      <Grid style={{textAlign: 'center'}} container spacing={1}>
        {doodles.map((doodle, index) => (
          <Grid item xs={6} sm={3} key={doodle.id}>
            <Link
              to={{
                pathname: `/doodles/${doodle.id}`,
                state: {doodles: doodles.slice(index + 1, doodles.length)},
              }}>
              <DoodleImage doodle={doodle} />
            </Link>
            <DoodlerFooter doodle={doodle} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default DoodleGrid;

import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import ReactGA from 'react-ga';
import mixpanel from 'mixpanel-browser';

const usePageTracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!window.location.href.includes('localhost')) {
      ReactGA.initialize('G-5PTMMH59SE');
    }
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search);

      mixpanel.track('Page Viewed', {
        path: location.pathname + location.search,
      });
    }
  }, [initialized, location]);
};

export default usePageTracking;

import React from 'react';
import {ListItemText, Stack, Typography} from '@mui/material';
import {getDoodleUrlById} from '../../utils';
import moment from 'moment';
import {BLOCK_EXPLORER, OPEN_SEA_URI} from '../../constants';
import TruncatedHash from '../../components/TruncatedHash';
import {Link} from 'react-router-dom';

const AccountNFTOrderScreen = ({order}) => {
  return (
    <Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        <div>
          <Link to={`/doodles/${order.doodle}`}>
            <img
              alt={order.title}
              style={{width: 69}}
              src={getDoodleUrlById(order.doodle)}
            />
          </Link>
        </div>
        <Stack>
          <Typography style={{textTransform: 'uppercase', marginTop: -8}}>
            <strong>{order.title}</strong>
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {moment(order.createdAt.toDate()).format('ll')}
          </Typography>
        </Stack>
      </Stack>
      <ListItemText
        primary={
          <Typography>
            <strong>ORDER#</strong> {order.orderNumber}
          </Typography>
        }
      />
      <ListItemText
        primary={
          <Typography style={{whiteSpace: 'nowrap'}}>
            <strong>STATUS</strong>{' '}
            <Typography component="span" className={`status-${order.status}`}>
              <strong>{(order.status || '').toUpperCase()}</strong>
            </Typography>
          </Typography>
        }
      />
      <ListItemText
        primary={
          <Typography>
            <strong>MINT PRICE</strong> ${order.amount / 100}
          </Typography>
        }
      />
      <ListItemText
        primary={
          <Typography>
            <strong>GAS FEE</strong>
          </Typography>
        }
      />
      <ListItemText
        primary={
          <Typography>
            <strong>TOTAL</strong> ${order.amount / 100}
          </Typography>
        }
      />

      <br />
      <div>
        <Typography>
          Congratulations! You're NFT has been minted into your wallet. You will
          now see the artwork under collection tab on your profile.{' '}
          <a
            className="clickable"
            target="_blank"
            href={`${OPEN_SEA_URI}/${order.transaction.contractCall.params.id}`}>
            View on OpenSea
          </a>
        </Typography>
      </div>
      <br />
      <Typography>
        <strong>Transaction Details</strong>
      </Typography>
      <br />
      <Stack spacing={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <strong>Network</strong> <Typography>Ethereum</Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <strong>Transaction</strong>
          <Typography>
            <a
              target="_blank"
              href={`${BLOCK_EXPLORER}/tx/${order.transaction.hash}`}>
              <TruncatedHash>{order.transaction.hash}</TruncatedHash>
            </a>
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <strong>Wallet</strong>{' '}
          <Typography>
            <a
              target="_blank"
              href={`${BLOCK_EXPLORER}/address/${order.transaction.hash}`}>
              <TruncatedHash>
                {order.transaction.contractCall.params._address}
              </TruncatedHash>
            </a>
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <strong>Token Id</strong>{' '}
          <Typography>{order.transaction.contractCall.params.id}</Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <strong>Contract</strong>{' '}
          <Typography>
            <a
              target="_blank"
              href={`${BLOCK_EXPLORER}/address/${order.transaction.to}`}>
              <TruncatedHash>{order.transaction.to}</TruncatedHash>
            </a>
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <strong>Block</strong>{' '}
          <Typography>{order.transaction.blockNumber}</Typography>
        </Stack>
      </Stack>
      <br />
    </Stack>
  );
};

export default AccountNFTOrderScreen;

import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Box, Typography} from '@mui/material';
import {collection, getDocs, orderBy, query, where} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import {getScale} from '../../utils';

const ProductsRow = ({doodle}) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getDocs(
      query(
        collection(db, `doodles/${doodle.id}/products`),
        orderBy('order', 'asc'),
      ),
    )
      .then(snap => {
        setProducts(snap.docs.map(p => p.data()));
      })
      .catch(err => {
        console.log(err);
      });
  }, [doodle]);

  const getTitle = _product => {
    if (_product.productId.indexOf('framed-print') >= 0) return 'Framed Print';
    if (_product.productId.indexOf('poster') >= 0) return 'Print';
    if (_product.productId.indexOf('shirt') >= 0) return 'Shirt';
  };

  return (
    <div>
      <Typography variant="h3" paragraph>
        Purchase Merch
      </Typography>
      <div className="scrolling-wrapper-container" style={{height: 340}}>
        <Box className="scrolling-wrapper">
          {products.map(product => (
            <div
              className="card"
              style={{overflow: 'visible'}}
              key={product.productId}>
              <Link to={`/doodles/${doodle.id}/products/${product.productId}`}>
                <div
                  style={{
                    display: 'flex',
                    overflow: 'hidden',
                    backgroundColor:
                      product.type === 'shirt'
                        ? 'rgb(255 255 255)'
                        : 'transparent',
                  }}>
                  <img
                    alt={product.title}
                    style={{
                      width: '100%',
                      transform: `scale(${getScale(product)})`,
                    }}
                    src={product.thumbnail}
                  />
                </div>
              </Link>
              <Link to={`/doodles/${doodle.id}/products/${product.productId}`}>
                <Typography style={{marginTop: 10}} variant="h5">
                  <strong>{getTitle(product)}</strong>{' '}
                </Typography>
                <Typography color="secondary" variant="span">
                  <strong>${product.price}</strong>
                </Typography>
              </Link>
            </div>
          ))}
        </Box>
      </div>
    </div>
  );
};

export default ProductsRow;

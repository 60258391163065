import {useEffect} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {makeSelectProvider, makeSelectWeb3} from './selectors';
import {setConnectedWallet, setChain, setWeb3, setProvider} from './actions';
import Web3 from 'web3';

const Web3Wrapper = ({children, dispatch, provider}) => {
  useEffect(() => {
    if (!provider) return;

    provider.on('accountsChanged', accounts => {
      if (accounts.length > 0) {
        dispatch(setConnectedWallet(accounts[0]));
      }
    });

    provider.on('chainChanged', chainId => {
      dispatch(setChain(chainId));
    });
  }, [provider]);

  useEffect(() => {
    let web3: any;
    if (window.ethereum) {
      web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      web3 = new Web3(window.web3.currentProvider);
    }

    if (web3) {
      web3.eth.getAccounts().then(async accounts => {
        if (accounts.length > 0) {
          dispatch(setConnectedWallet(accounts[0]));
          dispatch(setWeb3(web3));
        }
      });
    }
  }, []);

  return children;
};

const mapStateToProps = createStructuredSelector({
  provider: makeSelectProvider(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Web3Wrapper);

import React, {useEffect, useRef, useState} from 'react';
import {TextField, InputAdornment, IconButton} from '@mui/material';
import {addDoc, collection} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import {createStructuredSelector} from 'reselect';
import {makeSelectUser} from '../App/selectors';
import {connect} from 'react-redux';
import {setIsAuthenticating} from '../App/actions';
import mixpanel from 'mixpanel-browser';
import {SendRounded} from '@mui/icons-material';
import theme from '../../theme';

const DoodleCommentEnterText = ({
  doodleId,
  user,
  dispatch,
  replyToComment,
  onClearReply,
  onNewComment,
}) => {
  const [text, setText] = useState('');
  const [placeholder, setPlaceholder] = useState('Add comment...');
  const inputRef = useRef(null);

  useEffect(() => {
    if (replyToComment) {
      setPlaceholder(`Reply to ${replyToComment.displayName} `);
      inputRef.current.focus();
    }
  }, [replyToComment]);

  const resetState = () => {
    if (replyToComment) onClearReply();
    setText('');
    setPlaceholder('Add comment...');
  };

  const handleSubmitText = () => {
    if (!user) {
      dispatch(setIsAuthenticating(true));
      return;
    }

    if (text.length <= 0) {
      alert('Cannot submit empty comment');
      return;
    }

    let ref = collection(db, `/doodles/${doodleId}/comments`);

    if (replyToComment) {
      ref = collection(
        db,
        `/doodles/${doodleId}/comments/${replyToComment.id}/replies`,
      );
    }

    const payload = {
      text,
      createdAt: new Date().toUTCString(),
      userId: user.id,
      displayName: user.displayName,
    };

    resetState();

    addDoc(ref, payload)
      .then(newDoc => {
        payload.id = newDoc.id;
        onNewComment(payload);
        mixpanel.track('Comment', {});
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <div style={{width: '100%', padding: 5, marginBottom: 5}}>
      <TextField
        inputRef={inputRef}
        onBlur={() => {
          if (replyToComment) {
            resetState();
          }
        }}
        size="small"
        type="text"
        autoComplete="off"
        placeholder={placeholder}
        fullWidth
        id="outlined-multiline-static"
        hiddenLabel
        value={text}
        onChange={e => {
          setText(e.target.value);
        }}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            handleSubmitText();
          }
        }}
        InputProps={{
          disableUnderline: true,
          autoComplete: 'off',
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disabled={text.length <= 0}
                onClick={handleSubmitText}
                size="small"
                aria-label="toggle password visibility"
                edge="end">
                <SendRounded
                  style={{
                    color:
                      text.length > 0 ? theme.palette.primary.main : '#f1f1f1',
                  }}
                />
              </IconButton>
            </InputAdornment>
          ),
          style: {
            fontSize: 14,
            borderRadius: 8,
            marginLeft: -3,
            padding: '8px 15px',
            marginBottom: 8,
            backgroundColor: 'white',
            borderColor: 'rgb(212 212 212)',
            borderWidth: 1,
            borderStyle: 'solid',
          },
        }}
        multiline
        variant="filled"
      />
    </div>
  );
};

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DoodleCommentEnterText);

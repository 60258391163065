import React, {useEffect, useState} from 'react';
import {Avatar, Stack, Typography} from '@mui/material';
import {getAvatarById} from '../../utils';
import {getDoc, doc} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import FollowButton from '../FollowButton';
import {Link} from 'react-router-dom';
import FallbackAvatar from '../../components/FallbackAvatar';

const DehydratedUserLineItem = ({userId}) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    getDoc(doc(db, 'users', userId))
      .then(doc => {
        if (doc.exists()) {
          setUser({...doc.data(), id: doc.id});
        }
      })
      .catch(error => {
        console.info('Error getting document:', error);
      });
  }, [userId]);

  if (!user) return null;

  return (
    <Stack justifyContent="space-between" direction="row">
      <Link to={`/doodlers/${userId}`}>
        <Stack spacing={2} alignItems="center" direction="row">
          <Avatar src={getAvatarById(userId)}>
            <FallbackAvatar />
          </Avatar>
          <Stack>
            <Typography>{user.displayName || user.username}</Typography>
          </Stack>
        </Stack>
      </Link>
      <FollowButton noBorder followUser={user} />
    </Stack>
  );
};

export default DehydratedUserLineItem;

import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {LoadingButton} from '@mui/lab';
import {useEffect, useState} from 'react';
import {doc, setDoc} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import {isValidEmail} from '../../utils';
import {createStructuredSelector} from 'reselect';
import {makeSelectUser} from '../App/selectors';
import {connect} from 'react-redux';

const NotifyWhenAvailableDialog = ({open, onClose, doodler, user}) => {
  const [working, setWorking] = useState(false);
  const [success, setSuccess] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (!open) {
      setEmail(user ? user.email : '');
      setSuccess(false);
      setWorking(false);
      setInvalidEmail(false);
    }
  }, [open]);

  const handleAddNotification = async () => {
    setWorking(true);

    if (!isValidEmail(email)) {
      setInvalidEmail(true);
      setWorking(false);
      return;
    }

    if (invalidEmail) {
      setInvalidEmail(false);
    }

    await setDoc(
      doc(db, `reminders`, doodler.id),
      {[email]: true},
      {merge: true},
    );

    setWorking(false);

    setSuccess(true);

    setTimeout(onClose, 3000);
  };

  console.log(invalidEmail);

  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>KEEP ME IN THE LOOP</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {doodler.displayName} is temporarily unavailable - enter your email
            address to get notified when {doodler.displayName} back!
          </DialogContentText>

          <br />

          <TextField
            value={email}
            helperText={invalidEmail ? 'Invalid email.' : ''}
            onChange={e => setEmail(e.target.value)}
            autoFocus
            id="name"
            label="Email Address"
            type="email"
            fullWidth
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>CANCEL</Button>
          <LoadingButton
            color={success ? 'success' : 'primary'}
            variant="contained"
            loading={working}
            onClick={handleAddNotification}>
            {success ? 'SUCCESS' : 'SUBMIT'}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});

export default connect(mapStateToProps, null)(NotifyWhenAvailableDialog);

import React, {useEffect, useState} from 'react';
import PageHeader from '../PageHeader';
import {
  Container,
  Divider,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import DehydratedUserLineItem from '../DehydratedUserLineItem';
import Center from '../Center';

const DoodlerFollowersList = ({doodler}) => {
  const [followers, setFollowers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadFollowing();
  }, [doodler]);

  const loadFollowing = async () => {
    setLoading(true);

    const querySnapshot = await getDocs(
      query(
        collection(db, 'follows'),
        where('followed', '==', doodler.id),
        where('following', '==', true),
        orderBy('createdAt', 'desc'),
        limit(20),
      ),
    );

    setFollowers(querySnapshot.docs);
    setLoading(false);
  };

  return (
    <Container maxWidth="sm">
      <PageHeader>Followers</PageHeader>
      {loading && <LinearProgress />}
      {!loading && followers.length === 0 && (
        <Center height={150}>
          <Typography color="darkGrey">No followers yet</Typography>
        </Center>
      )}
      <Stack spacing={2}>
        {followers.map(follower => (
          <React.Fragment key={follower.id}>
            <DehydratedUserLineItem userId={follower.data().follower} />
            <Divider />
          </React.Fragment>
        ))}
      </Stack>
    </Container>
  );
};

export default DoodlerFollowersList;

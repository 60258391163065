import React from 'react';
import theme from '../../theme';
import {IconButton, Typography} from '@mui/material';
import {connect} from 'react-redux';
import {setShowDrawer} from '../../containers/AppDrawer/actions';

const CloseDrawer = ({dispatch}) => {
  return (
    <IconButton
      onClick={() => dispatch(setShowDrawer(false))}
      variant="bordered"
      style={{
        width: 26,
        height: 26,
        backgroundColor: theme.palette.white,
        border: 'solid',
        borderColor: 'rgb(212 212 212)',
        borderWidth: 1,
        zIndex: 999,
      }}>
      <Typography style={{color: 'rgb(212 212 212)'}} variant="caption">
        X
      </Typography>
    </IconButton>
  );
};

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(null, mapDispatchToProps)(CloseDrawer);

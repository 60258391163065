import React, {useEffect, useState} from 'react';
import {
  Box,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  LinearProgress,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {createStructuredSelector} from 'reselect';
import {makeSelectUser} from '../../containers/App/selectors';
import {connect} from 'react-redux';
import {doc, getDoc} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import PageHeader from '../../containers/PageHeader';
import AddressForm from '../../containers/AddressForm';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import FunctionClient from '../../clients/Functions';
import {LoadingButton} from '@mui/lab';
import {setIsAuthenticating} from '../../containers/App/actions';
import mixpanel from 'mixpanel-browser';
import {useNavigate, useParams} from 'react-router-dom';

const DoodlePurchaseProductScreen = ({user, dispatch}) => {
  const {variantId, doodleId} = useParams();

  const [product, setProduct] = useState(null);
  const [doodle, setDoodle] = useState(null);
  const [cardEntryComplete, setCardEntryComplete] = useState(false);
  const [address, setAddress] = useState(false);
  const [shipping, setShipping] = useState(0);
  const [shippingMethod, setShippingMethod] = useState(null);
  const [tax, setTax] = useState(0);
  const [total, setTotal] = useState(0);
  const [shippingMethods, setShippingMethods] = useState([]);
  const [loadingMethods, setLoadingMethods] = useState(false);
  const [creatingOrder, setCreatingOrder] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const navigate = useNavigate();

  useEffect(() => {
    fetchProductsAsync();
  }, []);

  useEffect(() => {
    if (address) {
      updateShippingAsync();
    }
  }, [address]);

  useEffect(() => {
    if (shippingMethod) {
      setShipping(parseFloat(shippingMethod.rate));
      updateTaxesAsync();
    }
  }, [shippingMethod]);

  useEffect(() => {
    if (product) setTotal(product.price + shipping + tax);
  }, [tax, shipping, product]);

  const updateShippingAsync = async () => {
    try {
      setLoadingMethods(true);

      const res = await FunctionClient.fetchShippingAsync({
        recipient: {
          country_code: address.country,
          state_code: address.state,
          city: address.city,
          zip: address.zip,
        },
        items: [{variant_id: product.variant_ids[0], quantity: 1}],
      });

      setLoadingMethods(false);

      setShippingMethod(res[0]);

      setShippingMethods(res);
    } catch (err) {
      setLoadingMethods(false);
      alert(err.message);
    }
  };

  const updateTaxesAsync = async () => {
    try {
      const {required, rate, shipping_taxable} =
        await FunctionClient.fetchTaxRatesAsync({
          recipient: {
            city: address.city,
            state_code: address.state,
            country_code: address.country,
            zip: address.zip,
          },
        });

      let sub = product.price;

      if (shipping_taxable) sub += parseFloat(shippingMethod.rate);

      if (required) setTax(sub * rate);
    } catch (err) {
      setLoadingMethods(false);
      alert(err.message);
    }
  };

  const fetchProductsAsync = async () => {
    const [productsSnap, doodleSnap] = await Promise.all([
      getDoc(doc(db, 'products', doodleId)),
      getDoc(doc(db, 'doodles', doodleId)),
    ]);

    if (!productsSnap.exists()) return;

    if (doodleSnap.exists()) setDoodle(doodleSnap.data());

    const index = productsSnap
      .data()
      .options.findIndex(option =>
        option.variant_ids.includes(parseInt(variantId, 10)),
      );

    if (index >= 0) setProduct(productsSnap.data().options[index]);
  };

  const handlePurchasePrint = async () => {
    try {
      if (!user) {
        dispatch(setIsAuthenticating(true));
        return;
      }

      mixpanel.track('Attempting Print Purchase', {
        product: product.title,
      });

      setCreatingOrder(true);

      const {token} = await stripe.createToken(
        elements.getElement(CardElement),
      );

      const payload = {
        shipping: shippingMethod.code,
        recipient: {
          name: user.displayName,
          address1: address.address,
          city: address.city,
          state_code: address.state,
          country_code: address.country,
          zip: address.zip,
          email: user.email,
        },
        items: [
          {
            quantity: 1,
            sync_variant_id: product.sync_variant_id,
            retail_price: product.price,
          },
        ],
        retail_costs: {
          currency: 'usd',
          shipping,
          tax,
          subtotal: product.price,
          total,
        },
      };

      const res = await FunctionClient.createPrintfulOrderAsync({
        doodleId,
        doodleProduct: product,
        payload,
        token,
      });

      navigate(`/account/orders/${res.id}`);

      mixpanel.track('Print Purchased', {
        product: product.title,
      });

      setCreatingOrder(false);
    } catch (err) {
      setCreatingOrder(false);
      alert(err.message);
    }
  };

  const isMobile = useMediaQuery('(max-width:600px)');

  if (!product) return <LinearProgress />;

  return (
    <Container maxWidth="sm">
      <PageHeader>Purchase Print</PageHeader>
      <Stack spacing={2}>
        <Stack alignItems="center" direction="row">
          <img
            style={{width: 100, transform: 'scale(1.45)'}}
            src={product.thumbnail}
          />
          <Stack style={{paddingLeft: 12}}>
            <Typography>
              <strong>{doodle.title || doodle.name}</strong>
            </Typography>
            <Typography variant="caption">{product.title}</Typography>
            <Typography>${product.price}</Typography>
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography paragraph>Shipping Address</Typography>
          {Boolean(address) && (
            <Typography
              className="clickable"
              onClick={() => setAddress(null)}
              color="blue">
              Edit
            </Typography>
          )}
        </Stack>
        {Boolean(address) && [
          <Typography>{address.address}</Typography>,
          <Typography>
            {address.city}, {address.state}
          </Typography>,
        ]}
        <div style={{display: address ? 'none' : 'block'}}>
          <AddressForm
            onComplete={_address => {
              setAddress(_address);
            }}
          />
        </div>
      </Stack>
      <br />
      {loadingMethods && <CircularProgress />}
      {shippingMethods.length > 0 && (
        <Stack spacing={2}>
          <Typography>Shipping Methods</Typography>
          <FormControl>
            {shippingMethods.map(method => (
              <FormControlLabel
                key={method.id}
                control={
                  <Checkbox
                    onChange={e => {
                      if (e.target.checked) setShippingMethod(method);
                    }}
                    checked={shippingMethod.id === method.id}
                  />
                }
                label={
                  <Typography variant="caption">
                    ${method.rate} {method.name}
                  </Typography>
                }
              />
            ))}
          </FormControl>
        </Stack>
      )}
      <br />
      {Boolean(address) && (
        <Stack spacing={2}>
          <Typography>Payment</Typography>
          <Box border className="stripe-box">
            <CardElement
              onChange={status => setCardEntryComplete(status.complete)}
              options={{
                style: {
                  base: {
                    fontSize: isMobile ? '16px' : '22px',
                    color: 'rgba(0, 0, 0, 0.87)',
                    lineHeight: isMobile ? '28px' : '36px',
                    height: isMobile ? 28 : 36,
                    fontFamily: 'Saira, Avenir, Helvetica',
                  },
                },
              }}
            />
          </Box>
          <Stack direction="row" justifyContent="space-between">
            <Typography bold>Print</Typography>
            <Typography>${product.price.toFixed(2)}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography bold>Shipping</Typography>
            <Typography>${shipping.toFixed(2)}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography bold>Tax</Typography>
            <Typography>${tax.toFixed(2)}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography bold>Total</Typography>
            <Typography>${total.toFixed(2)}</Typography>
          </Stack>
          <LoadingButton
            loading={creatingOrder}
            onClick={handlePurchasePrint}
            fullWidth
            variant="contained">
            {user ? 'PURCHASE' : 'SIGN IN FIRST'}
          </LoadingButton>
          <Typography variant="caption">*All print sales are final</Typography>
        </Stack>
      )}
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DoodlePurchaseProductScreen);

import {combineReducers} from 'redux';
import {appReducer} from '../containers/App/reducers';
import {web3Reducer} from '../containers/Web3Wrapper/reducers';
import {appDrawerReducer} from '../containers/AppDrawer/reducers';

const initialState = {};

const reducers = function (state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
};

export default combineReducers({
  app: appReducer,
  web3: web3Reducer,
  appDrawer: appDrawerReducer,
  reducers,
});

import React, {useState, useEffect, useCallback} from 'react';
import {
  Box,
  Button,
  Container,
  LinearProgress,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import PageHeader from '../../containers/PageHeader';
import {createStructuredSelector} from 'reselect';
import {makeSelectUser} from '../../containers/App/selectors';
import {connect} from 'react-redux';
import {doc, getDoc} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import {connectWeb3} from '../../containers/Web3Wrapper/actions';
import {
  makeSelectConnectedWallet,
  makeSelectWeb3,
} from '../../containers/Web3Wrapper/selectors';
import FunctionClient from '../../clients/Functions';
import {LoadingButton} from '@mui/lab';
import {onReceipt} from '../../utils';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import GetadoodleContract from '../../contracts/rinkeby/Getadoodle.json';
import {useNavigate} from 'react-router-dom';
import {BLOCK_EXPLORER} from '../../constants';
import CoinGeckoClient from '../../clients/CoinGeckoClient';

const DoodleMintNFTScreen = ({
  web3,
  doodleId,
  dispatch,
  connectedWallet,
  user,
}) => {
  const [doodle, setDoodle] = useState(null);
  const [isMinting, setIsMinting] = useState(false);
  const [transaction, setTransaction] = useState(false);
  const [cardEntryComplete, setCardEntryComplete] = useState(false);
  const [loadingButtonText, setLoadingButtonText] = useState('');
  const [amount, setAmount] = useState(10000);
  const [gasPrice, setGasPrice] = useState(0);

  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    fetchDoodleAsync().catch(err => console.error(err));
  }, [fetchDoodleAsync]);

  useEffect(() => {
    if (!web3) return;
    updatePrices();

    const interval = setInterval(() => {
      updatePrices();
    }, 5000);

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [web3]);

  const updatePrices = async () => {
    const gasPrice = await web3.eth.getGasPrice();
    const gasLimit = 146632;
    const {ethereum} = await CoinGeckoClient.getEthereumToUsdAsync();

    const _total =
      Math.ceil(
        gasLimit * gasPrice * ethereum.usd * 0.000000000000000001 * 100,
      ) / 100;

    setGasPrice(_total);
  };

  const fetchDoodleAsync = useCallback(async () => {
    try {
      const docRef = doc(db, 'doodles', doodleId);

      const docSnap = await getDoc(docRef);

      if (user && docSnap.data().doodler === user.id) {
        setAmount(100);
      } else {
        setAmount(parseInt(docSnap.data().marketplacePrice, 10));
      }

      setDoodle({
        ...docSnap.data(),
        id: docSnap.id,
      });
    } catch (err) {
      console.log(err.message);
    }
  }, [doodleId]);

  const handlePurchaseMint = async () => {
    try {
      setLoadingButtonText('MINTING NFT');

      setIsMinting(true);

      const {token} = await stripe.createToken(
        elements.getElement(CardElement),
      );

      const {hash, orderId} = await FunctionClient.mintTokenAsync({
        wallet: connectedWallet,
        doodle: doodleId,
        token: token.id,
      });

      setTransaction(hash);

      setLoadingButtonText('TRANSACTION PENDING');

      await onReceipt(web3, hash);

      setIsMinting(false);

      setTimeout(() => {
        navigate(`/account/orders/${orderId}`);
      }, 2000);
    } catch (err) {
      setIsMinting(false);
      alert(err.message);
      console.log(err.message);
    }
  };

  const isMobile = useMediaQuery('(max-width:600px)');

  if (!doodle) return <LinearProgress />;

  return (
    <Container maxWidth="sm">
      <PageHeader>PURCHASE NFT</PageHeader>
      <Stack spacing={2} alignItems="center" direction="row">
        <img alt="Doodle" style={{width: 100}} src={doodle.uri} />
        <Stack>
          <Typography variant="h5">{doodle.title || doodle.name}</Typography>
          <Typography>Artist: {doodle.doodlerName}</Typography>
        </Stack>
      </Stack>
      <br />
      <Stack spacing={2}>
        {connectedWallet && (
          <Stack direction="horizontal" justifyContent="space-between">
            <Typography>
              <strong>NETWORK</strong>
            </Typography>
            <Typography>Ethereum</Typography>
          </Stack>
        )}
        <Stack
          direction="horizontal"
          alignItems="center"
          justifyContent="space-between">
          <Typography>
            <strong>CONTRACT</strong>
          </Typography>
          <Typography>
            <a
              target="_blank"
              href={`${BLOCK_EXPLORER}/address/${GetadoodleContract.address}`}>
              {GetadoodleContract.address.slice(0, 6)}...
              {GetadoodleContract.address.slice(-6)}
            </a>
          </Typography>
        </Stack>
        <Stack
          direction="horizontal"
          alignItems="center"
          justifyContent="space-between">
          <Typography>
            <strong>WALLET</strong>
          </Typography>
          <Typography>
            {connectedWallet ? (
              <a
                target="_blank"
                href={`${BLOCK_EXPLORER}/address/${connectedWallet}`}>
                {connectedWallet.slice(0, 6)}...{connectedWallet.slice(-6)}
              </a>
            ) : (
              <Typography>N/A</Typography>
            )}
          </Typography>
        </Stack>
        <Stack direction="horizontal" justifyContent="space-between">
          <Typography>
            <strong>MINT PRICE</strong>
          </Typography>
          <Typography>${amount}</Typography>
        </Stack>
        <Stack direction="horizontal" justifyContent="space-between">
          <Typography>
            <strong>GAS FEE</strong>
          </Typography>
          <Typography>{gasPrice ? `$${gasPrice}` : 'N/A'}</Typography>
        </Stack>
        <Stack direction="horizontal" justifyContent="space-between">
          <Typography>
            <strong>TOTAL</strong>
          </Typography>
          <Typography>{gasPrice ? `$${amount + gasPrice}` : 'N/A'}</Typography>
        </Stack>
        {connectedWallet && (
          <Box border className="stripe-box">
            <CardElement
              onChange={status => setCardEntryComplete(status.complete)}
              options={{
                style: {
                  base: {
                    fontSize: isMobile ? '16px' : '22px',
                    color: 'rgba(0, 0, 0, 0.87)',
                    lineHeight: isMobile ? '28px' : '36px',
                    height: isMobile ? 28 : 36,
                    fontFamily: 'Saira, Avenir, Helvetica',
                  },
                },
              }}
            />
          </Box>
        )}
        {transaction && (
          <Stack>
            <Typography>
              Transaction Status:{' '}
              {isMinting ? (
                <span className="status-pending">Pending</span>
              ) : (
                <span className="status-fulfilled">Confirmed</span>
              )}
            </Typography>
            <Typography variant="caption">
              <a target="_blank" href={`${BLOCK_EXPLORER}/tx/${transaction}`}>
                {transaction}
              </a>
            </Typography>
          </Stack>
        )}

        {!connectedWallet && (
          <Button variant="contained" onClick={() => dispatch(connectWeb3())}>
            CONNECT WALLET
          </Button>
        )}

        {connectedWallet && (
          <div style={{width: '100%'}}>
            <LoadingButton
              loadingIndicator={loadingButtonText}
              disabled={!connectedWallet || !cardEntryComplete}
              fullWidth
              loading={isMinting}
              onClick={handlePurchaseMint}
              variant="contained">
              MINT NFT
            </LoadingButton>
          </div>
        )}

        <Typography paragraph>
          <strong>FAQ's</strong>
        </Typography>
        <Typography>
          <strong>What is an NFT?</strong>
        </Typography>
        <Typography>
          <a
            className="clickable"
            target="_blank"
            href="https://support.opensea.io/hc/en-us/articles/360063450733-What-is-a-Non-Fungible-Token-NFT-">
            Learn
          </a>
        </Typography>
        <Typography>
          <strong>What is a gas fee?</strong>
        </Typography>
        <Typography>
          Gas is the amount paid to the ethereum network to mint your NFT.
        </Typography>
      </Stack>
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  web3: makeSelectWeb3(),
  connectedWallet: makeSelectConnectedWallet(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DoodleMintNFTScreen);

import {getScale} from '../../utils';
import React from 'react';

const ProductImage = ({product, thumbnail, noClick}) => (
  <div
    onClick={() => {
      if (noClick) return;
      const image = new Image();
      image.src = thumbnail;
      const w = window.open('');
      w.document.write(image.outerHTML);
    }}
    style={{
      cursor: 'pointer',
      backgroundSize: 'cover',
      backgroundColor: product.type === 'shirt' ? `#ffffff` : 'transparent',
      overflow: 'hidden',
      display: 'flex',
    }}>
    <img
      style={{
        transform: `scale(${getScale(product)})`,
      }}
      src={thumbnail}
    />
  </div>
);

export default ProductImage;

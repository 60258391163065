import React, {useState} from 'react';
import {
  Button,
  CircularProgress,
  Container,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import {getAuth, sendPasswordResetEmail} from 'firebase/auth';
import {useNavigate} from 'react-router-dom';
import PageHeader from '../../containers/PageHeader';

const ForgotPasswordScreen = () => {
  const [email, setEmail] = useState('');
  const [sendSuccess, setSendSuccess] = useState(false);
  const [working, setWorking] = useState(false);
  const [error, setError] = useState(false);

  const handleResetPassword = () => {
    setError(false);
    const auth = getAuth();
    setWorking(true);
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setSendSuccess(true);
        setWorking(false);
      })
      .catch(error => {
        setError(error.message);
        setWorking(false);
      });
  };

  const theme = useTheme();

  const navigate = useNavigate();

  return (
    <Container maxWidth="sm">
      <PageHeader>Forgot Password</PageHeader>
      <Stack spacing={3}>
        <TextField
          required
          disabled={sendSuccess}
          value={email}
          onChange={e => setEmail(e.target.value)}
          label="Email"
          type="email"
        />
        <Button
          disabled={sendSuccess || working}
          size="large"
          variant="contained"
          onClick={handleResetPassword}
          fullWidth>
          {working ? <CircularProgress size={24} /> : 'Request Password Reset'}
        </Button>
        <Typography variant="text">
          <span
            onClick={() => {
              navigate('/login');
            }}
            className="clickable">
            Back to login.
          </span>
        </Typography>
        {sendSuccess && (
          <Typography variant="text" color="green">
            Check your email for reset password instructions.
          </Typography>
        )}
        {error && (
          <Typography variant="text" style={{color: theme.palette.red}}>
            {error}
          </Typography>
        )}
      </Stack>
    </Container>
  );
};
export default ForgotPasswordScreen;

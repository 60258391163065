import React, {useEffect, useState} from 'react';
import PageHeader from '../PageHeader';
import {
  Container,
  Divider,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import DehydratedUserLineItem from '../DehydratedUserLineItem';
import Center from '../Center';

const DoodlerFollowingList = ({doodler}) => {
  const [followers, setFollowers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadFollowings();
  }, [doodler]);

  const loadFollowings = async () => {
    try {
      setLoading(true);
      const querySnapshot = await getDocs(
        query(
          collection(db, 'follows'),
          where('follower', '==', doodler.id),
          where('following', '==', true),
          orderBy('createdAt', 'desc'),
          limit(20),
        ),
      );

      setFollowers(querySnapshot.docs);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container maxWidth="sm">
      <PageHeader>Following</PageHeader>
      {loading && <LinearProgress />}
      {!loading && followers.length === 0 && (
        <Center height={150}>
          <Typography color="darkGrey">Not following anybody yet</Typography>
        </Center>
      )}
      <Stack spacing={2}>
        {followers.map(follower => (
          <React.Fragment key={follower.id}>
            <DehydratedUserLineItem userId={follower.data().followed} />
            <Divider />
          </React.Fragment>
        ))}
      </Stack>
    </Container>
  );
};

export default DoodlerFollowingList;

import {Button, Grid, Stack, Typography} from '@mui/material';
import {Link} from 'react-router-dom';
import {getDoodleUrlById} from '../../utils';
import moment from 'moment';
import React, {useCallback, useEffect, useState} from 'react';
import {doc, getDoc} from 'firebase/firestore';
import {db} from '../../clients/Firebase';

const DoodleOrderCard = ({doodleId, order}) => {
  const [doodle, setDoodle] = useState(null);

  const fetchDoodle = useCallback(async () => {
    const docSnap = await getDoc(doc(db, 'doodles', doodleId));

    setDoodle({
      id: docSnap.id,
      ...docSnap.data(),
    });
  }, [doodleId]);

  useEffect(() => {
    fetchDoodle().catch(err => console.error(err));
  }, [doodleId]);

  if (!doodle) return null;

  return (
    <Stack spacing={2}>
      <Typography paragraph>
        <strong>YOUR GETADOODLE HAS ARRIVED!</strong>
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={4}>
          <Link to={`/doodles/${doodle.id}`}>
            <img width="100%" src={getDoodleUrlById(doodle.id)} />
          </Link>
        </Grid>
        <Grid xs={12} item sm={8}>
          <Stack sx={{paddingLeft: {xs: 0, sm: '18px'}}} spacing={2}>
            <Typography variant="text">
              <strong>Title</strong> {doodle.title || doodle.name}
            </Typography>
            <Typography variant="text">
              <strong>Artist</strong> {doodle.doodlerName}
            </Typography>
            <Typography variant="text">
              <strong>Year</strong> {moment(doodle.createdAt.toDate()).year()}
            </Typography>
            <Typography
              variant="text"
              style={{whiteSpace: 'nowrap', overflow: 'hidden'}}>
              <strong>Certificate</strong>{' '}
              <a href={doodle.ipfsFriendlyUrl} target="_blank">
                {(doodle.ipfsHash || '').slice(0, 20) + '...'}
              </a>
            </Typography>
            {order.notes && (
              <Typography color="textSecondary">"{order.notes}"</Typography>
            )}
          </Stack>
        </Grid>
      </Grid>
      <Link to={`/doodles/${doodle.id}`}>
        <Button fullWidth size="large" variant="contained">
          VIEW
        </Button>
      </Link>
      {!order.hasBeenReviewed && (
        <Link to={`/account/orders/${doodle.id}/review`}>
          <Button fullWidth size="large" variant="contained">
            LEAVE REVIEW
          </Button>
        </Link>
      )}
      {order.hasBeenReviewed && (
        <Link to={`/account/orders/${doodle.id}/review`}>
          <Button fullWidth size="large" variant="contained">
            EDIT REVIEW
          </Button>
        </Link>
      )}
    </Stack>
  );
};

export default DoodleOrderCard;

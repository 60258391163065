import {fromJS} from 'immutable';
import {SET_COMMENT_DOODLE_ID, SET_SHOW_DRAWER} from './constants';

const initialState = fromJS({
  isDrawerShowing: false,
  commentDoodleId: null,
});

function appDrawerReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SHOW_DRAWER:
      return {
        ...state,
        isDrawerShowing: action.isDrawerShowing,
      };

    case SET_COMMENT_DOODLE_ID:
      return {
        ...state,
        commentDoodleId: action.commentDoodleId,
      };

    default:
      return state;
  }
}

export {appDrawerReducer};

import {Box, Container, Stack, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import ContestEntries from '../../containers/ContestEntries';
import {doc, getDoc} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import DoodleDetailColumns from '../../containers/DoodleDetailColumns';
import ContestHonorableMentions from '../../containers/ContestHonorableMentions';
const ContestCompleteScreen = ({contest}) => {
  const [doodle, setDoodle] = useState(null);

  useEffect(() => {
    getDoc(doc(db, 'doodles', contest.winningDoodle))
      .then(doc => {
        setDoodle({id: doc.id, ...doc.data()});
      })
      .catch(err => console.error(err));
  }, [contest]);

  if (!doodle) {
    return null;
  }

  return (
    <Container maxWidth="lg">
      <Stack direction="column" spacing={2}>
        <Box
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}>
          <Typography textAlign="center" variant="h3">
            {contest.title.toUpperCase()}
          </Typography>
          <Typography textAlign="center" variant="h3">
            "{contest.description}"
          </Typography>
          <Typography textAlign="center" color="secondary" variant="h3">
            WINNING ENTRY
          </Typography>
        </Box>

        <DoodleDetailColumns marginLeft={-6} completed doodle={doodle} />

        <ContestHonorableMentions contest={contest} winningDoodle={doodle} />

        <ContestEntries contest={contest} />
      </Stack>
    </Container>
  );
};

export default ContestCompleteScreen;

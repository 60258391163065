import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {setCommentDoodleId, setShowDrawer} from '../AppDrawer/actions';
import {IconButton} from '@mui/material';

const CommentButton = ({dispatch, doodle}) => {
  useEffect(() => {
    dispatch(setCommentDoodleId(doodle.id));

    return () => {
      dispatch(setCommentDoodleId(null));
    };
  }, [doodle, dispatch]);

  return (
    <IconButton
      onClick={() => {
        dispatch(setShowDrawer(true));
      }}>
      <img
        style={{width: 28, height: 28}}
        src={require('../../assets/icons/comment.png')}
        alt="comment"
      />
    </IconButton>
  );
};

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(null, mapDispatchToProps)(CommentButton);

import {createSelector} from 'reselect';

const selectContracts = state => state.web3;

const makeSelectWeb3 = () => createSelector(selectContracts, web3 => web3.web3);

const makeSelectProvider = () =>
  createSelector(selectContracts, web3 => web3.provider);

const makeSelectChainId = () =>
  createSelector(selectContracts, web3 => web3.chainId);

const makeSelectConnectedWallet = () =>
  createSelector(selectContracts, web3 => web3.connectedWallet);

const makeSelectEthBalance = () =>
  createSelector(selectContracts, web3 => web3.ethBalance);

const makeSelectGetadoodleContract = () =>
  createSelector(selectContracts, web3 => web3.getadoodleContract);

export {
  makeSelectWeb3,
  makeSelectProvider,
  makeSelectChainId,
  makeSelectConnectedWallet,
  makeSelectEthBalance,
  makeSelectGetadoodleContract,
};

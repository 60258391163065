import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import {AppBar, IconButton, Toolbar, Typography} from '@mui/material';
import {createStructuredSelector} from 'reselect';
import {makeSelectUser} from '../App/selectors';
import {connect} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';
import SearchBar from '../SearchBar';
import {getAuth, signOut} from 'firebase/auth';
import {CloseRounded} from '@mui/icons-material';
import FooterLinks from '../FooterLinks';

const drawerWidth = window.innerWidth < 600 ? window.innerWidth : 400;

const UserDrawer = ({setIsDrawerOpen, isDrawerOpen, user}) => {
  let location = useLocation();

  React.useEffect(() => {
    if (isDrawerOpen) {
      setIsDrawerOpen(false);
    }
  }, [location]);

  return (
    <div>
      <Drawer
        elevation={1}
        onClose={() => setIsDrawerOpen(false)}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-root': {
            zIndex: 99999,
          },
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            backgroundColor: '#1d1d1d',
            overflow: 'visible',
            zIndex: 99999,
          },
        }}
        anchor="right"
        open={isDrawerOpen}>
        <AppBar position="static" elevation={0}>
          <Toolbar>
            <div style={{flexGrow: 1}} />
            <IconButton onClick={() => setIsDrawerOpen(false)}>
              <CloseRounded style={{color: 'white', fontSize: 38}} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className="drawer-content">
          <div>
            <SearchBar />
            {Boolean(user) ? <LoggedInMenu user={user} /> : <LoggedOutMenu />}
          </div>
          <div style={{paddingBottom: 18}}>
            <FooterLinks />
          </div>
        </div>
      </Drawer>
    </div>
  );
};

const LoggedInMenu = ({user}) => {
  return (
    <div>
      <Link to={`/doodlers/${user.username}`}>
        <Typography variant="h3" paragraph>
          Profile
        </Typography>
      </Link>
      <Link to="/account/notifications">
        <Typography variant="h3" paragraph>
          Notifications
        </Typography>
      </Link>
      <Link to={`/doodlers/${user.username}/following`}>
        <Typography variant="h3" paragraph>
          Following
        </Typography>
      </Link>
      <Link to="/account/orders">
        <Typography variant="h3" paragraph>
          Orders
        </Typography>
      </Link>
      <Link
        onClick={async () => {
          await signOut(getAuth());
        }}
        to="/">
        <Typography variant="h3" paragraph style={{color: '#FF5D4F'}}>
          Logout
        </Typography>
      </Link>
    </div>
  );
};

const LoggedOutMenu = () => {
  return (
    <div>
      <Link to={`/discover`}>
        <Typography variant="h3" paragraph>
          Discover
        </Typography>
      </Link>
      <Link to="/login">
        <Typography variant="h3" paragraph>
          Log In
        </Typography>
      </Link>
      <Link to="/register">
        <Typography variant="h3" paragraph>
          Sign Up
        </Typography>
      </Link>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});

export default connect(mapStateToProps, null)(UserDrawer);

import {LinearProgress} from '@mui/material';
import {useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {getDoc, doc} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import ContestActiveScreen from '../ContestActiveScreen';
import ContestCompleteScreen from '../ContestCompleteScreen';
import {Helmet} from 'react-helmet';
import ContestRibbon from '../../components/ContestRibbon';

const ContestScreen = () => {
  const [contest, setContest] = useState(null);
  const contestId = useParams().contestId;

  useEffect(() => {
    getDoc(doc(db, 'contests', contestId))
      .then(doc => {
        setContest({id: doc.id, ...doc.data()});
      })
      .catch(error => {
        console.log(error);
      });
  }, [contestId]);

  if (!contest) {
    return <LinearProgress />;
  }

  const completed = contest.state === 'completed';

  return (
    <div>
      {!completed && <ContestRibbon contest={contest} />}
      <Helmet>
        <title>{contest.title}</title>
        <meta name="description" content={contest.description} />
        <meta
          property="og:image"
          content={require('../../assets/contest-1-banner-web.jpg')}
        />
      </Helmet>
      <br />
      {!completed && <ContestActiveScreen contest={contest} />}
      {completed && <ContestCompleteScreen contest={contest} />}
    </div>
  );
};

export default ContestScreen;

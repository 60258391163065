import React from 'react';
import {Chip} from '@mui/material';
import {Link} from 'react-router-dom';

const TagChip = ({tag}) => {
  return (
    <Link to={`/tags/${tag}`}>
      <Chip style={{backgroundColor: 'white'}} label={tag} variant="outlined" />
    </Link>
  );
};

export default TagChip;

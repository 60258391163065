import React, {useEffect, useState} from 'react';
import {Typography, Stack, Button, Container} from '@mui/material';
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
  startAfter,
  startAt,
} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import DoodleGrid from '../DoodleGrid';
import Center from '../Center';
import {APP_LINK} from '../../constants';

const LIMIT = 20;

const ProfileTabCreated = ({user, isUsersProfile}) => {
  const [doodles, setDoodles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginate, setPaginate] = useState(null);

  useEffect(() => {
    setDoodles([]);
    setPaginate(null);
    loadDoodles();
  }, [user]);

  const loadDoodles = async () => {
    setLoading(true);

    const doodlesSnap = await getDocs(
      query(
        collection(db, 'doodles'),
        where('doodler', '==', user.id),
        where('public', '==', true),
        where('deleted', '==', false),
        orderBy('createdAt', 'desc'),
        paginate ? startAfter(paginate) : startAt('createdAt'),
        limit(LIMIT),
      ),
    );

    setDoodles(
      doodles.concat(
        doodlesSnap.docs.map(snap => ({
          id: snap.id,
          ...snap.data(),
        })),
      ),
    );

    setLoading(false);

    if (doodlesSnap.size === LIMIT) {
      setPaginate(doodlesSnap.docs.pop());
    } else {
      setPaginate(null);
    }
  };

  if (!loading && doodles.length === 0)
    return (
      <Center height={300}>
        {!isUsersProfile && (
          <Typography color="darkGrey">No Getadoodles here</Typography>
        )}
        {isUsersProfile && (
          <Container maxWidth="sm" disableGutters>
            <Stack spacing={2}>
              <Typography style={{textAlign: 'center'}} color="darkGrey">
                To create Getadoodles{' '}
                <a href={APP_LINK} className="blue" target="_blank">
                  download our app
                </a>{' '}
                from the App store and sign in with your account.
              </Typography>
            </Stack>
          </Container>
        )}
      </Center>
    );

  return (
    <div>
      <DoodleGrid doodles={doodles} />
      {paginate && (
        <Container maxWidth="sm">
          <br />
          <Button onClick={loadDoodles} fullWidth variant="outlined">
            LOAD MORE
          </Button>
        </Container>
      )}
    </div>
  );
};

export default ProfileTabCreated;

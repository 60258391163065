import {Box} from '@mui/material';
import React from 'react';
import DoodlerCard from '../DoodlerCard';

const TalentGridRow = ({talent}) => {
  return (
    <div className="scrolling-wrapper-container">
      <Box className="scrolling-wrapper">
        {talent.map(user => (
          <DoodlerCard key={user.id} user={user} />
        ))}
      </Box>
    </div>
  );
};

export default TalentGridRow;

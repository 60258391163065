import React, {useState} from 'react';
import {CircularProgress, TextField} from '@mui/material';
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';
import moment from 'moment';
import ObjectID from 'bson-objectid';
import {Add} from '@mui/icons-material';

const RequestReferenceImage = ({onImageAdded}) => {
  const [previewImage, setPreviewImage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const onChange = e => {
    const file = e.target.files[0];

    setPreviewImage(URL.createObjectURL(file));

    const storage = getStorage();

    const storageRef = ref(
      storage,
      `attachments/${moment().format(
        'MM.DD.YY',
      )}/${ObjectID().toString()}.${file.name.split('.').pop()}`,
    );

    setIsUploading(true);

    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      snapshot => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
        }
      },
      err => {
        alert(err.message);
        setIsUploading(false);
        setProgress(0);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(avatar => {
          setIsUploading(false);
          setProgress(0);
          onImageAdded(avatar);
        });
      },
    );
  };

  return (
    <div>
      <TextField
        fullWidth
        value="Attach reference image (optional)"
        inputProps={{
          readOnly: true,
        }}
        InputProps={{
          endAdornment: (
            <div
              style={{
                position: 'relative',
                cursor: 'pointer',
              }}>
              <input
                accept="image/png, image/gif, image/jpeg"
                onChange={onChange}
                type="file"
                style={localStyles.input}
              />
              <div
                style={{
                  ...localStyles.previewImage,
                  backgroundImage: `url(${previewImage || ''})`,
                }}>
                {isUploading && (
                  <CircularProgress
                    variant="determinate"
                    size={18}
                    value={progress}
                  />
                )}
                {!isUploading && !previewImage && (
                  <Add style={{color: 'white'}} />
                )}
              </div>
            </div>
          ),
        }}
      />
    </div>
  );
};

const localStyles = {
  input: {
    position: 'absolute',
    cursor: 'pointer',
    opacity: 0,
    zIndex: 2,
    left: 0,
    height: '100%',
    top: 0,
    width: '100%',
  },
  previewImage: {
    height: 50,
    width: 50 * 0.75,
    borderRadius: 3,
    backgroundColor: '#d7d7d7',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default RequestReferenceImage;

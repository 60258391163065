import React, {useEffect, useState} from 'react';
import {collection, getDocs, orderBy, query, where} from 'firebase/firestore';
import {Typography} from '@mui/material';
import {db} from '../../clients/Firebase';
import DoodleGrid from '../DoodleGrid';

const GadGrid = () => {
  const [doodles, setDoodles] = useState([]);

  useEffect(() => {
    getDocs(
      query(
        collection(db, 'doodles'),
        where('deleted', '==', false),
        orderBy('gadIndex', 'desc'),
      ),
    )
      .then(querySnapshot =>
        setDoodles(
          querySnapshot.docs.map(snap => ({
            id: snap.id,
            ...snap.data(),
          })),
        ),
      )
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <Typography paragraph variant="h3">
        Litty Doodles
      </Typography>
      <DoodleGrid doodles={doodles} />
      <br />
    </div>
  );
};

export default GadGrid;

import {TextField, Typography} from '@mui/material';
import React, {useRef} from 'react';

const Instructions = ({doodler, setDescription}) => {
  const charLimit = useRef(280).current;
  const [instructions, setInstructions] = React.useState('');

  return (
    <div style={{width: '100%', marginBottom: -16}}>
      <TextField
        disabled={doodler.disableInstructions}
        fullWidth
        label={
          doodler.disableInstructions
            ? `${doodler.displayName} does not accept instructions`
            : 'Getadoodle Instructions (optional)'
        }
        onBlur={() => {
          setDescription(instructions);
        }}
        multiline
        onChange={e => {
          if (e.target.value.length > charLimit) {
            return;
          }
          setInstructions(e.target.value);
        }}
      />
      <div style={{textAlign: 'right'}}>
        <Typography
          color={charLimit === instructions.length ? 'red' : 'textSecondary'}>
          {charLimit - instructions.length}
        </Typography>
      </div>
    </div>
  );
};

export default Instructions;

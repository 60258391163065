import React, {useEffect, useState} from 'react';
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import DoodleRow from '../DoodleRow';

const DoodlesByTag = ({tag}) => {
  const [doodles, setDoodles] = useState([]);

  useEffect(() => {
    getDocs(
      query(
        collection(db, 'doodles'),
        where('public', '==', true),
        where('deleted', '==', false),
        where('tags', 'array-contains', tag),
        orderBy('createdAt', 'desc'),
        limit(6),
      ),
    ).then(querySnapshot => {
      setDoodles(querySnapshot.docs.map(doc => ({id: doc.id, ...doc.data()})));
    });
  }, [tag]);

  return (
    <div>
      <DoodleRow doodles={doodles} title={`#${tag}`} />
    </div>
  );
};

export default DoodlesByTag;

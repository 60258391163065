import React, {useCallback, useEffect, useState} from 'react';
import {Container, LinearProgress} from '@mui/material';
import {doc, getDoc} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import PageHeader from '../../containers/PageHeader';
import AccountDoodleRequestOrderScreen from '../AccountDoodleRequestOrderScreen';
import AccountPrintOrderScreen from '../AccountPrintOrderScreen';
import AccountNFTOrderScreen from '../AccountNFTOrderScreen';
import {useParams} from 'react-router-dom';

const AccountOrderScreen = () => {
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(null);
  const {orderId} = useParams();

  const fetchOrderAsync = useCallback(async () => {
    try {
      const docRef = doc(db, 'orders', orderId);

      const docSnap = await getDoc(docRef);

      setOrder({
        ...docSnap.data(),
        id: docSnap.id,
      });

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, [orderId]);

  useEffect(() => {
    fetchOrderAsync().catch(err => console.error(err));
  }, [fetchOrderAsync]);

  if (loading) return <LinearProgress />;

  return (
    <Container maxWidth="sm">
      <PageHeader>Order Summary</PageHeader>
      {order && order.type === 'doodle-request' && (
        <AccountDoodleRequestOrderScreen orderId={orderId} />
      )}
      {order && order.type === 'print' && (
        <AccountPrintOrderScreen order={order} />
      )}
      {order && order.type === 'nft' && <AccountNFTOrderScreen order={order} />}
    </Container>
  );
};

export default AccountOrderScreen;

import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ReactCrop, {centerCrop, makeAspectCrop} from 'react-image-crop';
import React, {useEffect, useRef, useState} from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import {CloseSharp} from '@mui/icons-material';

const CropImageDialog = ({file, onCroppingComplete, onClose}) => {
  const [imgSrc, setImgSrc] = useState('');
  const [crop, setCrop] = useState();
  const [open, setOpen] = useState(false);
  const imgRef = useRef(null);
  const pixelCropRef = useRef(null);
  const imgDimensions = useRef(null);

  useEffect(() => {
    setOpen(Boolean(file));
  }, [file]);

  useEffect(() => {
    if (file) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onloadend = function () {
        setImgSrc(reader.result.toString() || '');

        const image = new Image();

        image.onload = function () {
          imgDimensions.current = {width: image.width, height: image.height};
        };

        image.src = reader.result;
      };
    } else {
      setOpen(false);
    }
  }, [file]);

  function onImageLoad(e) {
    const {width, height} = e.currentTarget;

    pixelCropRef.current = centerAspectCrop(width, height, 1);

    setCrop(
      centerCrop(
        makeAspectCrop(
          {
            unit: '%',
            height: 100,
          },
          1,
          width,
          height,
        ),
        width,
        height,
      ),
    );
  }

  function getCroppedImg() {
    const pixelCrop = pixelCropRef.current;

    const ratio = imgDimensions.current.width / imgRef.current.width;

    const canvas = document.createElement('canvas');

    canvas.width = pixelCropRef.current.width * ratio;

    canvas.height = pixelCropRef.current.height * ratio;

    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      imgRef.current,
      pixelCrop.x * ratio,
      pixelCrop.y * ratio,
      pixelCrop.width * ratio,
      pixelCrop.height * ratio,
      0,
      0,
      pixelCrop.width * ratio,
      pixelCrop.height * ratio,
    );

    canvas.toBlob(
      file => {
        if (file) {
          onCroppingComplete(file);
        } else {
          alert('Something went wrong');
        }
      },
      'image/png',
      '100%',
    );
  }

  const isMobile = useMediaQuery('(max-width:600px)');

  const theme = useTheme();

  return (
    <Dialog fullScreen={isMobile} maxWidth="md" open={open}>
      <AppBar
        elevation={0}
        style={{
          background: theme.palette.primary.main,
        }}
        position="static">
        <Stack
          style={{paddingLeft: 24, paddingRight: 24}}
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Typography variant="h3" color="#F1F1F1">
            EDIT MEDIA
          </Typography>
          <IconButton onClick={onClose}>
            <Typography variant="h3" color="#F1F1F1">
              <CloseSharp color="#F1F1F1" />
            </Typography>
          </IconButton>
        </Stack>
      </AppBar>
      <DialogContent>
        <div style={{textAlign: 'center'}}>
          <ReactCrop
            crop={crop}
            onChange={(pc, percentCrop) => {
              pixelCropRef.current = pc;
              setCrop(percentCrop);
            }}
            style={{maxHeight: '70vh'}}
            aspect={1}>
            <img
              style={{width: 'auto', maxWidth: '100%', maxHeight: '70vh'}}
              onLoad={onImageLoad}
              ref={imgRef}
              alt="Crop me"
              src={imgSrc}
            />
          </ReactCrop>
          <div style={{maxWidth: 600, margin: '0 auto', marginTop: 20}}>
            <Button fullWidth onClick={getCroppedImg} variant="contained">
              SAVE
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  const crop = {
    unit: 'px',
  };
  if (mediaWidth >= mediaHeight) {
    crop.height = mediaHeight;
  } else {
    crop.width = mediaWidth;
  }
  return centerCrop(
    makeAspectCrop(crop, aspect, mediaWidth, mediaHeight),
    mediaWidth,
    mediaHeight,
  );
}

export default CropImageDialog;

import React, {useState} from 'react';
import {Container, Stack, TextField, Typography} from '@mui/material';
import {addDoc, collection} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import {LoadingButton} from '@mui/lab';
import PageHeader from '../../containers/PageHeader';
import {Helmet} from 'react-helmet';

const ContactUsScreen = () => {
  const [working, setWorking] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const sendMessageAsync = async e => {
    e.preventDefault();

    try {
      setWorking(true);

      const docRef = collection(db, 'externalMessages');

      await addDoc(docRef, {
        name,
        email,
        message,
      });

      setWorking(false);

      setName('');
      setEmail('');
      setMessage('');

      alert('Message Delivered');
    } catch (err) {
      setWorking(false);
      alert(err.message);
    }
  };

  return (
    <Container maxWidth="sm">
      <Helmet>
        <title>Contact Us</title>
      </Helmet>
      <PageHeader>Contact Us 🧸</PageHeader>
      <form onSubmit={sendMessageAsync}>
        <Stack spacing={2}>
          <TextField
            required
            label="Name"
            value={name}
            onChange={e => setName(e.target.value)}
            fullWidth
          />
          <TextField
            required
            inputProps={{type: 'email'}}
            label="Email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            fullWidth
          />
          <TextField
            required
            label="Message"
            value={message}
            onChange={e => setMessage(e.target.value)}
            fullWidth
            multiline
          />
          <LoadingButton
            type="submit"
            size="large"
            variant="contained"
            loading={working}
            disabled={!name || !email}>
            SEND
          </LoadingButton>
        </Stack>
      </form>
    </Container>
  );
};

export default ContactUsScreen;

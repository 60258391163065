import React, {useEffect, useState} from 'react';
import {Container, LinearProgress, List, Typography} from '@mui/material';
import {createStructuredSelector} from 'reselect';
import {makeSelectUser} from '../../containers/App/selectors';
import {connect} from 'react-redux';
import {collection, limit, getDocs, orderBy, query} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import PageHeader from '../../containers/PageHeader';
import Center from '../../containers/Center';
import NotificationLineItem from '../../containers/NotificationLineItem';
import {fetchNewNotificationCount} from '../../containers/App/actions';

const NotificationsScreen = ({user, dispatch}) => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    getDocs(
      query(
        collection(db, `users/${user.id}/notifications`),
        orderBy('createdAt', 'desc'),
        limit(24),
      ),
    ).then(snapshot => {
      if (!snapshot.empty) {
        setNotifications(snapshot.docs);
      }
      setLoading(false);
    });

    return () => {
      dispatch(fetchNewNotificationCount());
    };
  }, [user]);

  return (
    <Container maxWidth="sm">
      <PageHeader>Notifications</PageHeader>
      {loading && <LinearProgress />}
      {!loading && notifications.length === 0 && (
        <Center height={180}>
          <Typography variant="body1" color="darkgrey">
            No notifications
          </Typography>
        </Center>
      )}
      {!loading && (
        <List>
          {notifications.map((notification, index) => (
            <NotificationLineItem
              key={notification.id}
              notification={notification}
            />
          ))}
        </List>
      )}
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationsScreen);

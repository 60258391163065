import React, {useEffect, useState} from 'react';
import {Button, Snackbar, Alert} from '@mui/material';
import {createStructuredSelector} from 'reselect';
import {makeSelectUser} from '../App/selectors';
import {connect} from 'react-redux';
import {doc, onSnapshot, setDoc} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import {setIsAuthenticating} from '../App/actions';
import theme from '../../theme';
import mixpanel from 'mixpanel-browser';

const FollowIcon = require('../../assets/follow.png');
const FollowingIcon = require('../../assets/following.png');

const FollowButton = ({user, followUser, dispatch, noBorder}) => {
  const [isFollowing, setIsFollowing] = useState(false);
  const [showSnack, setShowSnack] = useState(false);

  useEffect(() => {
    if (user && followUser) {
      const subscriber = onSnapshot(
        doc(db, 'follows', user.id + followUser.id),
        snapshot => {
          if (snapshot.exists()) {
            setIsFollowing(snapshot.data().following);
          } else {
            setIsFollowing(false);
          }
        },
      );

      return subscriber;
    }
  }, [user]);

  const toggleFollow = async () => {
    if (!user) {
      dispatch(setIsAuthenticating(true));
      return;
    }

    if (!isFollowing) {
      setShowSnack(true);
    }

    try {
      await setDoc(
        doc(db, 'follows', user.id + followUser.id),
        {
          follower: user.id,
          followed: followUser.id,
          following: !isFollowing,
          createdAt: new Date(),
        },
        {merge: true},
      );
    } catch (err) {
      console.log(err);
    }

    try {
      mixpanel.track('Follow', {
        followed: !isFollowing,
        followName: followUser.displayName,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={showSnack}
        autoHideDuration={6000}
        onClose={() => setShowSnack(false)}>
        <Alert onClose={() => setShowSnack(false)} severity="success">
          You are now following {followUser.displayName}
        </Alert>
      </Snackbar>
      <Button
        style={{
          width: 50,
          opacity: isFollowing ? 0.5 : 1,
          borderWidth: noBorder ? 0 : 2.5,
          borderColor: theme.palette.primary.main,
        }}
        variant="outlined"
        onClick={toggleFollow}>
        {isFollowing ? (
          <img style={{width: 24}} alt="follow" src={FollowingIcon} />
        ) : (
          <img style={{width: 24}} alt="follow" src={FollowIcon} />
        )}
      </Button>
    </React.Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FollowButton);

import {Typography} from '@mui/material';
import React from 'react';
import theme from '../../theme';

const DoodlerInfoBar = ({doodler}) => (
  <Typography variant="body" style={{marginTop: 0}}>
    <Typography variant="span" color="darkGrey">
      {doodler.mainCategory}
    </Typography>
    {doodler.link && (
      <span>
        {' • '}
        <a
          rel="noopener noreferrer"
          style={{textTransform: 'lowercase', color: theme.palette.blue}}
          href={
            'https://' +
            (doodler.link || '').replace('https://', '').replace('http://', '')
          }
          target="_blank">
          {(doodler.link || '')
            .slice(0, 60)
            .replace('https://', '')
            .replace('http://', '')}
        </a>
      </span>
    )}
  </Typography>
);

export default DoodlerInfoBar;

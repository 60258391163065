import React, {useEffect, useState} from 'react';
import {Box, Typography, List, useMediaQuery, Stack} from '@mui/material';
import DoodleCommentEnterText from '../DoodleCommentEnterText';
import {createStructuredSelector} from 'reselect';
import {connect} from 'react-redux';
import {makeSelectCommentDoodleId} from '../AppDrawer/selectors';
import {collection, getDocs, limit, orderBy, query} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import {makeSelectUser} from '../App/selectors';
import CommentLineItem from '../CommentLineItem';
import Center from '../Center';
import CloseDrawerButton from '../../components/CloseDrawerButton';

const DoodleComments = ({commentDoodleId, user, doodle}) => {
  const [comments, setComments] = useState([]);
  const [replyToComment, setReplyToComment] = useState(null);
  const isDesktop = useMediaQuery('(min-width:600px)');

  useEffect(() => {
    fetchComments();
    return () => {
      setComments([]);
    };
  }, [commentDoodleId]);

  const fetchComments = async () => {
    const snapshot = await getDocs(
      query(
        collection(db, `doodles/${commentDoodleId}/comments`),
        orderBy('createdAt', 'desc'),
        limit(24),
      ),
    );
    setComments(snapshot.docs.map(doc => ({...doc.data(), id: doc.id})));
  };
  const onNewComment = comment => {
    if (replyToComment) {
      let x = 0;
      for (const _comment of comments) {
        if (replyToComment.id === _comment.id) {
          comments[x] = {
            ...comments[x],
            replies: [comment],
          };
          break;
        }
        x += 1;
      }
      setComments([...comments]);
    } else {
      setComments([comment, ...comments]);
    }
  };

  return (
    <Box
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
      }}>
      <div
        style={{
          padding: isDesktop ? 40 : 20,
        }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          style={{marginBottom: 12}}>
          <Typography variant="h5">
            <strong>Comments</strong>
          </Typography>
          <div style={{marginRight: 8, marginTop: -2}}>
            <CloseDrawerButton />
          </div>
        </Stack>

        <DoodleCommentEnterText
          replyToComment={replyToComment}
          doodleId={commentDoodleId}
          onClearReply={() => setReplyToComment(null)}
          onNewComment={onNewComment}
        />

        <List disablePadding>
          {comments.length === 0 && (
            <Center height={120}>
              <Typography variant="body" color="darkgrey">
                No comments yet
              </Typography>
            </Center>
          )}
          {comments.map(comment => (
            <CommentLineItem
              commentDoodleId={commentDoodleId}
              key={comment.id}
              comment={comment}
              user={user}
              setReplyToComment={setReplyToComment}
            />
          ))}
        </List>
      </div>
    </Box>
  );
};

const mapStateToProps = createStructuredSelector({
  commentDoodleId: makeSelectCommentDoodleId(),
  user: makeSelectUser(),
});

export default connect(mapStateToProps, null)(DoodleComments);

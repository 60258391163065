import {TextField} from '@mui/material';
import React, {useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';

const SearchBar = () => {
  const [query, setQuery] = useState('');
  const inputRef = useRef(null);

  const navigate = useNavigate();

  const handleSubmit = e => {
    e.preventDefault();
    navigate(`/search/${query}`);
    setQuery('');
    inputRef.current.blur();
  };
  return (
    <div className="search-bar-wrapper">
      <form onSubmit={handleSubmit}>
        <TextField
          variant="standard"
          inputRef={inputRef}
          value={query}
          placeholder="Search"
          size="medium"
          className="search-bar"
          fullWidth
          onChange={e => setQuery(e.target.value)}
          InputProps={{
            disableUnderline: true,
          }}
        />
      </form>
    </div>
  );
};

export default SearchBar;

import React from 'react';
import {Grid, Typography, useMediaQuery} from '@mui/material';
import DoodleOptionsRow from '../DoodleOptionsRow';
import DoodleDetails from '../DoodleDetails';

const DoodleDetailColumns = ({doodle, completed, marginLeft = 0}) => {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Grid style={{marginTop: 0}} container spacing={6}>
      <Grid
        sx={{
          marginLeft,
          paddingTop: {
            xs: `${isMobile ? 12 : 48}px !important`,
          },
        }}
        xs={12}
        sm={6}
        item>
        <div style={{display: 'grid'}}>
          <img alt={doodle.name} style={{width: '100%'}} src={doodle.uri} />
        </div>
      </Grid>
      <Grid
        sx={{
          marginLeft: isMobile ? marginLeft : 0,
          paddingTop: {
            xs: `${isMobile ? 12 : 48}px !important`,
          },
        }}
        xs={12}
        sm={6}
        item>
        {isMobile && (
          <div
            style={{
              marginBottom: 12,
              width: '100%',
              marginTop: -15,
              marginLeft: -8,
            }}>
            <DoodleOptionsRow doodle={doodle} />
          </div>
        )}
        <DoodleDetails doodle={doodle} isMobile={isMobile} />
      </Grid>
    </Grid>
  );
};

export default DoodleDetailColumns;

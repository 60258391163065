import React from 'react';
import {
  Button,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Helmet} from 'react-helmet';
import AppStoreButton from '../../components/AppStoreButton';
import {Link} from 'react-router-dom';

const HomeScreen = () => {
  const isDesktop = useMediaQuery('(min-width:600px)');
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <Helmet
        description="Personalized doodles from your favorite people."
        title="Official Site"
        defaultTitle="Getadoodle"
      />
      <div
        style={{
          minHeight: 'calc(100vh - 307px)',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <div className={classes.imageWrapper}>
          <img
            style={{height: isDesktop ? 489 : 383}}
            alt="getadoodle"
            src={
              isDesktop
                ? require('../../assets/gad-banner-top-web-1152.png')
                : require('../../assets/gad-banner-top-mobile-web-2400.png')
            }
          />
          <div className={classes.centeredText}>
            <Typography paragraph variant="h3" className={classes.title}>
              AN ONLINE SOCIAL CLUB FOR DOODLERS
            </Typography>
          </div>
        </div>
        {!isDesktop && <div style={{height: 50}} />}
        <div className={classes.buttonGroup}>
          <Stack spacing={4} direction={isDesktop ? 'row' : 'column'}>
            <AppStoreButton width={245} />
            <Link to={'/about'}>
              <Button className={classes.learnMoreButton}>
                <Typography>
                  <strong>LEARN MORE</strong>
                </Typography>
              </Button>
            </Link>
          </Stack>
        </div>
        {!isDesktop && <div style={{height: 100}} />}
      </div>
    </Container>
  );
};

const useStyles = makeStyles(theme => ({
  imageWrapper: {
    position: 'relative',
  },
  centeredText: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    maxWidth: 750,
    textAlign: 'center',
    whiteSpace: 'normal',
  },
  buttonGroup: {
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
  },
  learnMoreButton: {
    height: 82,
    width: 245,
    border: '#1d1d1d solid 3px',
    borderRadius: 12,
  },
}));

export default HomeScreen;

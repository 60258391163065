import React, {useEffect, useState} from 'react';
import {
  Container,
  List,
  ListItem,
  Typography,
  ListItemText,
  useTheme,
  ListItemAvatar,
} from '@mui/material';
import {createStructuredSelector} from 'reselect';
import {makeSelectUser} from '../../containers/App/selectors';
import {connect} from 'react-redux';
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import Center from '../../containers/Center';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import PageHeader from '../../containers/PageHeader';
import {getDoodleUrlById} from '../../utils';

const AccountOrdersScreen = ({user}) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    fetchOrdersAsync();
  }, []);

  const fetchOrdersAsync = async () => {
    setLoading(true);
    const q = query(
      collection(db, 'orders'),
      where('user', '==', user.id),
      orderBy('createdAt', 'desc'),
      limit(20),
    );

    const querySnapshot = await getDocs(q);

    const _orders = [];

    querySnapshot.forEach(doc => {
      _orders.push({
        ...doc.data(),
        id: doc.id,
      });
    });

    setOrders(_orders);

    setLoading(false);
  };

  const navigate = useNavigate();

  return (
    <Container maxWidth="sm">
      <PageHeader>Your Orders</PageHeader>
      {!loading && orders.length === 0 && (
        <Center height={300}>
          <Typography variant="text" color="textSecondary">
            Your orders will appear here
          </Typography>
        </Center>
      )}
      <List style={{whiteSpace: 'nowrap'}}>
        {orders.map(order => (
          <ListItem
            divider
            disableGutters
            onClick={() => navigate(`/account/orders/${order.id}`)}
            button
            key={order.id}>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography>{order.title}</Typography>
                </React.Fragment>
              }
              secondary={
                <React.Fragment>
                  <Typography variant="caption">
                    {moment(order.createdAt.toDate()).format('MMM D')} • Order #
                    {order.orderNumber}{' '}
                  </Typography>
                  <br />
                  <Typography variant="caption">
                    Amount: ${(order.amount || 0) / 100} |{' '}
                    <span
                      className={`status-${order.status}`}
                      style={{
                        textTransform: 'capitalize',
                      }}>
                      {order.status}
                    </span>
                  </Typography>
                </React.Fragment>
              }
            />
            <ListItemAvatar>
              <img
                style={{
                  width: 48,
                  opacity: order.status === 'fulfilled' ? 1 : 0.6,
                }}
                src={
                  order.status === 'fulfilled' || order.type === 'print'
                    ? getDoodleUrlById(order.doodle || order.id)
                    : 'https://firebasestorage.googleapis.com/v0/b/doodles-5658c.appspot.com/o/static%2Flogo192.png?alt=media'
                }
              />
            </ListItemAvatar>
          </ListItem>
        ))}
      </List>
    </Container>
  );
};
const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountOrdersScreen);

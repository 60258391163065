import {put, takeLatest, all, select} from 'redux-saga/effects';
import {
  RECORD_ERROR,
  UPDATE_USER,
  FETCH_NEW_NOTIFICATION_COUNT,
} from './constants';
import {makeSelectUser} from './selectors';
import {
  collection,
  doc,
  getCountFromServer,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import {logEvent} from 'firebase/analytics';
import {setIsAuthenticating, setNewNotificationCount} from './actions';
import {analytics, auth, db} from '../../clients/Firebase';
import mixpanel from 'mixpanel-browser';

function* updateUserSaga({payload}) {
  try {
    const user = yield select(makeSelectUser());

    yield updateDoc(doc(db, 'users', user.uid), payload);

    yield put(setIsAuthenticating(false));
  } catch (err) {
    alert(err.message);
  }
}

function recordErrorSaga({err, eventName = 'error'}) {
  try {
    logEvent(analytics, eventName, {
      message: err.message,
    });

    mixpanel.track('error', {
      message: err.message,
    });
  } catch (err) {
    console.log(err);
  }
}

function* fetchNewNotificationsCountSaga() {
  try {
    if (!auth.currentUser) return;

    const res = yield getCountFromServer(
      query(
        collection(db, `users/${auth.currentUser.uid}/notifications`),
        where('unseen', '==', true),
      ),
    );

    yield put(setNewNotificationCount(res.data().count));
  } catch (err) {
    console.log(err);
  }
}

export function* updateUserWatcher() {
  yield takeLatest(UPDATE_USER, updateUserSaga);
}

export function* recordErrorWatcher() {
  yield takeLatest(RECORD_ERROR, recordErrorSaga);
}
export function* fetchNewNotificationCountWatcher() {
  yield takeLatest(
    FETCH_NEW_NOTIFICATION_COUNT,
    fetchNewNotificationsCountSaga,
  );
}

export default function* rootAppSaga() {
  yield all([
    updateUserWatcher(),
    recordErrorWatcher(),
    fetchNewNotificationCountWatcher(),
  ]);
}

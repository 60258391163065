import React from 'react';

const Avatar = ({alt = 'Avatar', src, width = '100%'}) => (
  <div>
    <div
      className="doodle-circle"
      style={{
        backgroundImage: `url(${src})`,
        backgroundSize: 'cover',
        width,
        paddingTop: '100%',
      }}
    />
  </div>
);

export default Avatar;

import React from 'react';
import {Typography} from '@mui/material';

const PageHeader = ({children}) => {
  return (
    <div>
      <br />
      <Typography variant="h3">{children}</Typography>
      <br />
    </div>
  );
};

export default PageHeader;

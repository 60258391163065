import {createTheme} from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1D1D1D',
      contrastText: '#F1F1F1',
    },
    secondary: {
      main: '#4BD964',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
    white: '#F1F1F1',
    blue: '#3797F0',
    red: '#FF5D4F',
    lightGrey: '#C7C7C7',
    yellow: '#E0C629',
    darkGrey: '#6B6B6B',
    action: {
      disabledBackground: '#C7C7C7',
      disabled: 'white',
    },
  },
  overrides: {
    MuiCard: {
      backgroundColor: '#f1f1f1',
    },
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: '#f1f1f1',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ownerState, theme}) => ({
          ...(ownerState.disabled === true && {
            border: 'thin solid #C7C7C7',
            borderRadius: 4,
          }),
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ownerState, theme}) => ({
          ...(ownerState.disabled === true && {
            backgroundColor: theme.palette.lightGrey,
          }),
        }),
      },
      defaultProps: {
        size: 'large',
      },
    },
  },
  typography: {
    h1: {
      fontFamily: [
        'Saira',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '@media (max-width:600px)': {
        fontSize: '3rem',
      },
    },
    h3: {
      fontWeight: 700,
      fontSize: 46,
      letterSpacing: -0.41,
      color: '#1D1D1D',
      whiteSpace: 'normal',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '@media (max-width:600px)': {
        fontSize: 32,
      },
    },
    h5: {
      '@media (max-width:600px)': {
        fontSize: 24,
      },
      fontWeight: 500,
      fontSize: 24,
    },
    h6: {
      fontWeight: 600,
    },

    body1: {
      fontSize: 22,
      '@media (max-width:600px)': {
        fontSize: 16,
      },
    },

    fontFamily: 'Saira',

    palette: {
      textPrimary: {
        main: '#F1F1F1',
      },
      textSecondary: {
        main: '#6b6b6b',
      },
    },
  },
});

export default theme;

import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Typography} from '@mui/material';
import {getDoc, doc} from 'firebase/firestore';
import {db} from '../../clients/Firebase';

const DoodleProductDescription = ({product}) => {
  const [doodler, setDoodler] = useState(null);

  useEffect(() => {
    fetchArtist();
  }, [product]);
  const fetchArtist = async () => {
    console.log(product);
    getDoc(doc(db, `users/${product.doodler}`)).then(snap => {
      setDoodler(snap);
    });
  };

  const getBody = () => {
    switch (product.type) {
      case 'shirt':
        return 'All cotton classic Getadoodle t-shirt with printed graphic by';
      case 'framed-print':
        return 'Elegant and simple framed print featuring artwork by';
      case 'poster':
        return 'Printed on high quality paper with a matte finish. Artwork by ';
    }
  };

  if (!doodler) return null;

  return (
    <Typography>
      {getBody()}{' '}
      <Link to={`/doodlers/${doodler.data().username}`}>
        <strong>
          {' '}
          {doodler.data().displayName || doodler.data().username}
        </strong>
      </Link>
      .
    </Typography>
  );
};

export default DoodleProductDescription;

import {useNavigate} from 'react-router-dom';
import {Button, Typography, Alert, Stack} from '@mui/material';
import React, {useEffect, useState} from 'react';
import Pluralize from 'react-pluralize';
import NotifyWhenAvailableDialog from '../NotifyWhenAvailableDialog';
import FollowButton from '../FollowButton';

const DoodlerActions = ({doodler, isUsersProfile}) => {
  const [unavailable, setUnavailable] = useState(false);
  const [settingNotify, setSettingNotify] = useState(false);
  const [requestsAvailable, setRequestsAvailable] = useState(0);

  useEffect(() => {
    if (doodler) {
      if (!doodler.acceptingRequests) {
        setUnavailable(true);
      } else if (doodler.doodleLimit > 0) {
        setUnavailable(doodler.doodleLimit - doodler.doodlesTodo <= 0);
        if (doodler.doodlesTodo / doodler.doodleLimit >= 0.8) {
          setRequestsAvailable(doodler.doodleLimit - doodler.doodlesTodo);
        }
      }
    }
  }, [doodler]);

  const navigate = useNavigate();

  return (
    <Stack spacing={2}>
      <NotifyWhenAvailableDialog
        doodler={doodler}
        onSubmit={() => setSettingNotify(false)}
        onClose={() => setSettingNotify(false)}
        open={settingNotify}
      />

      {unavailable && (
        <Alert severity="info">
          {doodler.displayName} is not accepting commissions at this time.
        </Alert>
      )}

      <Stack direction="row" spacing={2}>
        <Button
          onClick={() => {
            if (!unavailable) navigate(`/doodlers/${doodler.id}/purchase`);

            setSettingNotify(true);
          }}
          size="large"
          fullWidth
          variant="contained">
          {!unavailable
            ? `COMMISSION GETADOODLE $${doodler.price}`
            : 'NOTIFY ME WHEN AVAILABLE'}
        </Button>
        <FollowButton followUser={doodler} />
      </Stack>

      {requestsAvailable > 0 && (
        <Typography variant="text">
          <i>
            Only {requestsAvailable}{' '}
            <strong>
              <Pluralize singular={'request'} count={requestsAvailable} />
            </strong>{' '}
            available.
          </i>
        </Typography>
      )}
    </Stack>
  );
};

export default DoodlerActions;

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

class CoinGeckoClient {
  static getEthereumToUsdAsync() {
    return fetch(
      'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd',
      {
        headers,
      },
    ).then(res => res.json());
  }
}

export default CoinGeckoClient;

import React, {useEffect, useState} from 'react';
import {IconButton} from '@mui/material';
import {createStructuredSelector} from 'reselect';
import {makeSelectUser} from '../App/selectors';
import {connect} from 'react-redux';
import {setIsAuthenticating} from '../App/actions';
import {doc, onSnapshot, setDoc} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import mixpanel from 'mixpanel-browser';

const Liked = require('../../assets/icons/like-filled.png');
const NotLiked = require('../../assets/icons/like.png');

const LikeDoodleButton = ({doodle, user, dispatch}) => {
  const [isLiked, setIsLiked] = useState(false);

  useEffect(() => {
    let subscriber;
    if (user) {
      subscriber = onSnapshot(
        doc(db, `users/${user.id}/doodleLikes`, doodle.id),
        snapshot => {
          if (snapshot.exists()) {
            setIsLiked(snapshot.data().isLiked);
          }
        },
      );
    }
    return () => {
      if (subscriber) subscriber();
    };
  }, [user]);

  const handleClick = async e => {
    e.preventDefault();

    if (!user) {
      dispatch(setIsAuthenticating(true));
      return;
    }

    await setDoc(
      doc(db, `users/${user.id}/doodleLikes`, doodle.id),
      {isLiked: !isLiked},
      {merge: true},
    );

    try {
      mixpanel.track('Like', {
        liked: !isLiked,
        doodlerName: doodle.doodlerName,
        doodle: doodle.name,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <IconButton onClick={handleClick}>
      <img style={{width: 28}} src={isLiked ? Liked : NotLiked} />
    </IconButton>
  );
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LikeDoodleButton);

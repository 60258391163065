import React, {useEffect, useState} from 'react';
import {Container, Typography} from '@mui/material';
import Center from '../../containers/Center';
import {useParams} from 'react-router-dom';

function iOS() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

const DoodleRequestScreen = () => {
  const [isMobile, setIsMobile] = useState(true);

  const {doodleRequestId} = useParams();

  useEffect(() => {
    if (iOS()) {
      window.location = `getadoodle://doodle-request/${doodleRequestId}`;
    } else {
      setIsMobile(false);
    }
  }, []);

  return (
    <Container maxWidth="sm">
      {isMobile && <Typography>Redirecting to app.</Typography>}
      {!isMobile && (
        <Center height="70vh">
          <Typography>Please open on mobile device.</Typography>
        </Center>
      )}
    </Container>
  );
};

export default DoodleRequestScreen;

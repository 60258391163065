import theme from '../../theme';
import React from 'react';

const VerifiedBar = () => (
  <div
    style={{
      whiteSpace: 'nowrap',
      backgroundColor: theme.palette.blue,
      color: 'white',
      fontSize: 9,
      fontWeight: 700,
      lineHeight: '14px',
      position: 'fixed',
      zIndex: 9,
      width: '100%',
      left: 0,
    }}>
    {Array.from(Array(100).keys())
      .map(i => 'VERIFIED ACCOUNT')
      .join(' ')}
  </div>
);

export default VerifiedBar;

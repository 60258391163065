import {useEffect} from 'react';
import {
  getAuth,
  onAuthStateChanged,
  signInWithCustomToken,
} from 'firebase/auth';
import {connect} from 'react-redux';
import {doc, getDoc, onSnapshot} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import {setInitialAuthComplete, setUser} from '../App/actions';
import mixpanel from 'mixpanel-browser';
import {useNavigate, useLocation} from 'react-router-dom';

const AuthWrapper = ({dispatch}) => {
  let location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    const token = new URLSearchParams(location.search).get('token');

    if (token) {
      const auth = getAuth();
      signInWithCustomToken(auth, token)
        .then(() => {
          navigate({
            search: '',
          });
        })
        .catch(error => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorMessage);
        });
    }
  }, [location]);

  useEffect(() => {
    const auth = getAuth();
    let userSubscriber;

    const subscriber = onAuthStateChanged(auth, async authRes => {
      if (authRes) {
        const docRef = doc(db, 'users', authRes.uid);

        const docSnap = await getDoc(docRef);

        dispatch(
          setUser({
            uid: authRes.uid,
            id: authRes.uid,
            email: authRes.email,
            emailVerified: authRes.emailVerified,
            ...docSnap.data(),
          }),
        );

        userSubscriber = onSnapshot(doc(db, 'users', authRes.uid), snapshot => {
          if (snapshot.data()) {
            dispatch(
              setUser({
                uid: authRes.uid,
                id: authRes.uid,
                email: authRes.email,
                emailVerified: authRes.emailVerified,
                ...snapshot.data(),
              }),
            );

            mixpanel.identify(snapshot.data().displayName || authRes.email);
            mixpanel.people.set({
              talent: Boolean(snapshot.data().activated),
              avatar: snapshot.data().avatar,
            });
          }
        });
      } else {
        dispatch(setUser(authRes));
      }

      dispatch(setInitialAuthComplete(true));
    });

    return () => {
      subscriber();
      if (userSubscriber) userSubscriber();
    };
  }, []);

  return null;
};

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(null, mapDispatchToProps)(AuthWrapper);

import React, {useEffect} from 'react';
import {createStructuredSelector} from 'reselect';
import {
  makeSelectInitialAuthComplete,
  makeSelectUser,
} from '../containers/App/selectors';
import {connect} from 'react-redux';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import HomeScreen from '../screens/HomeScreen';
import DoodlerScreen from '../screens/DoodlerScreen';
import PurchaseDoodleScreen from '../screens/PurchaseDoodleScreen';
import DoodleScreen from '../screens/DoodleScreen';
import AccountOrdersScreen from '../screens/AccountOrdersScreen';
import EditDoodlerScreen from '../screens/EditDoodlerScreen';
import AccountOrderScreen from '../screens/AccountOrderScreen';
import TOSScreen from '../screens/TOSScreen';
import LoginScreen from '../screens/LoginScreen';
import RegisterScreen from '../screens/RegisterScreen';
import ForgotPasswordScreen from '../screens/ForgotPasswordScreen';
import ProfileScreen from '../screens/ProfileScreen';
import FourOhFourScreen from '../screens/FourOhFourScreen';
import PrivacyScreen from '../screens/PrivacyScreen';
import AcceptableUseScreen from '../screens/AcceptableUseScreen';
import TalentTermsOfService from '../screens/TalentTermsOfService';
import ApplicationSuccessScreen from '../screens/ApplicationSuccessScreen';
import ContactUsScreen from '../screens/ContactUsScreen';
import CommunityGuidelinesScreen from '../screens/CommunityGuidelinesScreen';
import FAQScreen from '../screens/FAQScreen';
import DoodlerFAQScreen from '../screens/DoodlerFAQScreen';
import AccountScreen from '../screens/AccountScreen';
import ReviewDoodleScreen from '../screens/ReviewDoodleScreen';
import DoodleRequestScreen from '../screens/DoodleRequestScreen';
import SearchScreen from '../screens/SearchScreen';
import DoodleProductsScreen from '../screens/DoodleProductsScreen';
import DoodlePurchaseProductScreen from '../screens/DoodlePurchaseProductScreen';
import DoodleMintNFTScreen from '../screens/DoodleMintNFTScreen';
import DoodlesScreen from '../screens/DoodlesScreen';
import AccountWalletsScreen from '../screens/AccountWalletsScreen';
import AccountReferralsScreen from '../screens/AccountReferralsScreen';
import AboutScreen from '../screens/AboutScreen';
import DoodleAnimatePathsScreen from '../screens/DoodleAnimatePathsScreen';
import DoodlePlayGroundScreen from '../screens/DoodlePlayGroundScreen';
import ProductScreen from '../screens/ProductScreen';
import CharityScreen from '../screens/CharityScreen';
import DoodlesForGood from '../screens/DoodlesForGood';
import NewDoods from '../screens/NewDoods';
import DoodlerFollowScreen from '../screens/DoodlerFollowScreen';
import NotificationsScreen from '../screens/NotificationsScreen';
import AuthWrapper from '../containers/AuthWrapper';
import AuthDialog from '../containers/AuthDialog';
import PageTracking from '../containers/PageTracking';
import AppHeader from '../containers/AppHeader';
import AppFooter from '../containers/AppFooter';
import DoodleTagScreen from '../screens/DoodleTagScreen';
import ContestScreen from '../screens/ContestScreen';
import DiscoverScreen from '../screens/DiscoverScreen';

const AppRouter = ({user, initialAuthComplete}) => {
  return (
    <Router>
      <AuthWrapper />
      <AuthDialog />
      <ScrollToTop />
      <PageTracking />
      <AppHeader />
      <div style={{minHeight: 'calc(100vh - 307px)'}}>
        {initialAuthComplete && (
          <Routes>
            <Route
              exact
              path="/"
              element={user ? <DiscoverScreen /> : <HomeScreen />}
            />

            <Route exact path="/terms-of-service" element={<TOSScreen />} />

            <Route exact path="/privacy" element={<PrivacyScreen />} />

            <Route exact path="/about" element={<AboutScreen />} />

            <Route
              exact
              path="/doodles-for-good"
              element={<DoodlesForGood />}
            />

            <Route
              exact
              path="/acceptable-use"
              element={<AcceptableUseScreen />}
            />

            <Route
              exact
              path="/community-guidelines"
              element={<CommunityGuidelinesScreen />}
            />

            <Route
              exact
              path="/talent-terms-of-service"
              element={<TalentTermsOfService />}
            />

            <Route
              exact
              path="/login"
              element={user ? <Navigate to="/" /> : <LoginScreen />}
            />

            <Route exact path="/register" element={<RegisterScreen />} />

            <Route exact path="/contact-us" element={<ContactUsScreen />} />

            <Route
              exact
              path="/forgot"
              element={user ? <Navigate to="/" /> : <ForgotPasswordScreen />}
            />

            <Route
              exact
              path="/doodler-application/success"
              element={
                !user ? (
                  <Navigate to="/register" />
                ) : (
                  <ApplicationSuccessScreen />
                )
              }
            />

            <Route exact path="/discover" element={<DiscoverScreen />} />

            <Route
              exact
              path="/account"
              element={!user ? <Navigate to="/" /> : <AccountScreen />}
            />

            <Route
              exact
              path="/account/wallets"
              element={!user ? <Navigate to="/" /> : <AccountWalletsScreen />}
            />

            <Route
              exact
              path="/account/notifications"
              element={!user ? <Navigate to="/" /> : <NotificationsScreen />}
            />

            <Route
              exact
              path="/account/profile/edit"
              element={!user ? <Navigate to="/" /> : <EditDoodlerScreen />}
            />

            <Route
              exact
              path="/account/profile"
              element={!user ? <Navigate to="/" /> : <ProfileScreen />}
            />

            <Route exact path="/search/:query" element={<SearchScreen />} />

            <Route
              exact
              path="/doodle-request/:doodleRequestId"
              element={<DoodleRequestScreen />}
            />

            <Route
              exact
              path="/account/orders/:orderId"
              element={!user ? <Navigate to="/" /> : <AccountOrderScreen />}
            />

            <Route
              exact
              path="/account/orders"
              element={!user ? <Navigate to="/" /> : <AccountOrdersScreen />}
            />

            <Route
              exact
              path="/account/referrals"
              element={!user ? <Navigate to="/" /> : <AccountReferralsScreen />}
            />

            <Route
              exact
              path="/contests/:contestId"
              element={<ContestScreen />}
            />

            <Route
              exact
              path="/doodlers/:doodlerId/purchase"
              element={<PurchaseDoodleScreen />}
            />

            <Route
              exact
              path="/doodlers/:doodlerId"
              element={<DoodlerScreen />}
            />

            <Route
              exact
              path="/doodlers/:doodlerId/:followType"
              element={<DoodlerFollowScreen />}
            />

            <Route exact path="/doodles" element={<DoodlesScreen />} />

            <Route exact path="/doodles/:doodleId" element={<DoodleScreen />} />

            <Route
              exact
              path="/charity/:doodlerId"
              element={<CharityScreen />}
            />

            <Route
              exact
              path="/doodles/:doodleId/products"
              element={<DoodleProductsScreen />}
            />

            <Route
              exact
              path="/doodles/:doodleId/products/:productId"
              element={<ProductScreen />}
            />

            <Route
              exact
              path="/doodles/:doodleId/playground"
              element={<DoodlePlayGroundScreen />}
            />

            <Route exact path="/tags/:tag" element={<DoodleTagScreen />} />

            <Route
              exact
              path="/doodles/:doodleId/animate"
              element={<DoodleAnimatePathsScreen />}
            />

            <Route
              exact
              path="/doodles/:doodleId/mint"
              element={e => (
                <DoodleMintNFTScreen doodleId={e.match.params.doodleId} />
              )}
            />

            <Route
              exact
              path="/doodles/:doodleId/products/:variantId/purchase"
              element={<DoodlePurchaseProductScreen />}
            />

            <Route
              exact
              path="/account/orders/:orderId/review"
              element={<ReviewDoodleScreen />}
            />

            <Route exact path="/faqs" element={<FAQScreen />} />

            <Route
              exact
              path="/doodler-questions"
              element={<DoodlerFAQScreen />}
            />

            <Route exact path="/talent-faqs" element={<DoodlerFAQScreen />} />

            <Route exact path="/new-doods" element={<NewDoods />} />

            <Route path="*" element={<FourOhFourScreen />} />
          </Routes>
        )}
      </div>
      <AppFooter />
    </Router>
  );
};

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),

  initialAuthComplete: makeSelectInitialAuthComplete(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);

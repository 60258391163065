import React, {useEffect, useRef, useState} from 'react';
import LikedIcon from '../../components/LikedIcon';
import NotLikedIcon from '../../components/NotLikedIcon';
import {getAuth} from 'firebase/auth';
import {doc, getDoc, setDoc} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import {IconButton} from '@mui/material';
import {setIsAuthenticating} from '../App/actions';
import {connect} from 'react-redux';

const SIZE = 18;

const CommentLikeButton = React.memo(
  ({commentDoodleId, comment, dispatch}) => {
    const [isLiked, setIsLiked] = useState(false);

    const baseRef = useRef(
      doc(
        db,
        `doodles/${commentDoodleId}/comments/${comment.id}/likes/${
          getAuth().currentUser ? getAuth().currentUser.uid : null
        }`,
      ),
    ).current;

    useEffect(() => {
      getDoc(baseRef)
        .then(snapshot => {
          if (snapshot.exists()) {
            setIsLiked(snapshot.data().isLiked);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }, []);

    const handlePress = async () => {
      if (!getAuth().currentUser) {
        dispatch(setIsAuthenticating(true));
        return;
      }
      try {
        setIsLiked(!isLiked);

        await setDoc(
          baseRef,
          {
            isLiked: !isLiked,
          },
          {merge: true},
        );
      } catch (err) {
        console.log(err);
      }
    };

    return (
      <IconButton onClick={handlePress}>
        {isLiked && <LikedIcon style={{height: SIZE, width: SIZE}} />}
        {!isLiked && <NotLikedIcon grey style={{height: SIZE, width: SIZE}} />}
      </IconButton>
    );
  },
  () => true,
);
export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(null, mapDispatchToProps)(CommentLikeButton);

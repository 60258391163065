import React, {useEffect, useState} from 'react';
import {Container, Hidden, useMediaQuery} from '@mui/material';
import {Helmet} from 'react-helmet';
import FeaturedTalentRow from '../../containers/FeaturedTalentRow';
import TalentRowByTag from '../../containers/TalentRowByTag';
import HomeScreenBanners from '../../containers/HomeScreenBanners';
import HowItWorksRow from '../../containers/HowItWorksRow';
import GadGrid from '../../containers/GadGrid';
import DoodlesByTag from '../../containers/DoodlesByTag';
import {collection, getDocs, limit, orderBy, query} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import ContestsRow from '../../containers/ContestsRow';

const DiscoverScreen = () => {
  const isDesktop = useMediaQuery('(min-width:600px)');
  const [popularTags, setPopularTags] = useState([]);

  useEffect(() => {
    getDocs(query(collection(db, 'tags'), orderBy('doodles', 'desc'), limit(4)))
      .then(snap => {
        setPopularTags(snap.docs.map(doc => doc.id));
      })
      .catch(err => console.error(err));
  }, []);

  return (
    <Container maxWidth="lg">
      <Helmet
        description="Personalized doodles from your favorite people."
        title="Official Site"
        defaultTitle="Getadoodle"
      />

      <div style={{height: isDesktop ? 43 : 14}} />

      <HomeScreenBanners />
      <div style={{height: isDesktop ? 160 : 70}} />
      <HowItWorksRow />
      <div style={{height: isDesktop ? 120 : 60}} />
      <FeaturedTalentRow />
      <br />
      <TalentRowByTag title="New Additions" tag="new" />
      <br />
      <TalentRowByTag title="Based" tag="based" />
      <br />
      <TalentRowByTag title="Bubblin" tag="bubblin" />
      <ContestsRow />
      <br />
      <GadGrid />
      {popularTags.map(tag => (
        <DoodlesByTag key={tag} tag={tag} />
      ))}
    </Container>
  );
};

export default DiscoverScreen;

import {Avatar, Button, Stack, Typography} from '@mui/material';
import {getAvatarById} from '../../utils';
import FallbackAvatar from '../../components/FallbackAvatar';
import DoodlerInfoBar from '../../components/DoodlerInfoBar';
import {Link} from 'react-router-dom';
import DoodlerActions from '../DoodlerActions';
import Center from '../Center';
import React, {useEffect, useState} from 'react';
import CharityRow from '../CharityRow';
import {doc, getDoc} from 'firebase/firestore';
import {db} from '../../clients/Firebase';

const ProfileHeader = ({doodler, isUsersProfile}) => {
  const [followingDoc, setFollowingDoc] = useState(null);

  useEffect(() => {
    if (isUsersProfile) {
      getDoc(doc(db, 'followCounts', doodler.id)).then(snap => {
        setFollowingDoc(snap.data());
      });
    }
  }, [isUsersProfile]);

  const getPrefix = num => {
    if (num < 10) {
      return '00';
    } else if (num < 100) {
      return '0';
    } else {
      return '';
    }
  };

  return (
    <Center
      style={{
        height: 500,
        textAlign: 'center',
      }}>
      <Stack
        style={{
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        spacing={2}>
        <Stack spacing={4} alignItems="center" direction="row">
          {followingDoc && (
            <Link to={`/doodlers/${doodler.username}/followers`}>
              <div>
                <Typography variant="h3">
                  {getPrefix(followingDoc.followers)}
                  {followingDoc.followers}
                </Typography>
                <Typography fontWeight="600" fontSize="small" color="darkGrey">
                  Followers
                </Typography>
              </div>
            </Link>
          )}
          <Avatar
            sx={{width: 161, height: 161}}
            alt={doodler.displayName}
            src={getAvatarById(doodler.id)}>
            <FallbackAvatar />
          </Avatar>
          {followingDoc && (
            <Link to={`/doodlers/${doodler.username}/following`}>
              <div>
                <Typography variant="h3">
                  {getPrefix(followingDoc.following)}
                  {followingDoc.following}
                </Typography>
                <Typography fontWeight="600" fontSize="small" color="darkGrey">
                  Following
                </Typography>
              </div>
            </Link>
          )}
        </Stack>

        <div className="profile-name" style={{position: 'relative'}}>
          <Stack direction="row">
            <Typography variant="h3">{doodler.displayName}</Typography>
          </Stack>
        </div>

        <DoodlerInfoBar doodler={doodler} />

        {doodler.bio && <Typography>{doodler.bio}</Typography>}

        {isUsersProfile && (
          <Link style={{width: '100%'}} to={`/account/profile/edit`}>
            <Button size="large" fullWidth variant="outlined">
              EDIT PROFILE
            </Button>
          </Link>
        )}

        {!isUsersProfile && doodler.activated && (
          <div style={{width: '100%'}}>
            <DoodlerActions isUsersProfile={isUsersProfile} doodler={doodler} />
          </div>
        )}

        {doodler.charity && <CharityRow charityId={doodler.charity} />}
      </Stack>
    </Center>
  );
};

export default ProfileHeader;

import {createSelector} from 'reselect';

const selectApp = state => state.app;

const makeSelectUser = () =>
  createSelector(
    selectApp,

    appState => appState.user,
  );

const makeSelectIsAuthenticating = () =>
  createSelector(
    selectApp,

    appState => appState.isAuthenticating,
  );

const makeSelectRegParams = () =>
  createSelector(
    selectApp,

    appState => appState.regParams,
  );

const makeSelectInitialAuthComplete = () =>
  createSelector(
    selectApp,

    appState => appState.initialAuthComplete,
  );

const makeSelectNewNotificationCount = () =>
  createSelector(
    selectApp,

    appState => appState.newNotificationCount,
  );

export {
  makeSelectUser,
  makeSelectIsAuthenticating,
  makeSelectRegParams,
  makeSelectInitialAuthComplete,
  makeSelectNewNotificationCount,
};

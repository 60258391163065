import React, {useEffect, useState} from 'react';
import {collection, getDocs, orderBy, query, where} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import {Typography} from '@mui/material';
import TalentGridRow from '../TalentGridRow';

const FeaturedTalentRow = ({title, category}) => {
  const [talent, setTalent] = useState([]);

  useEffect(() => {
    fetchTalentAsync();
  }, []);

  const fetchTalentAsync = async () => {
    const q = query(
      collection(db, 'users'),
      where('doodler', '==', true),
      where('activated', '==', true),
      where('tags', 'array-contains', 'featured'),
      orderBy('gadIndex', 'desc'),
    );

    const querySnapshot = await getDocs(q);

    const _talent = [];

    querySnapshot.forEach(doc => {
      _talent.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    setTalent(_talent);
  };

  return (
    <div>
      <Typography style={{marginBottom: 32}} variant="h3" paragraph>
        Featured Artists
      </Typography>
      <TalentGridRow talent={talent} />
    </div>
  );
};

export default FeaturedTalentRow;

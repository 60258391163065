import React from 'react';
import {Container, IconButton, Stack, Typography} from '@mui/material';
import PageHeader from '../../containers/PageHeader';
import {createStructuredSelector} from 'reselect';
import {makeSelectUser} from '../../containers/App/selectors';
import {connect} from 'react-redux';
import {Check, CopyAllRounded} from '@mui/icons-material';

const AccountReferralsScreen = ({user}) => {
  return (
    <Container maxWidth="sm">
      <PageHeader>Referrals</PageHeader>
      <Stack spacing={2}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between">
          <Typography>Code</Typography>
          <Stack alignItems="center" direction="row">
            <Typography>{user.id}</Typography>{' '}
            <IconButton
              onClick={async () => {
                try {
                  await navigator.clipboard.writeText(user.id);
                  alert('Your referral code has been copied to your clipboard');
                } catch (err) {}
              }}>
              <CopyAllRounded />
            </IconButton>
          </Stack>
        </Stack>
        <div>
          <ol style={{'padding-inline-start': 25, 'margin-block-start': 0}}>
            <li>Share your referral code with Talent</li>
            <li>Talent joins Getadoodle</li>
            <li>Talent activates account on mobile app</li>
            <li>Talent adds code to “referral code” field</li>
            <li>You earn 5% of net revenues for one year</li>
            <li>Referral fee taken from Getadoodle’s cut</li>
          </ol>
        </div>
        <Stack alignItems="center" direction="row">
          <Check color="secondary" /> Everybody wins
        </Stack>
      </Stack>
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});

export default connect(mapStateToProps, null)(AccountReferralsScreen);

import React from 'react';
const Asset = require('../../assets/verified.png');

const VerifiedBadge = ({width}) => (
  <img
    className="doodler-badge"
    style={{width: width || '100%', height: width || 'auto'}}
    src={Asset}
    alt="verified"
  />
);

export default VerifiedBadge;

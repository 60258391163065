import React, {useCallback, useEffect, useState} from 'react';
import {collection, getDocs, orderBy, query, where} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import {Typography} from '@mui/material';
import TalentGridRow from '../TalentGridRow';

const TalentRowByTag = ({title, tag}) => {
  const [talent, setTalent] = useState([]);

  const fetchTalentAsync = useCallback(async () => {
    const q = query(
      collection(db, 'users'),
      where('doodler', '==', true),
      where('activated', '==', true),
      where('tags', 'array-contains', tag),
      orderBy('gadIndex', 'desc'),
    );

    const querySnapshot = await getDocs(q);

    const _talent = [];

    querySnapshot.forEach(doc => {
      _talent.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    setTalent(_talent);
  }, [tag]);

  useEffect(() => {
    fetchTalentAsync().catch(err => {
      console.error(err);
    });
  }, [fetchTalentAsync]);

  return (
    <div>
      <Typography style={{marginBottom: 32}} variant="h3" paragraph>
        {title}
      </Typography>
      <TalentGridRow talent={talent} />
    </div>
  );
};

export default TalentRowByTag;

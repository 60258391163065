import theme from '../../theme';
import React from 'react';
import ContestTimeRemaining from '../ContestTimeRemaining';

const ContestRibbon = ({contest}) => (
  <div
    style={{
      whiteSpace: 'nowrap',
      backgroundColor: theme.palette.red,
      color: 'white',
      fontSize: 9,
      lineHeight: '14px',
      position: 'fixed',
      zIndex: 9,
      width: '100%',
      left: 0,
    }}>
    {Array.from(Array(100).keys()).map(key => (
      <span
        style={{marginLeft: 12, textTransform: 'uppercase'}}
        key={`tr-${key}`}>
        <strong>DEADLINE</strong>{' '}
        <ContestTimeRemaining date={contest.endDate} />
        {'\t'}
      </span>
    ))}
  </div>
);

export default ContestRibbon;

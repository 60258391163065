import {Button, Container, Grid} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {
  collection,
  getDocs,
  limit,
  query,
  where,
  orderBy,
  startAfter,
} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import {Link} from 'react-router-dom';
import PageHeader from '../../containers/PageHeader';
import moment from 'moment';

const DoodlesScreen = () => {
  const [doodles, setDoodles] = useState([]);

  useEffect(() => {
    fetchDoodlesAsync();
  }, []);

  const fetchDoodlesAsync = async () => {
    try {
      const querySnapshot = await getDocs(
        query(
          collection(db, 'doodles'),
          where('public', '==', true),
          where('deleted', '==', false),
          orderBy('createdAt', 'desc'),
          startAfter(
            doodles[doodles.length - 1]?.createdAt || moment().toDate(),
          ),
          limit(100),
        ),
      );

      setDoodles(
        doodles.concat(
          querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          })),
        ),
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container maxWidth="lg">
      <PageHeader>Oodles of doodles</PageHeader>
      <br />
      <Grid spacing={2} container>
        {doodles.map(doodle => (
          <Grid item xs={12} sm={4} key={doodle.id}>
            <Link to={`/doodles/${doodle.id}`}>
              <img alt={doodle.name} src={doodle.uri} />
            </Link>
          </Grid>
        ))}
      </Grid>
      <Button onClick={fetchDoodlesAsync}>Load more</Button>
    </Container>
  );
};

export default DoodlesScreen;

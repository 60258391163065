import {Container, LinearProgress} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {doc, getDoc} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import PageHeader from '../../containers/PageHeader';
import {useParams} from 'react-router-dom';

const DoodleAnimatePathsScreen = () => {
  const {doodleId} = useParams();
  const [loading, setLoading] = useState(false);
  const [doodlePaths, setDoodlePaths] = useState(null);

  const fetchDoodlePaths = useCallback(async () => {
    setLoading(true);
    const docRef = doc(db, 'doodlePaths', doodleId);

    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setDoodlePaths(docSnap.data());
    }

    setLoading(false);
  }, [doodleId]);

  useEffect(() => {
    fetchDoodlePaths().catch(err => console.error(err));
  }, [fetchDoodlePaths]);

  if (loading) return <LinearProgress />;

  if (!loading && !doodlePaths)
    return (
      <Container maxWidth="lg">
        <PageHeader>No paths available</PageHeader>
      </Container>
    );

  return (
    <Container maxWidth="lg">
      <PageHeader>Animate Test</PageHeader>
      <div style={{overflow: 'hidden'}}>
        <svg height={362} width={362}>
          {doodlePaths.paths.map(path => {
            const a = path.color.toString(16);

            const stroke = '#' + a.slice(2) + a.slice(0, 2);
            return (
              <path
                className="path"
                fill="transparent"
                stroke={stroke}
                strokeWidth={path.size}
                d={path.path}
                pathLength="1"
              />
            );
          })}
        </svg>
      </div>
    </Container>
  );
};

export default DoodleAnimatePathsScreen;

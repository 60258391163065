const {APP_LINK} = require('../../constants');
const React = require('react');

const SRC = require('../../assets/about/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg');
const AppStoreButton = ({width = 187}) => (
  <a
    style={{display: 'contents'}}
    rel="noopener "
    href={APP_LINK}
    target="_blank">
    <img alt="Download App" style={{width}} src={SRC} />
  </a>
);

export default AppStoreButton;

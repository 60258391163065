import {Container, Stack, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import React, {useCallback, useEffect} from 'react';
import {createStructuredSelector} from 'reselect';
import {makeSelectUser} from '../../containers/App/selectors';
import {
  makeSelectConnectedWallet,
  makeSelectWeb3,
} from '../../containers/Web3Wrapper/selectors';
import {connect} from 'react-redux';
import {connectWeb3} from '../../containers/Web3Wrapper/actions';
import FunctionClient from '../../clients/Functions';
import PageHeader from '../../containers/PageHeader';

const AccountWalletsScreen = ({user, web3, dispatch, connectedWallet}) => {
  const connectWallet = useCallback(async () => {
    if (!web3) {
      dispatch(connectWeb3());
      return;
    }
    if ((user.wallets || []).indexOf(connectedWallet) >= 0) {
      return;
    }

    // todo GET THIS FROM SERVER YOU SCHMUCK
    const message = `getadoodle rocks`;

    //Sign message with Metamask (private key)
    const signature = await web3.eth.personal.sign(message, connectedWallet);

    await FunctionClient.connectWallet({signature});
  }, [user]);

  useEffect(() => {
    if (connectedWallet) {
      connectWallet().catch(err => console.error(err));
    }
  }, [connectWallet]);

  return (
    <Container maxWidth="sm">
      <PageHeader>Connected Wallets</PageHeader>
      <Stack style={{marginTop: 12}} spacing={2}>
        {(user.wallets || []).map(wallet => (
          <Typography key={wallet}>{wallet}</Typography>
        ))}
        {(user.wallets || []).indexOf(connectedWallet) === -1 && (
          <LoadingButton onClick={connectWallet} variant="contained">
            CONNECT WALLET
          </LoadingButton>
        )}
      </Stack>
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),

  web3: makeSelectWeb3(),

  connectedWallet: makeSelectConnectedWallet(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountWalletsScreen);

import * as React from 'react';
import {styled} from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import {createStructuredSelector} from 'reselect';
import {connect} from 'react-redux';
import {makeSelectIsDrawerShowing} from './selectors';
import {setShowDrawer} from './actions';
import {AppBar, Toolbar} from '@mui/material';
import DoodleComments from '../DoodleComments';

const drawerWidth = window.innerWidth < 600 ? window.innerWidth : 400;

const Main = styled('main', {shouldForwardProp: prop => prop !== 'open'})(
  ({theme, open}) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
    width: '100%',
  }),
);

const AppDrawer = ({isDrawerShowing, dispatch, children, doodle}) => {
  return (
    <div>
      <Main open={isDrawerShowing}>{children}</Main>
      <Drawer
        elevation={1}
        BackdropProps={{invisible: true}}
        onClose={() => dispatch(setShowDrawer(false))}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            backgroundColor: '#f1f1f1',
            overflow: 'visible',
            zIndex: '1000 !important',
          },
        }}
        variant={'temporary'}
        anchor="right"
        open={isDrawerShowing}>
        <AppBar position="static" elevation={0} style={{opacity: 0}}>
          <Toolbar />
        </AppBar>
        {isDrawerShowing && <DoodleComments doodle={doodle} />}
      </Drawer>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  isDrawerShowing: makeSelectIsDrawerShowing(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppDrawer);

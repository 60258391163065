import React from 'react';
import {getDoodleUrlById} from '../../utils';
import theme from '../../theme';
import LikeDoodleButton from '../LikeDoodleButton';
import {Link} from 'react-router-dom';

const ContestDoodleSquare = ({doodle}) => {
  if (typeof doodle === 'number') {
    return (
      <div
        style={{paddingTop: '100%', backgroundColor: theme.palette.lightGrey}}
      />
    );
  }

  return (
    <Link to={`/doodles/${doodle.id}`}>
      <div style={{position: 'relative'}}>
        <img width="100%" src={getDoodleUrlById(doodle.id)} alt={doodle.name} />
        <div style={{position: 'absolute', right: 3, bottom: 10}}>
          <LikeDoodleButton doodle={doodle} />
        </div>
      </div>
    </Link>
  );
};

export default ContestDoodleSquare;

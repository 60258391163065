import React from 'react';
import {IconButton} from '@mui/material';

const SRC = require('../../assets/icons/share.png');

const ShareDoodleButton = () => {
  const handleClick = async e => {
    e.preventDefault();

    try {
      await navigator.clipboard.writeText(window.location.href);
      alert('Getadoodle link has been copied to your clipboard');
    } catch (err) {}
  };

  return (
    <IconButton onClick={handleClick}>
      <img style={{width: 28}} src={SRC} />
    </IconButton>
  );
};

export default ShareDoodleButton;

import {fromJS} from 'immutable';
import {
  SET_INITIAL_AUTH_COMPLETE,
  SET_IS_AUTHENTICATING,
  SET_NEW_NOTIFICATION_COUNT,
  SET_REG_PARAMS,
  SET_USER,
} from './constants';

const initialState = fromJS({
  user: null,
  isAuthenticating: false,
  regParams: null,
  initialAuthComplete: false,
  newNotificationCount: 0,
});

function appReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.user,
      };

    case SET_REG_PARAMS:
      return {
        ...state,
        regParams: action.regParams,
      };

    case SET_IS_AUTHENTICATING:
      return {
        ...state,
        isAuthenticating: action.isAuthenticating,
      };

    case SET_INITIAL_AUTH_COMPLETE:
      return {
        ...state,
        initialAuthComplete: action.initialAuthComplete,
      };

    case SET_NEW_NOTIFICATION_COUNT:
      return {
        ...state,
        newNotificationCount: action.newNotificationCount,
      };

    default:
      return state;
  }
}

export {appReducer};

import React from 'react';
import {Container, LinearProgress, Box, Tabs, Tab} from '@mui/material';
import {useEffect, useState} from 'react';
import {db} from '../../clients/Firebase';
import {
  getDocs,
  query,
  collection,
  where,
  limit,
  doc,
  getDoc,
} from 'firebase/firestore';
import {createStructuredSelector} from 'reselect';
import {makeSelectUser} from '../../containers/App/selectors';
import {connect} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {getAvatarById} from '../../utils';
import mixpanel from 'mixpanel-browser';
import ProfileHeader from '../../containers/ProfileHeader';
import CharityDoodlers from '../../containers/CharityDoodlers';
import CharitySupporters from '../../containers/CharitySupporters';
import CharityBar from '../../components/CharityBar';
import theme from '../../theme';

const CharityScreen = ({user}) => {
  const {doodlerId} = useParams();

  const [doodler, setDoodler] = useState(null);
  const [value, setValue] = useState('talent');

  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setDoodler(null);
    loadDoodlerAsync();
  }, [doodlerId]);

  useEffect(() => {
    if (doodler) {
      mixpanel.track('Doodler Viewed', {
        path: doodler.displayName,
      });
    }
  }, [doodler]);

  const loadDoodlerAsync = async () => {
    const querySnapshot = await getDocs(
      query(
        collection(db, 'users'),
        where('username', '==', doodlerId),
        limit(1),
      ),
    );

    if (!querySnapshot.empty) {
      if (querySnapshot.docs[0].data().doodler) setValue('created');
      setDoodler({
        ...querySnapshot.docs[0].data(),
        id: querySnapshot.docs[0].id,
      });
    } else {
      const docSnap = await getDoc(doc(db, 'users', doodlerId));

      if (docSnap.data().doodler) setValue('created');

      navigate(`/charity/${docSnap.data().username}`, {replace: true});

      setDoodler({
        ...docSnap.data(),
        id: docSnap.id,
      });
    }
  };

  if (!doodler) return <LinearProgress />;

  const isUsersProfile = user && user.id === doodler.id;

  const description = `Get your very own personalized doodle from ${doodler.displayName} at getadoodle.com today!`;

  return (
    <React.Fragment>
      <CharityBar />
      <Container maxWidth="lg">
        <Helmet>
          <title>{doodler.displayName}</title>
          <meta name="description" content={description} />
          <meta property="og:title" content={doodler.displayName} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={getAvatarById(doodler.id)} />
          <meta
            property="og:url"
            content={`https://getadoodle.com/charities/${doodler.username}`}
          />
          <meta name="twitter:title" content={doodler.displayName} />
          <meta name="twitter:site" content="getadoodle" />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content={doodler.avatar} />
          <meta name="twitter:card" content="summary" />
        </Helmet>
        <br />
        <br />
        <Container disableGutters maxWidth="sm">
          <ProfileHeader doodler={doodler} isUsersProfile={isUsersProfile} />
        </Container>
        <br />
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
          <Tabs
            TabIndicatorProps={{
              sx: {backgroundColor: theme.palette.red},
            }}
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example">
            <Tab label="Talent" value="talent" />
            <Tab label="Supporters" value="supporters" />
          </Tabs>
        </Box>
        <br />
        {value === 'talent' && <CharityDoodlers charityId={doodler.id} />}
        {value === 'supporters' && <CharitySupporters charityId={doodler.id} />}
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});

export default connect(mapStateToProps, null)(CharityScreen);

import React, {useEffect, useState} from 'react';
import {Box, Grid, IconButton, Stack, Typography} from '@mui/material';
import {collection, getDocs, orderBy, query, where} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import ContestDoodleSquare from '../ContestDoodleSquare';
import ContestQuestionsDialog from '../../components/ContestQuestionsDialog';
const ContestEntries = ({contest}) => {
  const [doodles, setDoodles] = useState([]);

  useEffect(() => {
    getDocs(
      query(
        collection(db, 'doodles'),
        where('contest', '==', contest.id),
        where('deleted', '==', false),
        where('public', '==', true),
        orderBy('createdAt', 'desc'),
      ),
    )
      .then(snap => {
        let _doodles = snap.docs.map(doc => ({id: doc.id, ...doc.data()}));

        if (_doodles.length < 12) {
          _doodles = _doodles.concat(
            Array.from(Array(12 - _doodles.length).keys()).map(index => index),
          );
        }

        setDoodles(_doodles);
      })
      .catch(err => {
        console.error(err);
      });
  }, [contest]);

  return (
    <Stack spacing={2}>
      <Stack
        justifyContent="space-between"
        alignItems="center"
        direction="row"
        spacing={2}>
        <Typography variant="h3">
          {contest.live ? 'Vote' : 'Entries'}
        </Typography>
        <Stack spacing={2} direction="row">
          <IconButton
            onClick={async () => {
              try {
                await navigator.clipboard.writeText(window.location.href);
                alert('Contest link has been copied to your clipboard');
              } catch (err) {}
            }}>
            <img
              style={{width: 46, height: 46}}
              src={require('../../assets/icons/contest-shareicon.png')}
              alt="contest icon"
            />
          </IconButton>
          <ContestQuestionsDialog />
        </Stack>
      </Stack>
      <Box sx={{flexGrow: 1}}>
        <Grid spacing={2} container>
          {doodles.map((doodle, index) => (
            <Grid
              xs={6}
              sm={4}
              md={3}
              key={doodle.id || `doodle-entry-${index}`}
              item>
              <ContestDoodleSquare doodle={doodle} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Stack>
  );
};

export default ContestEntries;

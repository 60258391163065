import {Stack, Typography} from '@mui/material';
import DoodlerAvatar from '../../components/DoodlerAvatar';
import {getAvatarById} from '../../utils';
import PageHeader from '../../containers/PageHeader';
import React from 'react';

const Header = ({doodler}) => (
  <PageHeader>
    <Stack alignItems="center" spacing={2}>
      <Stack alignItems="center" direction="row" spacing={2}>
        <DoodlerAvatar
          sx={{width: 56, height: 56}}
          src={getAvatarById(doodler.id)}
        />
        {doodler.charity && (
          <DoodlerAvatar
            style={{marginLeft: -13}}
            sx={{width: 56, height: 56}}
            src={getAvatarById(doodler.charity)}
          />
        )}
      </Stack>
      <Typography>
        <strong>Commission from {doodler.displayName}</strong>
      </Typography>
    </Stack>
  </PageHeader>
);
export default Header;

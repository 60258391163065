import {put, takeLatest, all, select} from 'redux-saga/effects';
import Web3 from 'web3';
import {CONNECT_WEB3, SET_CONNECTED_WALLET, SET_WEB3} from './constants';
import WalletConnectProvider from '@walletconnect/web3-provider';
import Web3Modal from 'web3modal';
import GetadoodleContract from '../../contracts/rinkeby/Getadoodle.json';
import {
  setChain,
  setProvider,
  setWeb3,
  setConnectedWallet,
  setEthBalance,
  setGetadoodleContract,
} from './actions';
import {makeSelectWeb3} from './selectors';

export function* connectGetadoodleContractSaga({web3}) {
  if (!web3) {
    yield put(setGetadoodleContract(null));
    return;
  }

  try {
    const threadTokenInstance = new web3.eth.Contract(
      GetadoodleContract.abi,
      GetadoodleContract.address,
    );

    yield put(setGetadoodleContract(threadTokenInstance));
  } catch (error) {
    console.error(error);
  }
}

export function* fetchEthBalanceSaga({connectedWallet}) {
  const web3 = yield select(makeSelectWeb3());

  if (!web3 || !connectedWallet) {
    return;
  }

  try {
    const _bal = yield web3.eth.getBalance(connectedWallet);

    yield put(setEthBalance((_bal / 10 ** 18).toFixed(2)));
  } catch (error) {
    console.error(error);
  }
}

export function* connectWeb3Saga() {
  try {
    const web3Modal = new Web3Modal({
      providerOptions: {
        walletconnect: {
          package: WalletConnectProvider,
          rpc: {
            43114: 'https://api.avax.network/ext/bc/C/rpc',
          },
          options: {
            infuraId: '0a44e4ac8daf4b87bd2f4b48ed7a947a',
            qrcodeModalOptions: {
              mobileLinks: [
                'rainbow',
                'metamask',
                'argent',
                'trust',
                'imtoken',
                'pillar',
              ],
            },
          },
        },
      },
    });

    const provider = yield web3Modal.connect();

    yield put(setProvider(provider));

    const web3 = new Web3(provider);

    const accounts = yield web3.eth.getAccounts();

    const chainId = yield web3.eth.getChainId();

    yield put(setWeb3(web3));

    yield put(setConnectedWallet(accounts[0]));

    yield put(setChain('0x' + chainId.toString(16)));
  } catch (err) {
    console.log(err);
  }
}

export function* connectWeb3Watcher() {
  yield takeLatest(CONNECT_WEB3, connectWeb3Saga);
}

export function* setConnectedWalletWatcher() {
  yield takeLatest(SET_CONNECTED_WALLET, fetchEthBalanceSaga);
}

export function* setGetadoodleContractWatcher() {
  yield takeLatest(SET_WEB3, connectGetadoodleContractSaga);
}

export default function* rootAccountSaga() {
  yield all([
    connectWeb3Watcher(),
    setConnectedWalletWatcher(),
    setGetadoodleContractWatcher(),
  ]);
}

import React from 'react';
import App from './containers/App';
import {Provider} from 'react-redux';
import store from './store';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import theme from './theme';
import {ThemeProvider} from '@mui/material';
import mixpanel from 'mixpanel-browser';

mixpanel.init('6018e131fc769ce205d09d9c499d3e63', {debug: false});

export default () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  );
};

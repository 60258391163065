import React, {useRef, useState} from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from '@mui/material';
import {MoreHorizRounded} from '@mui/icons-material';
import {connect} from 'react-redux';
import {setIsAuthenticating} from '../App/actions';
import {setDoc, doc, deleteDoc} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import {getAuth} from 'firebase/auth';
import {getConfirmation} from '../../utils';

const reasons = [
  {title: 'Intellectual property violation'},
  {title: 'Nudity or sexual activity'},
  {title: 'Hate speech or symbols'},
  {title: 'It offends me'},
];

const DoodleMoreOptions = ({dispatch, doodle}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [showReport, setShowReport] = useState(false);

  const isUsers = useRef(
    getAuth().currentUser && doodle.doodler === getAuth().currentUser.uid,
  ).current;

  const reportDoodle = async reason => {
    try {
      await setDoc(
        doc(db, 'doodleReported', doodle.id),
        {[getAuth().currentUser.uid]: reason},
        {merge: true},
      );
    } catch (err) {
      console.log(err);
    }
  };
  const deleteGetadoodle = async () => {
    setShowOptions(false);
    const res = await getConfirmation({
      title: 'Confirm Delete',
      text: 'Are you sure you want to delete your Getadoodle?',
    });

    if (res) {
      deleteDoc(doc(db, 'doodles', doodle.id)).catch(err => console.log(err));
    }
  };

  return (
    <div>
      <Dialog
        onClose={() => setShowOptions(false)}
        fullWidth
        maxWidth="sm"
        open={showOptions}>
        <DialogContent>
          <List>
            <ListItem>
              <ListItemButton
                onClick={() => {
                  setShowOptions(false);
                  if (!getAuth().currentUser) {
                    dispatch(setIsAuthenticating(true));
                    return;
                  }
                  setShowReport(true);
                }}>
                <Typography
                  color="red"
                  style={{width: '100%', textAlign: 'center'}}>
                  Report
                </Typography>
              </ListItemButton>
            </ListItem>

            <Divider />

            {doodle.ipfsHash && (
              <React.Fragment>
                <ListItem>
                  <ListItemButton
                    onClick={() =>
                      window.open(
                        doodle.ipfsFriendlyUrl.includes('metadata.json')
                          ? doodle.ipfsFriendlyUrl
                          : doodle.ipfsFriendlyUrl + '/metadata.json',
                      )
                    }>
                    <Typography style={{width: '100%', textAlign: 'center'}}>
                      View Certificate
                    </Typography>
                  </ListItemButton>
                </ListItem>
                <Divider />
              </React.Fragment>
            )}
            <ListItem>
              <ListItemButton
                onClick={() => {
                  setShowOptions(false);
                  navigator.clipboard.writeText(window.location.href);
                }}>
                <Typography style={{width: '100%', textAlign: 'center'}}>
                  Copy Link
                </Typography>
              </ListItemButton>
            </ListItem>
            <Divider />

            {isUsers && (
              <React.Fragment>
                <ListItem onClick={deleteGetadoodle}>
                  <ListItemButton onClick={() => {}}>
                    <Typography style={{width: '100%', textAlign: 'center'}}>
                      Delete Your Getadoodle
                    </Typography>
                  </ListItemButton>
                </ListItem>
                <Divider />
              </React.Fragment>
            )}

            <Divider />

            <ListItem>
              <ListItemButton onClick={() => setShowOptions(false)}>
                <Typography style={{width: '100%', textAlign: 'center'}}>
                  Cancel
                </Typography>
              </ListItemButton>
            </ListItem>
          </List>
        </DialogContent>
      </Dialog>
      <Dialog
        onClose={() => setShowReport(false)}
        fullWidth
        maxWidth="sm"
        open={showReport}>
        <DialogTitle>Report</DialogTitle>
        <DialogContent>
          <ListItem disableGutters>
            <Typography>Why are you reporting this Doodle?</Typography>
          </ListItem>
          <Divider />
          <List disablePadding>
            {reasons.map(reason => (
              <span key={reason.title}>
                <ListItem disableGutters key={reason.title}>
                  <ListItemButton
                    style={{marginLeft: -16}}
                    onClick={async () => {
                      setShowReport(false);
                      await reportDoodle(reason.title);
                    }}>
                    <Typography>{reason.title}</Typography>
                  </ListItemButton>
                  <Divider />
                </ListItem>
                <Divider />
              </span>
            ))}
          </List>
        </DialogContent>
      </Dialog>
      <IconButton onClick={() => setShowOptions(!showOptions)}>
        <MoreHorizRounded color="primary" />
      </IconButton>
    </div>
  );
};

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(null, mapDispatchToProps)(DoodleMoreOptions);

const DEFAULT_AVATAR =
  'https://firebasestorage.googleapis.com/v0/b/doodles-5658c.appspot.com/o/static%2Favatar.png?alt=media';
const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
const BLOCK_EXPLORER = 'https://rinkeby.etherscan.io';
const OPEN_SEA_URI =
  'https://testnets.opensea.io/assets/rinkeby/0x3cbb7c39e5a7d0ab33c6412493ce67ea19061e53';
const APP_LOGO = 'https://getadoodle-69.web.app/getadoodle-logo-1024.png';
const APP_LINK = 'https://apps.apple.com/us/app/getadoodle/id1617177472';

export {
  DEFAULT_AVATAR,
  ZERO_ADDRESS,
  BLOCK_EXPLORER,
  OPEN_SEA_URI,
  APP_LOGO,
  APP_LINK,
};

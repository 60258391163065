import {fromJS} from 'immutable';
import {
  SET_CHAIN,
  SET_CONNECTED_WALLET,
  SET_ETH_BALANCE,
  SET_GETADOODLE_CONTRACT,
  SET_PROVIDER,
  SET_WEB3,
} from './constants';

const initialState = fromJS({
  web3: null,
  provider: null,
  chainId: null,
  connectedWallet: null,
  ethBalance: 0,
  getadoodleContract: null,
});

function web3Reducer(state = initialState, action) {
  switch (action.type) {
    case SET_WEB3:
      return {
        ...state,
        web3: action.web3,
      };

    case SET_PROVIDER:
      return {
        ...state,
        provider: action.provider,
      };

    case SET_CHAIN:
      return {
        ...state,
        chainId: action.chainId,
      };

    case SET_CONNECTED_WALLET:
      return {
        ...state,
        connectedWallet: action.connectedWallet,
      };

    case SET_ETH_BALANCE:
      return {
        ...state,
        ethBalance: action.ethBalance,
      };

    case SET_GETADOODLE_CONTRACT:
      return {
        ...state,
        getadoodleContract: action.getadoodleContract,
      };

    default:
      return state;
  }
}

export {web3Reducer};

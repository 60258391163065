import React from 'react';
import {Button, Container, Typography} from '@mui/material';
import {Link} from 'react-router-dom';
import PageHeader from '../../containers/PageHeader';

const ApplicationSuccess = () => {
  return (
    <Container maxWidth="sm">
      <PageHeader>Application Received</PageHeader>
      <Typography paragraph>
        We've received your application and will be in touch. Thanks!
      </Typography>
      <Link to="/">
        <Button fullWidth size="large" variant="contained">
          BROWSE DOODLERS
        </Button>
      </Link>
    </Container>
  );
};

export default ApplicationSuccess;

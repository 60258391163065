import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import {Link} from 'react-router-dom';
import {getAvatarById, getDoodleUrlById} from '../../utils';
import FallbackAvatar from '../../components/FallbackAvatar';
import moment from 'moment/moment';
import React, {useEffect} from 'react';
import {updateDoc, doc} from 'firebase/firestore';
import {auth, db} from '../../clients/Firebase';

const NotificationLineItem = ({notification}) => {
  useEffect(() => {
    if (notification.data().unseen) {
      updateDoc(
        doc(db, `users/${auth.currentUser.uid}/notifications`, notification.id),
        {unseen: false},
      ).catch(error => {
        console.error('Error updating document: ', error);
      });
    }
  }, [notification]);

  return (
    <React.Fragment>
      <ListItem
        disablePadding
        secondaryAction={
          Boolean(notification.data().doodleId) && (
            <Link to={`/doodles/${notification.data().doodleId}`}>
              <img
                style={{width: 42, marginTop: 10}}
                src={getDoodleUrlById(notification.data().doodleId)}
              />
            </Link>
          )
        }>
        <ListItemAvatar>
          <Link to={`/doodlers/${notification.data().userId}`}>
            <Avatar src={getAvatarById(notification.data().userId)}>
              <FallbackAvatar />
            </Avatar>
          </Link>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Stack>
              <Typography style={{marginBottom: -2, fontSize: 16}}>
                <Link to={`/doodlers/${notification.data().userId}`}>
                  {notification.data().title}
                </Link>
              </Typography>
              <Typography style={{marginBottom: -2, fontSize: 16}}>
                {notification.data().body}
              </Typography>
              <Typography
                style={{marginBottom: -2, fontSize: 12}}
                variant="caption"
                color="darkgrey">
                {moment(notification.data().createdAt.toDate()).fromNow()}
              </Typography>
            </Stack>
          }
        />
      </ListItem>
    </React.Fragment>
  );
};

export default NotificationLineItem;

import React from 'react';
import {Button, Stack, Typography} from '@mui/material';
import {Link} from 'react-router-dom';
import TagChip from '../../components/TagChip';
import CommentButton from '../CommentButton';
import LikeDoodleButton from '../LikeDoodleButton';
import DoodleMoreOptions from '../DoodleMoreOptions';
import moment from 'moment';
import DoodleCommentCount from '../../components/DoodleCommentCount';
import {createStructuredSelector} from 'reselect';
import {makeSelectUser} from '../App/selectors';
import {connect} from 'react-redux';
import SignWithSelfieIcon from '../../components/SignWithSelfieIcon';

const DoodleDetails = ({doodle, user, isMobile}) => {
  return (
    <Stack
      justifyContent="space-between"
      style={{height: '100%', paddingBottom: 8}}>
      <Stack style={{marginTop: -12}} spacing={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Typography variant="h5">
            <strong>{doodle.name}</strong>
          </Typography>
          {!isMobile && (
            <DoodleOptions spacing={0} doodle={doodle} user={user} />
          )}
        </Stack>
        {doodle.title && (
          <Typography>
            <strong>Title </strong> {doodle.title}
          </Typography>
        )}
        <Typography>
          <strong>By </strong>{' '}
          <Link to={`/doodlers/${doodle.doodler}`}>{doodle.doodlerName}</Link>
        </Typography>

        {doodle.requesterName && (
          <Typography>
            <strong>Commissioned by </strong>{' '}
            <Link to={`/doodlers/${doodle.requester}`}>
              {doodle.requesterName}
            </Link>{' '}
            {doodle.doodleFor && `for ${doodle.doodleFor}`}
          </Typography>
        )}

        <DoodleCommentCount doodle={doodle} />

        <Typography color="darkGrey" variant="caption">
          <strong>
            Published {moment(doodle.createdAt.toDate()).fromNow()}
          </strong>
        </Typography>

        {doodle.tags && doodle.tags.length > 0 && (
          <Stack spacing={1}>
            <Typography>
              <strong>Tags</strong>
            </Typography>
            <Stack direction="row" spacing={1}>
              {doodle.tags.map(tag => (
                <TagChip tag={tag} key={tag} />
              ))}
            </Stack>
          </Stack>
        )}
      </Stack>

      <Stack spacing={2}>
        {user && user.isAdmin && (
          <Stack spacing={2} direction="row">
            <Link
              style={{width: '100%'}}
              to={`/doodles/${doodle.id}/playground`}>
              <Button fullWidth variant="outlined">
                PLAYGROUND
              </Button>
            </Link>
            <a
              style={{width: '100%'}}
              target="_blank"
              href={`getadoodle://doodle-remix/${doodle.id}`}>
              <Button fullWidth variant="outlined">
                REMIX
              </Button>
            </a>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

const DoodleOptions = ({
  doodle,
  user,
  justifyContent = 'center',
  spacing = 1,
}) => (
  <Stack justifyContent={justifyContent} spacing={spacing} direction="row">
    <CommentButton doodle={doodle} />
    <LikeDoodleButton doodle={doodle} />
    {doodle.signatureImageUri && <SignWithSelfieIcon doodle={doodle} />}
    <DoodleMoreOptions user={user} doodle={doodle} />
  </Stack>
);

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});

export default connect(mapStateToProps, null)(DoodleDetails);

import React, {useRef, useState} from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Container,
  useTheme,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import {Link, useLocation} from 'react-router-dom';
import {createStructuredSelector} from 'reselect';
import {makeSelectInitialAuthComplete, makeSelectUser} from '../App/selectors';
import {connect} from 'react-redux';
import UserDrawer from '../UserDrawer';
import MenuIcon from '@mui/icons-material/Menu';

const AppHeader = ({user, initialAuthComplete}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const location = useLocation();

  const noHeader = useRef(
    new URLSearchParams(location.search).get('hide_header'),
  ).current;

  const theme = useTheme();

  const isDesktop = useMediaQuery('(min-width:600px)');

  if (noHeader) return null;

  return (
    <React.Fragment>
      <Box sx={{flexGrow: 1, position: 'relative', zIndex: 9999}}>
        <AppBar position="static" elevation={0} style={{opacity: 0}}>
          <Toolbar />
        </AppBar>
        <AppBar
          elevation={0}
          style={{
            background: theme.palette.primary.main,
          }}
          position="fixed">
          <Container maxWidth="lg">
            <Toolbar style={{padding: 0, alignItems: 'center'}}>
              <Link to="/">
                <Typography variant="h3" color="#F1F1F1">
                  GETADOODLE
                </Typography>
              </Link>
              <div style={{flexGrow: 1}} />
              {initialAuthComplete && (
                <IconButton
                  onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{}}>
                  <MenuIcon style={{fontSize: isDesktop ? 38 : 32}} />
                </IconButton>
              )}
            </Toolbar>
          </Container>
        </AppBar>
      </Box>
      <UserDrawer
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
      />
    </React.Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),

  initialAuthComplete: makeSelectInitialAuthComplete(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);

import React from 'react';
import {Container} from '@mui/material';
import LoginForm from '../../containers/LoginForm';
import {useNavigate} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {APP_LOGO} from '../../constants';

const LoginScreen = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="sm">
      <Helmet>
        <title>Login</title>
        <meta
          name="description"
          content="Login into your Getadoodle account."
        />
        <meta property="og:image" content={APP_LOGO} />
      </Helmet>
      <LoginForm onToggleAuth={() => navigate('/register')} />
    </Container>
  );
};

export default LoginScreen;

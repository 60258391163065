import {useMediaQuery} from '@mui/material';
const {Link} = require('react-router-dom');
const {Carousel} = require('react-responsive-carousel');
const React = require('react');

const HomeScreenBanners = () => {
  const isDesktop = useMediaQuery('(min-width:600px)');
  return (
    <Carousel
      showStatus={false}
      showArrows={false}
      swipeable
      autoPlay
      showThumbs={false}
      interval={10000}
      infiniteLoop>
      <div className="hp-slide">
        <Link
          className="banner-link"
          style={{display: 'inline-block'}}
          to="/doodlers/martyschwartz">
          <img
            style={{borderRadius: 25}}
            alt="Tooba"
            src={
              isDesktop
                ? require('../../assets/banners/martymusicbanner1152x480.jpg')
                : require('../../assets/banners/MartyMusicIcon343x343.jpg')
            }
          />
        </Link>
      </div>

      <div className="hp-slide">
        <Link
          className="banner-link"
          style={{display: 'inline-block'}}
          to="/doodlers/tooba">
          <img
            style={{borderRadius: 25}}
            alt="Tooba"
            src={
              isDesktop
                ? require('../../assets/banners/tooba_banner.jpeg')
                : require('../../assets/banners/toooba_square.jpeg')
            }
          />
        </Link>
      </div>

      <div className="hp-slide">
        <Link
          className="banner-link"
          style={{display: 'inline-block'}}
          to="/doodlers/lehrerboys">
          <img
            style={{borderRadius: 25}}
            alt="Lehrer Boys"
            src={
              isDesktop
                ? require('../../assets/banners/lehrerboys-wide.jpg')
                : require('../../assets/banners/lehrerboys-square.jpg')
            }
          />
        </Link>
      </div>

      <div className="hp-slide">
        <Link
          className="banner-link"
          style={{display: 'inline-block'}}
          to="/doodlers/jamieseerman">
          <img
            style={{borderRadius: 25}}
            alt="Jamie Seerman"
            src={
              isDesktop
                ? require('../../assets/banners/jamie_banner-wide.jpg')
                : require('../../assets/banners/jamie_banner-square.jpg')
            }
          />
        </Link>
      </div>
    </Carousel>
  );
};

export default HomeScreenBanners;

import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import {getAnalytics} from 'firebase/analytics';
import {getFirestore, collection, getDocs} from 'firebase/firestore';

const config = {
  apiKey: 'AIzaSyAOkdqGUtREkkJMohOx-bJeJnFoavi4LvU',
  authDomain: 'getadoodle-69.firebaseapp.com',
  projectId: 'getadoodle-69',
  storageBucket: 'getadoodle-69.appspot.com',
  messagingSenderId: '429317144987',
  appId: '1:429317144987:web:b9c78a1d19f698aaa0e13e',
  measurementId: 'G-5PTMMH59SE',
};

const app = initializeApp(config);

const db = getFirestore(app);

const auth = getAuth(app);

const analytics = getAnalytics(app);

export {app, db, collection, getDocs, auth, analytics};

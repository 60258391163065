import React, {useEffect, useState} from 'react';
import {Typography} from '@mui/material';
import {getDoc, doc} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import {Link} from 'react-router-dom';

const CharityRow = ({charityId}) => {
  const [charity, setCharity] = useState(null);

  useEffect(() => {
    getDoc(doc(db, 'users', charityId)).then(result => {
      setCharity(result.data());
    });
  }, [charityId]);

  if (!charity) return null;

  return (
    <Typography variant="caption">
      All proceeds will go to charity •{' '}
      <strong>
        <Link to={`/charity/${charity.username}`}>{charity.displayName}</Link>
      </strong>{' '}
      • {charity.bio.slice(0, 90)}...{' '}
      <Link className="blue" to={`/charity/${charity.username}`}>
        Learn more
      </Link>
    </Typography>
  );
};

export default CharityRow;

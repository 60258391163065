import {
  Avatar,
  IconButton,
  LinearProgress,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import {Link} from 'react-router-dom';
import {getAvatarById, getConfirmation, formattedTimeAgo} from '../../utils';
import FallbackAvatar from '../../components/FallbackAvatar';
import {DeleteOutline} from '@mui/icons-material';
import React, {useEffect, useState} from 'react';
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import ReplyLineItem from '../ReplyLineItem';
import CommentLikeButton from '../CommentLikeButton';

const CommentLineItem = ({
  comment,
  user,
  setReplyToComment,
  commentDoodleId,
}) => {
  const [replies, setReplies] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (comment.replies) {
      setReplies(comment.replies.concat(replies));
    }
  }, [comment]);

  const handleDelete = async () => {
    try {
      await getConfirmation({
        title: 'Delete Comment',
        text: 'Are you sure you want delete this comment. This action can not be undone.',
      });

      await deleteDoc(
        doc(db, `doodles/${commentDoodleId}/comments`, comment.id),
      );
    } catch (e) {
      console.log(e);
    }
  };

  const fetchReplies = () => {
    setLoading(true);
    getDocs(
      query(
        collection(
          db,
          `doodles/${commentDoodleId}/comments/${comment.id}/replies`,
        ),
        orderBy('createdAt', 'desc'),
        limit(24),
      ),
    ).then(snapshot => {
      setReplies(snapshot.docs.map(doc => ({...doc.data(), id: doc.id})));
      setLoading(false);
    });
  };

  return (
    <React.Fragment>
      <ListItem disablePadding key={comment.id} alignItems="flex-start">
        <ListItemAvatar style={{minWidth: 42}}>
          <Link to={`/doodlers/${comment.userId}`}>
            <Avatar
              sx={{width: 32, height: 32}}
              alt="Remy Sharp"
              src={getAvatarById(comment.userId)}>
              <FallbackAvatar />
            </Avatar>
          </Link>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center">
              <Typography variant="caption" color="darkgrey">
                <strong>{comment.displayName}</strong>
              </Typography>
              <Stack direction="row">
                {user && user.id === comment.userId && (
                  <IconButton size="small" onClick={handleDelete}>
                    <DeleteOutline style={{fontSize: 16}} color="warning" />
                  </IconButton>
                )}
                <CommentLikeButton comment={comment} />
              </Stack>
            </Stack>
          }
          secondary={
            <Stack style={{marginTop: 0}}>
              <Typography
                sx={{display: 'inline'}}
                component="span"
                variant="body2"
                color="text.primary">
                {comment.text}
              </Typography>
              <Typography color="darkGrey" variant="caption">
                <Typography
                  onClick={() => setReplyToComment(comment)}
                  variant="span"
                  style={{cursor: 'pointer'}}>
                  Reply
                </Typography>{' '}
                - {formattedTimeAgo(comment)}
              </Typography>
              {Boolean(comment.replyCount) &&
                !loading &&
                replies.length === 0 && (
                  <Typography
                    style={{cursor: 'pointer'}}
                    onClick={fetchReplies}
                    color="primary"
                    variant="caption">
                    View Replies ({comment.replyCount})
                  </Typography>
                )}
            </Stack>
          }
        />
      </ListItem>
      {loading && <LinearProgress />}
      {replies.map(reply => (
        <ReplyLineItem user={user} reply={reply} key={reply.id} />
      ))}
    </React.Fragment>
  );
};

export default CommentLineItem;

import {TextField} from '@mui/material';
import React from 'react';
import IOSSwitch from '../../components/IOSSwitch';

const HideFromProfile = ({userPrivate, setUserPrivate}) => {
  return (
    <TextField
      inputProps={{
        readOnly: true,
      }}
      InputProps={{
        endAdornment: (
          <IOSSwitch
            onChange={() => {
              setUserPrivate(!userPrivate);
            }}
          />
        ),
      }}
      fullWidth
      value="Make this commission private"
    />
  );
};

export default HideFromProfile;

import React from 'react';
import {Avatar} from '@mui/material';
import FallbackAvatar from '../FallbackAvatar';

const DoodlerAvatar = props => (
  <Avatar {...props}>
    <FallbackAvatar />
  </Avatar>
);

export default DoodlerAvatar;

import React from 'react';
import {Container, LinearProgress, Box, Tabs, Tab} from '@mui/material';
import {useEffect, useState} from 'react';
import {db} from '../../clients/Firebase';
import {
  getDocs,
  query,
  collection,
  where,
  limit,
  doc,
  getDoc,
} from 'firebase/firestore';
import {createStructuredSelector} from 'reselect';
import {makeSelectUser} from '../../containers/App/selectors';
import {connect} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {getAvatarById} from '../../utils';
import mixpanel from 'mixpanel-browser';
import ProfileTabCollected from '../../containers/ProfileTabCollected';
import ProfileTabCreated from '../../containers/ProfileTabCreated';
import ProfileTabPrivate from '../../containers/ProfileTabPrivate';
import ProfileHeader from '../../containers/ProfileHeader';
import VerifiedBar from '../../components/VerifiedBar';
import theme from '../../theme';

const DoodlerScreen = ({user}) => {
  const [doodler, setDoodler] = useState(null);
  const [value, setValue] = useState('created');

  const navigate = useNavigate();
  const {doodlerId} = useParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (doodler) {
      window.prerenderReady = true;
    }
  }, [doodler]);

  useEffect(() => {
    setDoodler(null);
    loadDoodlerAsync();
  }, [doodlerId]);

  useEffect(() => {
    if (doodler) {
      if (doodler && doodler.isCharity) {
        navigate('/charity/' + doodler.username, {replace: true});
        return;
      }

      mixpanel.track('Doodler Viewed', {
        path: doodler.displayName,
      });
    }
  }, [doodler]);

  const loadDoodlerAsync = async () => {
    const querySnapshot = await getDocs(
      query(
        collection(db, 'users'),
        where('username', '==', doodlerId),
        limit(1),
      ),
    );

    if (!querySnapshot.empty) {
      setDoodler({
        ...querySnapshot.docs[0].data(),
        id: querySnapshot.docs[0].id,
      });
    } else {
      const docSnap = await getDoc(doc(db, 'users', doodlerId));

      navigate('/doodlers/' + docSnap.data().username, {replace: true});

      setDoodler({
        ...docSnap.data(),
        id: docSnap.id,
      });
    }
  };

  if (!doodler) return <LinearProgress />;

  const isUsersProfile = user && user.id === doodler.id;

  const description = `Get your very own personalized doodle from ${doodler.displayName} at getadoodle.com today!`;

  return (
    <React.Fragment>
      {doodler.verified && <VerifiedBar />}
      <Container maxWidth="lg">
        <Helmet>
          <title>{doodler.displayName}</title>
          <meta name="description" content={description} />
          <meta property="og:title" content={doodler.displayName} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={getAvatarById(doodler.id)} />
          <meta
            property="og:url"
            content={`https://getadoodle.com/doodlers/${doodler.username}`}
          />
          <meta name="twitter:title" content={doodler.displayName} />
          <meta name="twitter:site" content="getadoodle" />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content={doodler.avatar} />
          <meta name="twitter:card" content="summary" />
        </Helmet>
        <br />
        <br />
        <Container disableGutters maxWidth="sm">
          <ProfileHeader doodler={doodler} isUsersProfile={isUsersProfile} />
        </Container>
        <br />
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
          <Tabs
            TabIndicatorProps={{
              sx: {backgroundColor: theme.palette.red},
            }}
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example">
            <Tab label="Created" value="created" />
            <Tab label="Collected" value="collected" />
            {isUsersProfile && <Tab label="Private" value="private" />}
          </Tabs>
        </Box>
        <div style={{height: 6}} />
        <div style={{minHeight: 500}}>
          {value === 'created' && (
            <ProfileTabCreated isUsersProfile={isUsersProfile} user={doodler} />
          )}
          {value === 'collected' && (
            <ProfileTabCollected
              isUsersProfile={isUsersProfile}
              doodlerId={doodler.id}
            />
          )}
          {value === 'private' && <ProfileTabPrivate doodlerId={doodler.id} />}
        </div>
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});

export default connect(mapStateToProps, null)(DoodlerScreen);

import {
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
} from 'firebase/auth';
import {recordError, setIsAuthenticating} from '../App/actions';
import {Link, useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {makeSelectUser} from '../App/selectors';
import {VisibilityOffRounded, VisibilityRounded} from '@mui/icons-material';
import {LoadingButton} from '@mui/lab';
import {getErrorMessage} from '../../utils/errors';
import PageHeader from '../PageHeader';
import mixpanel from 'mixpanel-browser';

const RegisterForm = ({dispatch, user, onToggleAuth, noRedirect}) => {
  const navigate = useNavigate();

  const [isWorking, setIsWorking] = useState(false);
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (user) {
      if (noRedirect) {
        return;
      } else {
        navigate('/account/profile/edit');
      }
    }
  }, [user]);

  const handleRegisterAsync = e => {
    e.preventDefault();

    setIsWorking(true);

    const auth = getAuth();

    createUserWithEmailAndPassword(auth, email, password)
      .then(async (user, err) => {
        dispatch(setIsAuthenticating(false));
        setIsWorking(false);
        const auth = getAuth();
        await sendEmailVerification(auth.currentUser);
        mixpanel.track('Registered', {});
      })
      .catch(err => {
        setIsWorking(false);
        setError(getErrorMessage(err));
        dispatch(recordError(err, 'auth_error'));
      });
  };

  return (
    <form onSubmit={handleRegisterAsync}>
      <div>
        <PageHeader>Create Account</PageHeader>
        <Stack spacing={2}>
          {error && <Typography style={{color: 'red'}}>{error}</Typography>}
          <TextField
            required
            label="Email"
            type="email"
            onChange={e => setEmail(e.target.value)}
          />
          <TextField
            required
            label="Password"
            type={showPassword ? 'text' : 'password'}
            onChange={e => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}>
                    {!showPassword ? (
                      <VisibilityOffRounded />
                    ) : (
                      <VisibilityRounded />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormControlLabel
            checked={agreeTerms}
            onClick={() => {
              setAgreeTerms(!agreeTerms);
            }}
            control={<Radio />}
            label={
              <Typography variant="text">
                Agree to{' '}
                <Link to="/terms-of-service" className="clickable">
                  Terms of Service
                </Link>{' '}
                and{' '}
                <Link to="/privacy" className="clickable">
                  Privacy Policy
                </Link>
              </Typography>
            }
          />
          <Typography variant="text">
            Already have an account?{' '}
            <span
              onClick={() => {
                if (onToggleAuth && typeof onToggleAuth === 'function') {
                  onToggleAuth();
                  return;
                }
                navigate('/login');
              }}
              className="clickable">
              Login
            </span>
            .
          </Typography>
          <LoadingButton
            loading={isWorking}
            size="large"
            disabled={!email || !password || !agreeTerms}
            fullWidth
            type="submit"
            variant="contained">
            GET TO IT!
          </LoadingButton>
        </Stack>
      </div>
    </form>
  );
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);

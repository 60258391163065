import {SET_SHOW_DRAWER, SET_COMMENT_DOODLE_ID} from './constants';

export const setShowDrawer = isDrawerShowing => ({
  type: SET_SHOW_DRAWER,
  isDrawerShowing,
});

export const setCommentDoodleId = commentDoodleId => ({
  type: SET_COMMENT_DOODLE_ID,
  commentDoodleId,
});

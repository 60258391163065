import React from 'react';
import {Container} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import RegisterForm from '../../containers/RegisterForm';
import {Helmet} from 'react-helmet';
import {APP_LOGO} from '../../constants';

const RegisterScreen = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="sm">
      <Helmet>
        <title>Register</title>
        <meta name="description" content="Register your Getadoodle account." />
        <meta property="og:image" content={APP_LOGO} />
      </Helmet>
      <RegisterForm onToggleAuth={() => navigate('/login')} />
    </Container>
  );
};

export default RegisterScreen;

import React, {useEffect, useRef, useState} from 'react';
import {
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import moment from 'moment';
import SocialRow from '../SocialRow';
import FooterLinks from '../FooterLinks';
import {APP_LINK} from '../../constants';
import {useLocation} from 'react-router-dom';
import EnterContestFooterBar from '../../components/EnterContestFooterBar';

const AppFooter = () => {
  const [showContestBar, setShowContestBar] = useState(false);

  const location = useLocation();

  const isMobile = useMediaQuery('(max-width:600px)');

  const noHeader = useRef(
    new URLSearchParams(location.search).get('hide_header'),
  ).current;

  useEffect(() => {
    if (location.pathname.includes('contests')) {
      setShowContestBar(true);
    } else if (showContestBar) {
      setShowContestBar(false);
    }
  }, [location]);

  if (noHeader) return null;

  return (
    <React.Fragment>
      <br />
      <div className="footer-wrapper">
        <Container style={{paddingTop: 60}} maxWidth="lg">
          <Container
            disableGutters
            maxWidth="lg"
            style={{
              paddingBottom: 35,
              paddingLeft: 0,
              textAlign: 'left',
            }}>
            <Stack spacing={4}>
              <FooterLinks />
              <Grid
                container
                style={{flexDirection: isMobile ? 'column-reverse' : 'row'}}>
                <Grid item xs={12} sm={9}>
                  {isMobile && <br />}
                  <Typography variant="caption" style={{color: '#6b6b6b'}}>
                    © {moment().year()} Getadoodle Inc. All rights reserved.
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
          </Container>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AppFooter;

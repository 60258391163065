import React from 'react';
const Center = ({children, style = {}, height = {}, className}) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      textAlign: 'center',
      ...style,
      height: height || style.height || 'auto',
    }}
    className={className}>
    {children}
  </div>
);

export default Center;

import React, {useEffect, useState} from 'react';
import {Container} from '@mui/material';
import PageHeader from '../../containers/PageHeader';
import {useParams} from 'react-router-dom';
import {collection, getDocs, limit, query, where} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import DoodleGrid from '../../containers/DoodleGrid';
import {Helmet} from 'react-helmet';

const DoodleTagScreen = () => {
  const {tag} = useParams();
  const [doodles, setDoodles] = useState([]);

  useEffect(() => {
    getDocs(
      query(
        collection(db, 'doodles'),
        where('tags', 'array-contains', tag),
        where('deleted', '==', false),
        where('public', '==', true),
        limit(20),
      ),
    ).then(snap => {
      setDoodles(snap.docs.map(doc => ({id: doc.id, ...doc.data()})));
    });
  }, [tag]);

  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>#{tag}</title>
      </Helmet>
      <PageHeader>#{tag}</PageHeader>
      <DoodleGrid doodles={doodles} />
    </Container>
  );
};

export default DoodleTagScreen;

const Heart_Icon = require('../../assets/icons/like.png');
const Grey_Heart_Icon = require('../../assets/icons/heart-gray.png');
const React = require('react');

const NotLikedIcon = ({style = {}, grey = false}) => (
  <img
    style={style}
    alt="Like Button"
    src={grey ? Grey_Heart_Icon : Heart_Icon}
  />
);

export default NotLikedIcon;

import React, {useEffect, useState} from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  ButtonGroup,
  DialogTitle,
  Stack,
} from '@mui/material';
import {collection, getDocs} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import Center from '../../containers/Center';

const SelectProductDialog = ({
  open,
  product,
  onClose,
  onVariantSelected,
  addressRequired,
}) => {
  const [variants, setVariants] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState(null);

  useEffect(() => {
    if (product) {
      getDocs(
        collection(
          db,
          `doodles/${product.data().doodle}/products/${product.id}/options`,
        ),
      ).then(snap => {
        const vs = snap.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          retail_price: parseInt(doc.data().retail_price) * 100,
        }));

        setSelectedVariant(vs[0]);
        setVariants(vs);
      });
    }
  }, [product]);

  if (!product) return null;

  const handleClose = () => {
    onClose();
    setSelectedVariant(null);
    setVariants([]);
  };

  console.log(variants);
  return (
    <Dialog maxWidth="sm" onClose={handleClose} open={open}>
      {Boolean(product) && Boolean(selectedVariant) && (
        <React.Fragment>
          <DialogTitle>{product.data().title}</DialogTitle>
          <DialogContent>
            <Stack justifyContent="center" alignItems="center">
              <img src={selectedVariant.thumbnail} />
              <ButtonGroup
                variant="outlined"
                aria-label="outlined button group">
                {variants.map(variant => (
                  <Button
                    onClick={() => {
                      handleClose();
                      onVariantSelected(variant);
                    }}
                    key={variant.id}>
                    {variant.title.split(' - ').pop()}
                  </Button>
                ))}
              </ButtonGroup>
            </Stack>
          </DialogContent>
        </React.Fragment>
      )}
    </Dialog>
  );
};

export default SelectProductDialog;

import Swal from 'sweetalert2';
import timeAgo from './TimeAgo';
import moment from 'moment';
const getSweetInput = ({
  title,
  text,
  inputPlaceholder,
  input = 'text',
  defaultValue,
}) => {
  return Swal.fire({
    title,
    text,
    input,
    inputValue: defaultValue,
    showCancelButton: true,
    closeOnConfirm: false,
    animation: 'slide-from-top',
    inputPlaceholder,
  });
};

export const toColor = (num: number, rgba: boolean = true) => {
  num >>>= 0;
  const b = num & 0xff;
  const g = (num & 0xff00) >>> 8;
  const r = (num & 0xff0000) >>> 16;
  const a = ((num & 0xff000000) >>> 24) / 255;
  if (rgba) {
    return `rgba(${[r, g, b, a].join(',')})`;
  }
  return `#${[r, g, b]
    .map(x => x.toString(16).padStart(2, '0'))
    .join('')
    .toUpperCase()}`;
};

const onReceipt = (web3, hash) =>
  new Promise((resolve, reject) => {
    const interval = setInterval(function () {
      web3.eth.getTransactionReceipt(hash, function (err, rec) {
        if (rec) {
          resolve(rec);
          clearInterval(interval);
        } else if (err) {
          reject(err);
        }
      });
    }, 1000);
  });

const getAvatarById = id =>
  `https://firebasestorage.googleapis.com:443/v0/b/getadoodle-69.appspot.com/o/users%2F${id}%2Favatar.png?alt=media`;

const getDoodleUrlById = id =>
  `https://firebasestorage.googleapis.com:443/v0/b/getadoodle-69.appspot.com/o/doodles%2F${id}.png?alt=media`;

const getProductImageById = (id, type) =>
  `https://firebasestorage.googleapis.com/v0/b/getadoodle-69.appspot.com/o/products%2F${type}%2F${id}.png?alt=media`;

const getConfirmation = ({title, text}) => {
  return new Promise((resolve, reject) => {
    Swal.fire({
      title,
      text,
      showCancelButton: true,
      confirmButtonColor: '#1D1D1D',
      cancelButtonColor: '#FF5D4F',
      confirmButtonText: 'Yes, do it!',
    }).then(result => {
      if (result.value) {
        resolve(true);
      } else {
        reject(new Error('User no wanty'));
      }
    });
  });
};

const toCapitalCase = text =>
  text.length > 0 ? text[0].toUpperCase() + text.substring(1) : '';

const hashCode = (id1, id2) => {
  const string = id1 + id2;

  let hash = 0,
    i,
    chr;
  if (string.length === 0) return hash;
  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return hash >>> 0;
};

const isValidEmail = email => {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return pattern.test(email);
};

const getScale = product => {
  if (product.type === 'shirt') return 1;
  if (product.title.includes('Framed')) return 1.52;
  return 1.68;
};

function formattedTimeAgo(doc) {
  return timeAgo.format(
    moment.utc().toDate() -
      moment
        .utc()
        .diff(
          moment.utc(
            doc?.createdAt?.toDate ? doc?.createdAt?.toDate() : moment(),
          ),
          'milliseconds',
        ),
    'mini',
  );
}

export {
  getSweetInput,
  getAvatarById,
  getDoodleUrlById,
  getConfirmation,
  toCapitalCase,
  onReceipt,
  hashCode,
  isValidEmail,
  getScale,
  formattedTimeAgo,
  getProductImageById,
};

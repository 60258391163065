import React from 'react';
import {Container, LinearProgress} from '@mui/material';
import {useEffect, useState} from 'react';
import {db} from '../../clients/Firebase';
import {
  getDocs,
  query,
  collection,
  where,
  limit,
  doc,
  getDoc,
} from 'firebase/firestore';
import {useNavigate, useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import DoodlerFollowersList from '../../containers/DoodlerFollowersList';
import DoodlerFollowingList from '../../containers/DoodlerFollowingList';

const DoodlerFollowScreen = () => {
  const [doodler, setDoodler] = useState(null);

  const navigate = useNavigate();
  const {doodlerId, followType} = useParams();

  useEffect(() => {
    setDoodler(null);
    loadDoodlerAsync();
  }, [doodlerId]);

  const loadDoodlerAsync = async () => {
    const querySnapshot = await getDocs(
      query(
        collection(db, 'users'),
        where('username', '==', doodlerId),
        limit(1),
      ),
    );

    if (!querySnapshot.empty) {
      setDoodler({
        ...querySnapshot.docs[0].data(),
        id: querySnapshot.docs[0].id,
      });
    } else {
      const docSnap = await getDoc(doc(db, 'users', doodlerId));

      navigate(docSnap.data().username, {replace: true});

      setDoodler({
        ...docSnap.data(),
        id: docSnap.id,
      });
    }
  };

  if (!doodler) return <LinearProgress />;

  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>{doodler.displayName} Followers</title>
      </Helmet>
      {followType === 'followers' && <DoodlerFollowersList doodler={doodler} />}
      {followType === 'following' && <DoodlerFollowingList doodler={doodler} />}
    </Container>
  );
};

export default DoodlerFollowScreen;

import {Stack} from '@mui/material';
import {Link} from 'react-router-dom';
import React from 'react';

const FooterLinks = () => (
  <strong>
    <Stack flexWrap="wrap" direction="row">
      <Link className="foot-link" to="/about">
        About
      </Link>
      <Link className="foot-link" to="/faqs">
        FAQ
      </Link>
      <Link className="foot-link" to="/contact-us">
        Contact
      </Link>
      <Link className="foot-link" to="/privacy">
        Privacy Policy
      </Link>
      <Link className="foot-link" to="/terms-of-service">
        Terms of Service
      </Link>
      <a
        className="foot-link"
        href="https://instagram.com/getadoodle"
        target="_blank">
        Instagram
      </a>
      <a
        className="foot-link"
        href="https://tiktok.com/@getadoodle"
        target="_blank">
        TikTok
      </a>
      <a
        className="foot-link"
        href="https://twitter.com/getadoodle"
        target="_blank">
        Twitter
      </a>
      <a
        className="foot-link"
        target="_blank"
        href="https://medium.com/@getadoodle">
        Blog
      </a>
    </Stack>
  </strong>
);

export default FooterLinks;

import React, {useEffect, useState} from 'react';
import {Avatar, Container, Divider, Stack, Typography} from '@mui/material';
import {collection, getDocs, orderBy, query, where} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import {Link} from 'react-router-dom';
import FallbackAvatar from '../../components/FallbackAvatar';
import VerifiedBadge from '../../components/VerifiedBadge';

const NewDoods = () => {
  const [doods, setDoods] = useState([]);
  useEffect(() => {
    getDocs(
      query(
        collection(db, 'users'),
        where('activated', '==', true),
        orderBy('createdAt', 'desc'),
      ),
    ).then(querySnapshot => {
      const data = querySnapshot.docs.map(doc => doc.data());
      setDoods(data);
    });
  }, []);

  return (
    <Container maxWidth="sm">
      {doods.map((hit, index) => (
        <div key={hit.id}>
          <Link
            to={`/${hit.isCharity ? 'charity' : 'doodlers'}/${
              hit.username || hit.id
            }`}>
            <Stack alignItems="center" spacing={2} direction="row">
              <div>
                <Avatar sx={{width: 69, height: 69}} src={hit.avatar}>
                  <FallbackAvatar />
                </Avatar>
              </div>
              <div>
                <div
                  style={{
                    marginBottom: -6,
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  {hit.displayName}{' '}
                  {hit.verified && <VerifiedBadge width={18} />}
                </div>
                <Typography color="textSecondary">
                  {hit.mainCategory}
                </Typography>
              </div>
            </Stack>
          </Link>
          {index < doods.length - 1 && (
            <Divider style={{marginBottom: -12, paddingBottom: 20}} />
          )}
        </div>
      ))}
    </Container>
  );
};

export default NewDoods;

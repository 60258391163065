import React from 'react';
import {IconButton} from '@mui/material';

const SRC = require('../../assets/icons/sign-with-selfie.png');

const SignWithSelfieIcon = ({doodle}) => {
  return (
    <IconButton onClick={() => window.open(doodle.signatureImageUri)}>
      <img alt="Sign with Selfie" style={{width: 28, height: 28}} src={SRC} />
    </IconButton>
  );
};

export default SignWithSelfieIcon;

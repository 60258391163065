import React from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Center from '../Center';

const StepperIcon = ({icon}) => {
  return (
    <div>
      <Center
        style={{
          borderColor: 'black',
          marginTop: -5,
          width: 50,
          borderRadius: '50%',
          border: 'thin solid #c2c2c2',
        }}
        height={50}>
        <Typography>{icon}</Typography>
      </Center>
    </div>
  );
};

const steps = [
  {
    title: 'Find Your Artist',
    body: 'Find your favorite person to create a new social media avatar, funny post for your feed or artwork for your new office.',
  },
  {
    title: 'Submit Commission',
    body: 'Leave instructions or share an image to have your personalized Getadoodle look just right. Artists have up to 7 days to complete it.',
  },
  {
    title: 'Enjoy Your Getadoodle',
    body: 'Surprise your Aunt with a Getadoodle of her precious Maltese drawn by her favorite rapper. Let us know what you do with your Getadoodle!',
  },
];

const HowItWorksRow = () => {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <div>
      <div style={{textAlign: 'left'}}>
        <Stepper
          connector={
            <div
              style={{
                width: 'calc(100% - 50px)',
                backgroundColor: '#c2c2c2',
                height: 1,
                top: 20,
                position: 'absolute',
                left: 'calc(-100% + 58px)',
                display: isMobile ? 'none' : 'block',
              }}
            />
          }
          orientation={isMobile ? 'vertical' : 'horizontal'}
          nonLinear
          activeStep={3}
          alternativeLabel>
          {steps.map(step => (
            <Step key={step.title}>
              <StepLabel
                style={{
                  alignItems: 'flex-start',
                  paddingTop: isMobile ? 32 : 0,
                }}
                StepIconComponent={StepperIcon}>
                <Typography
                  style={{textAlign: 'left'}}
                  color="primary"
                  variant="h5">
                  {step.title}
                </Typography>
              </StepLabel>
              <Typography variant="subtitle1">{step.body}</Typography>
            </Step>
          ))}
        </Stepper>
      </div>
    </div>
  );
};

export default HowItWorksRow;

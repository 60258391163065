import React from 'react';
import {Typography} from '@mui/material';
import Pluralize from 'react-pluralize';
import {connect} from 'react-redux';
import {
  setCommentDoodleId,
  setShowDrawer,
} from '../../containers/AppDrawer/actions';

const DoodleCommentCount = ({doodle, dispatch}) => (
  <Typography
    className="clickable"
    style={{textDecoration: 'none'}}
    onClick={() => {
      dispatch(setCommentDoodleId(doodle.id));
      dispatch(setShowDrawer(true));
    }}
    color="darkGrey">
    {doodle.commentCount > 0 ? (
      <Typography>
        View <Pluralize singular={'comment'} count={doodle.commentCount} />
      </Typography>
    ) : (
      'Be the first to comment'
    )}
  </Typography>
);

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(null, mapDispatchToProps)(DoodleCommentCount);

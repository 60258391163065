import React, {useEffect, useRef} from 'react';
import {
  Avatar,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import moment from 'moment';
import {getDoodleUrlById} from '../../utils';

const AccountPrintOrderScreen = ({order}) => {
  const fulfilled = useRef(false);
  const declined = useRef(false);
  const pending = useRef(false);

  useEffect(() => {
    fulfilled.current = order.status === 'fulfilled';
    declined.current = order.status === 'declined';
    pending.current = order.status === 'pending';
  }, [order]);

  return (
    <div>
      <Stack spacing={2}>
        <Stack direction="row" spacing={0} alignItems="center">
          <ListItemAvatar>
            <Avatar src={getDoodleUrlById(order.doodle)} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography style={{textTransform: 'uppercase'}}>
                <strong>{order.title}</strong>
              </Typography>
            }
            secondary={moment(order.createdAt.toDate()).format('ll')}
          />
        </Stack>
        <ListItemText
          primary={
            <Typography>
              <strong>ORDER#</strong> {order.orderNumber}
            </Typography>
          }
        />
        <ListItemText
          primary={
            <Typography style={{whiteSpace: 'nowrap'}}>
              <strong>STATUS</strong>{' '}
              <Typography component="span" className={`status-${order.status}`}>
                <strong>{(order.status || '').toUpperCase()}</strong>
              </Typography>
            </Typography>
          }
        />
        <ListItemText
          primary={
            <Typography>
              <strong>ORDER TOTAL</strong> ${order.amount / 100}
            </Typography>
          }
        />

        <div>
          <br />
          <Typography variant="h5" paragraph>
            <strong>Shipping Address</strong>
          </Typography>
          <Stack spacing={2}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center">
              <strong>Name</strong>{' '}
              <Typography>{order.recipient.name}</Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center">
              <strong>Address</strong>{' '}
              <Typography>{order.recipient.address1}</Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center">
              <strong>City</strong>{' '}
              <Typography>{order.recipient.city}</Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center">
              <strong>State</strong>{' '}
              <Typography>{order.recipient.state_name}</Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center">
              <strong>Country</strong>{' '}
              <Typography>{order.recipient.country_name}</Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center">
              <strong>Zip</strong>{' '}
              <Typography>{order.recipient.zip}</Typography>
            </Stack>
          </Stack>
        </div>

        {order.shipment && (
          <div>
            <br />
            <Typography variant="h5" paragraph>
              <strong>Shipment Info</strong>
            </Typography>
            <Stack spacing={2}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center">
                <strong>Carrier</strong>{' '}
                <Typography>{order.shipment.carrier}</Typography>
              </Stack>

              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center">
                <strong>Service</strong>{' '}
                <Typography>{order.shipment.service}</Typography>
              </Stack>

              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center">
                <strong>Ship Date</strong>{' '}
                <Typography>{order.shipment.ship_date}</Typography>
              </Stack>

              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center">
                <strong>Tracking #</strong>{' '}
                <Typography>
                  <a
                    className="clickable"
                    href={order.shipment.tracking_url}
                    target="_blank">
                    {order.shipment.tracking_number}
                  </a>
                </Typography>
              </Stack>
            </Stack>
          </div>
        )}
      </Stack>
    </div>
  );
};

export default AccountPrintOrderScreen;

import {Link} from 'react-router-dom';
import {Avatar, Stack, Typography} from '@mui/material';
import {getAvatarById} from '../../utils';
import FallbackAvatar from '../../components/FallbackAvatar';
import React from 'react';

const DoodlerCard = ({user}) => (
  <div style={{textAlign: 'center'}} className="card">
    <Link to={`/${user.isCharity ? 'charity' : 'doodlers'}/${user.username}`}>
      <div className="doodle-circle">
        <Avatar
          style={{width: '100%', height: 'auto', margin: '0 auto'}}
          className="doodle-circle"
          src={getAvatarById(user.id)}>
          <FallbackAvatar />
        </Avatar>
      </div>
    </Link>
    <Stack style={{marginTop: 10, whiteSpace: 'nowrap'}}>
      <Link to={`/doodlers/${user.username}`}>
        <Typography
          variant="h5"
          style={{
            overflow: 'hidden',
            width: '100%',
            textOverflow: 'ellipsis',
          }}>
          {user.displayName || 'NO NAME'}
        </Typography>
      </Link>
      <Typography style={{textTransform: 'capitalize', fontSize: 18}}>
        {user.mainCategory}
      </Typography>
    </Stack>
  </div>
);

export default DoodlerCard;

import React, {useEffect, useState} from 'react';
import FunctionClient from '../../clients/Functions';
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';

const ShippingMethod = ({products, onShippingMethodSelected, address}) => {
  const [shippingMethod, setShippingMethod] = useState(null);
  const [shippingMethods, setShippingMethods] = useState([]);
  const [loadingMethods, setLoadingMethods] = useState(false);

  useEffect(() => {
    if (address) {
      updateShippingAsync();
    }
  }, [address, products]);

  useEffect(() => {
    if (shippingMethod) {
      onShippingMethodSelected({
        ...shippingMethod,
        rate: parseFloat(shippingMethod.rate) * 100,
      });
    }
  }, [shippingMethod]);

  const updateShippingAsync = async () => {
    try {
      setLoadingMethods(true);

      const res = await FunctionClient.fetchShippingAsync({
        recipient: {
          country_code: address.country,
          state_code: address.state,
          city: address.city,
          zip: address.zip,
        },
        items: products.map(p => ({variant_id: p.variant_id, quantity: 1})),
      });

      setLoadingMethods(false);

      setShippingMethod(res[0]);

      setShippingMethods(res);
    } catch (err) {
      setLoadingMethods(false);
      alert(err.message);
    }
  };

  return (
    <div>
      {loadingMethods && <CircularProgress />}
      {shippingMethods.length > 0 && (
        <Stack spacing={2}>
          <Typography variant="h5">Shipping Methods</Typography>
          <FormControl>
            {shippingMethods.map(method => (
              <FormControlLabel
                key={method.id}
                control={
                  <Checkbox
                    onChange={e => {
                      if (e.target.checked) setShippingMethod(method);
                    }}
                    checked={shippingMethod.id === method.id}
                  />
                }
                label={
                  <Typography variant="caption">
                    ${method.rate} {method.name}
                  </Typography>
                }
              />
            ))}
          </FormControl>
        </Stack>
      )}
    </div>
  );
};

export default ShippingMethod;

import React, {useEffect, useRef, useState} from 'react';
import {Button, Container, Stack, Typography} from '@mui/material';
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  startAt,
  where,
} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import DoodleGrid from '../DoodleGrid';
import {Link} from 'react-router-dom';

const LIMIT = 20;

const ProfileTabPrivate = ({doodlerId}) => {
  const [doodles, setDoodles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginate, setPaginate] = useState(null);

  useEffect(() => {
    loadDoodles();
  }, []);

  const loadDoodles = async () => {
    setLoading(true);

    const doodlesSnap = await getDocs(
      query(
        collection(db, 'doodles'),
        where('doodler', '==', doodlerId),
        where('public', '==', false),
        where('deleted', '==', false),
        orderBy('createdAt', 'desc'),
        paginate ? startAfter(paginate) : startAt('createdAt'),
        limit(LIMIT),
      ),
    );

    setDoodles(
      doodles.concat(
        doodlesSnap.docs.map(snap => ({
          id: snap.id,
          ...snap.data(),
        })),
      ),
    );

    setLoading(false);

    if (doodlesSnap.size === LIMIT) {
      setPaginate(doodlesSnap.docs.pop());
    } else {
      setPaginate(null);
    }
  };

  if (!loading && doodles.length === 0)
    return (
      <Container maxWidth="sm" disableGutters>
        <Stack spacing={2}>
          <Typography color="darkGrey">
            Getadoodles you want hidden from the public will show here. To start
            your collection{' '}
            <Link className="blue-links blue" to="/">
              browse our artists
            </Link>{' '}
            and commission a Getadoodle today!
          </Typography>
        </Stack>
      </Container>
    );

  return (
    <div>
      <DoodleGrid doodles={doodles} />
      {paginate && (
        <Container maxWidth="sm">
          <br />
          <Button onClick={loadDoodles} fullWidth variant="outlined">
            LOAD MORE
          </Button>
        </Container>
      )}
    </div>
  );
};

export default ProfileTabPrivate;

import {
  AppBar,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, {useState} from 'react';
import {CloseOutlined} from '@mui/icons-material';

const ContestQuestionsDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');
  const theme = useTheme();
  return (
    <React.Fragment>
      <IconButton onClick={() => setIsOpen(true)}>
        <img
          style={{width: 46, height: 46}}
          src={require('../../assets/icons/contest-questioncircle.png')}
          alt="contest icon"
        />
      </IconButton>
      <Dialog
        maxWidth="sm"
        fullWidth
        fullScreen={isMobile}
        open={isOpen}
        onClose={() => setIsOpen(false)}>
        <br />
        <AppBar
          position="static"
          style={{
            backgroundColor: theme.palette.primary.main,
            paddingTop: 12,
            paddingBottom: 12,
            marginTop: -1,
          }}>
          <Stack
            paddingLeft={3}
            paddingRight={3}
            direction="row"
            alignItems="center"
            justifyContent="space-between">
            <Typography color="white" variant="h3" textAlign="center">
              CONTESTS
            </Typography>
            <div>
              <IconButton onClick={() => setIsOpen(false)}>
                <CloseOutlined style={{color: 'white'}} />
              </IconButton>
            </div>
          </Stack>
        </AppBar>
        <DialogContent>
          <Typography variant="h3">How they Work</Typography>
          <ul>
            <li>Each contest is 7 days long</li>
            <li>The entry with the most hearts wins</li>
          </ul>
          <Typography variant="h3">How to Heart</Typography>
          <ul>
            <li>
              Tap the{' '}
              <img
                style={{width: 22, marginBottom: -3}}
                src={require('../../assets/icons/like.png')}
              />{' '}
              icon to heart an entry
            </li>
          </ul>
          <Typography variant="h3">How to Enter</Typography>
          <ul>
            <li>
              From the Getadoodle app homepage, tap “Enter To Win” to create an
              entry
            </li>
          </ul>
          <Typography paragraph>
            Feedback? Email{' '}
            <a href="mailto:contests@getadoodle.com">contests@getadoodle.com</a>
            .
          </Typography>
          <Typography variant="body2" color="darkGrey">
            Contest Rules 1) Getadoodle contests are open to users who are at
            least 18 years old and reside in the United States. 2) Users can
            enter contests by submitting a Getadoodle Entry (entry) on the
            Getadoodle app. There is no limit to the number of entries
            allowable. 3) Entries must be original, created by the user
            submitting, and cannot include any copyrighted material or infringe
            on any intellectual property rights. 4) Users can heart their
            favorite entry on the app by clicking the entries’ heart icon. 5)
            The entry with the most hearts at the end of the contest period will
            be declared the winner. 6) The winner will receive a prize, to be
            determined by the sponsor of the contest. 7) Getadoodle reserves the
            right to disqualify any entry or voter that it believes to be in
            violation of these rules or in violation of any laws or regulations.
            8) Getadoodle is not responsible for any damages resulting from
            participation in the contest or acceptance of any prize. 9) By
            entering the contest, the user agrees to be bound by these rules and
            the decisions of Getadoodle 10) The contest period, prize and method
            of verification will be announced by Getadoodle. 11) Getadoodle
            reserves the right to make any necessary change to the rules and
            terms of the contest.
          </Typography>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default ContestQuestionsDialog;

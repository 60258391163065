import React, {useEffect, useState} from 'react';
import {
  Typography,
  Container,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  FormGroup,
  FormControl,
  useTheme,
} from '@mui/material';
import {doc, getDoc, setDoc} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import StarRatings from 'react-star-ratings';
import PageHeader from '../../containers/PageHeader';
import {LoadingButton} from '@mui/lab';
import {createStructuredSelector} from 'reselect';
import {makeSelectUser} from '../../containers/App/selectors';
import {connect} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

const ReviewDoodleScreen = ({user}) => {
  const {orderId} = useParams();

  const [order, setOrder] = useState(null);
  const [rating, setRating] = useState(5);
  const [review, setReview] = useState('');
  const [loading, setLoading] = useState(false);
  const [anon, setAnon] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchOrderAsync();

    fetchReviewAsync();
  }, []);

  const fetchOrderAsync = async () => {
    const docRef = doc(db, 'doodleRequests', orderId);

    const docSnap = await getDoc(docRef);

    setOrder({
      ...docSnap.data(),
      id: docSnap.id,
    });
  };

  const fetchReviewAsync = async () => {
    const docRef = doc(db, 'reviews', orderId);

    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setReview(docSnap.data().review);
      setRating(docSnap.data().rating);
      setAnon(docSnap.data().anon);
    }
  };

  const handleSubmitReview = async () => {
    if (review.length <= 0) alert('please complete form');

    try {
      setLoading(true);

      await setDoc(doc(db, 'reviews', orderId), {
        rating,
        review,
        order: orderId,
        doodler: order.doodler,
        anon,
        name: anon ? `*****${user.displayName.slice(-1)}` : user.displayName,
      });

      setLoading(false);

      window.confirm('Review Submitted Successfully');

      navigate(-1);
    } catch (err) {
      setLoading(false);

      alert(err.message);
    }
  };

  const theme = useTheme();

  return (
    <Container maxWidth="sm">
      <PageHeader>Leave a Review</PageHeader>
      <Stack spacing={2}>
        <Stack direction="row">
          <StarRatings
            starHoverColor={theme.palette.yellow}
            starEmptyColor={theme.palette.lightGrey}
            starRatedColor={theme.palette.yellow}
            rating={rating}
            changeRating={nr => setRating(nr)}
            numberOfStars={5}
            name="rating"
          />
        </Stack>

        <TextField
          required
          label="My review"
          value={review}
          onChange={e => setReview(e.target.value)}
          multiline
        />

        <FormControl fullWidth>
          <FormGroup aria-label="position" row>
            <FormControlLabel
              style={{
                marginLeft: 0,
                width: '100%',
                justifyContent: 'space-between',
              }}
              onChange={() => setAnon(!anon)}
              value={anon}
              control={<Switch color="primary" />}
              label={<Typography variant="text">Submit anonymously</Typography>}
              labelPlacement="start"
            />
          </FormGroup>
        </FormControl>

        <LoadingButton
          variant={'contained'}
          size={'large'}
          loading={loading}
          onClick={handleSubmitReview}>
          SUBMIT
        </LoadingButton>
      </Stack>
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});

export default connect(mapStateToProps, null)(ReviewDoodleScreen);

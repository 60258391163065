import {
  Stack,
  TextField,
  Typography,
  useTheme,
  IconButton,
  InputAdornment,
} from '@mui/material';
import React, {useState} from 'react';
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth';
import {recordError, setIsAuthenticating} from '../App/actions';
import {useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';
import {VisibilityOffRounded, VisibilityRounded} from '@mui/icons-material';
import {LoadingButton} from '@mui/lab';
import {getErrorMessage} from '../../utils/errors';
import PageHeader from '../PageHeader';

const LoginForm = ({dispatch, onToggleAuth}) => {
  const [isWorking, setIsWorking] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleLoginAsync = e => {
    e.preventDefault();
    setError(false);
    setIsWorking(true);
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then((user, err) => {
        setIsWorking(false);
        if (!err) dispatch(setIsAuthenticating(false));
      })
      .catch(err => {
        setIsWorking(false);
        setError(getErrorMessage(err));
        dispatch(recordError(err), 'auth_error');
      });
  };

  const theme = useTheme();

  return (
    <form onSubmit={handleLoginAsync}>
      <div>
        <PageHeader>Welcome Back</PageHeader>
        <Stack spacing={2}>
          {error && (
            <Typography variant="text" style={{color: theme.palette.red}}>
              {error}
            </Typography>
          )}
          <TextField
            required
            label="Email"
            type="email"
            onChange={e => setEmail(e.target.value)}
          />
          <TextField
            required
            label="Password"
            type={showPassword ? 'text' : 'password'}
            onChange={e => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    //onMouseDown={handleMouseDownPassword}
                  >
                    {!showPassword ? (
                      <VisibilityOffRounded />
                    ) : (
                      <VisibilityRounded />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Typography variant="text">
            <span
              onClick={() => {
                dispatch(setIsAuthenticating(false));
                navigate('/forgot');
              }}
              className="clickable">
              Forgot your password?
            </span>
          </Typography>
          <Typography variant="text">
            Don't have an account?{' '}
            <span
              onClick={() => {
                if (onToggleAuth && typeof onToggleAuth === 'function') {
                  onToggleAuth();
                  return;
                }
                navigate('/register');
              }}
              className="clickable">
              Create account
            </span>
            .
          </Typography>
        </Stack>
        <br />
        <LoadingButton
          size="large"
          disabled={!email || !password}
          fullWidth
          type="submit"
          loadingPosition="start"
          loading={isWorking}
          variant="contained">
          LOGIN
        </LoadingButton>
      </div>
    </form>
  );
};

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(null, mapDispatchToProps)(LoginForm);

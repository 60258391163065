const getErrorMessage = err => {
  console.log(err.message);
  switch (err.message) {
    case 'Firebase: Error (auth/email-already-in-use).':
      return 'Email already registered';
    case 'Firebase: Error (auth/user-not-found).':
      return 'Email not associated with any accounts';
    case 'Firebase: Error (auth/wrong-password).':
      return 'Invalid password';
    case 'Firebase: Password should be at least 6 characters (auth/weak-password).':
      return 'Password should be at least 6 characters';
    default:
      return 'Something broke';
  }
};

export {getErrorMessage};

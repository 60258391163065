import React, {useEffect, useState} from 'react';
import {
  collection,
  getDocs,
  where,
  orderBy,
  startAfter,
  limit,
  query,
} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import {Grid, Stack, Typography, Box} from '@mui/material';
import DoodleItem from '../../containers/DoodleItem';

const ContestHonorableMentions = ({contest, winningDoodle}) => {
  const [doodles, setDoodles] = useState([]);

  useEffect(() => {
    if (winningDoodle) {
      getDocs(
        query(
          collection(db, 'doodles'),
          where('contest', '==', contest.id),
          where('public', '==', true),
          where('deleted', '==', false),
          orderBy('likes', 'desc'),
          startAfter(winningDoodle.likes),
          limit(3),
        ),
      ).then(querySnapshot => {
        const docs = [];
        querySnapshot.forEach(doc => {
          docs.push({...doc.data(), id: doc.id});
        });
        setDoodles(docs);
      });
    }
  }, [winningDoodle]);

  return (
    <Stack spacing={2}>
      <Typography variant="h3">Honorable Mention</Typography>
      <Box sx={{flexGrow: 1}}>
        <Grid spacing={2} container>
          {doodles.map(doodle => (
            <Grid item xs={4} key={doodle.id}>
              <DoodleItem doodle={doodle} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Stack>
  );
};

export default ContestHonorableMentions;

import React, {useEffect, useRef, useState} from 'react';
import {
  Avatar,
  LinearProgress,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import {doc, getDoc} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import moment from 'moment';
import {getAvatarById, getConfirmation} from '../../utils';
import {Link, Navigate} from 'react-router-dom';
import DoodleOrderCard from '../../containers/DoodleOrderCard';
import {LoadingButton} from '@mui/lab';
import FunctionClient from '../../clients/Functions';
import mixpanel from 'mixpanel-browser';

const AccountDoodleRequestOrderScreen = ({orderId}) => {
  const [loading, setLoading] = useState(true);
  const [cancelling, setCancelling] = useState(false);
  const [order, setOrder] = useState(null);
  const fulfilled = useRef(false);
  const declined = useRef(false);
  const pending = useRef(false);

  useEffect(() => {
    fetchOrderAsync();
  }, [orderId]);

  const fetchOrderAsync = async () => {
    try {
      const docRef = doc(db, 'doodleRequests', orderId);

      const docSnap = await getDoc(docRef);

      fulfilled.current = docSnap.data().status === 'fulfilled';
      declined.current = docSnap.data().status === 'declined';
      pending.current = docSnap.data().status === 'pending';

      setOrder({
        ...docSnap.data(),
        id: docSnap.id,
      });

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleCancelOrderAsync = async () => {
    try {
      await getConfirmation({
        title: 'CANCEL ORDER?',
        text: 'This action can not be reversed!',
      });

      setCancelling(true);

      await FunctionClient.cancelOrderAsync(orderId);

      await fetchOrderAsync();

      setCancelling(false);

      mixpanel.track('Order Cancelled', {
        order: order.data().orderNumber,
      });
    } catch (err) {
      setCancelling(false);
    }
  };

  if (loading) return <LinearProgress />;

  if (!loading && !order) return <Navigate to={'/'} />;

  return (
    <div>
      <Stack spacing={2}>
        <Stack direction="row" spacing={0} alignItems="center">
          <ListItemAvatar>
            <Avatar src={getAvatarById(order.doodler)} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography style={{textTransform: 'uppercase'}}>
                <strong>
                  Commission for{' '}
                  <Link className="clickable" to={`/doodlers/${order.doodler}`}>
                    {order.doodlerName}
                  </Link>
                </strong>
              </Typography>
            }
            secondary={moment(order.createdAt.toDate()).format('ll')}
          />
        </Stack>
        <ListItemText
          primary={
            <Typography>
              <strong>ORDER#</strong> {order.orderNumber}
            </Typography>
          }
        />
        {order.doodleFor && (
          <ListItemText
            primary={
              <Typography>
                <strong>REQUESTED FOR</strong> {order.doodleFor}
              </Typography>
            }
          />
        )}
        {order.description && (
          <ListItemText
            primary={
              <Typography>
                <strong>INSTRUCTIONS</strong> "{order.description}"
              </Typography>
            }
          />
        )}

        {order.referenceImage && (
          <ListItemText
            primary={
              <Typography>
                <strong>REFERENCE IMAGE</strong>
              </Typography>
            }
            secondary={
              <div
                style={{
                  height: 69,
                  width: 69 * 0.75,
                  borderRadius: 5,
                  backgroundImage: `url(${order.referenceImage})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              />
            }
          />
        )}
        <ListItemText
          primary={
            <Typography style={{whiteSpace: 'nowrap'}}>
              <strong>STATUS</strong>{' '}
              <Typography component="span" className={`status-${order.status}`}>
                <strong>{(order.status || '').toUpperCase()}</strong>
              </Typography>
            </Typography>
          }
          secondary={
            fulfilled.current
              ? `Fulfilled ${moment(order.completedAt.toDate()).format('ll')}`
              : `Due before ${moment(order.createdAt.toDate())
                  .add(7, 'days')
                  .format('ll')}`
          }
        />
        <ListItemText
          primary={
            <Typography>
              <strong>ORDER TOTAL</strong> ${order.amount / 100}
            </Typography>
          }
        />

        {fulfilled.current && (
          <DoodleOrderCard order={order} doodleId={order.id} />
        )}

        {!fulfilled.current && (
          <Stack spacing={2}>
            <Typography variant="text" color="textSecondary">
              We've authorized your payment and placed a hold on your card until
              your Getadoodle is completed.
            </Typography>
            <Typography variant="text" color="textSecondary">
              <strong>What’s next?</strong>
            </Typography>
            <Typography variant="text" color="textSecondary">
              <ul style={{margin: '0 auto'}}>
                <li>
                  {order.doodlerName || 'No Name'} has 7 days to complete this
                  Getadoodle
                </li>
                <li>You'll receive an email when your Order is complete</li>
                <li>You can always check the status of your order here</li>
              </ul>
            </Typography>
            <Typography variant="text" color="textSecondary">
              Please reach out to{' '}
              <a href="mailto:help@getadoodle.com">help@getadoodle.com</a> if
              you are having any issues.
            </Typography>
          </Stack>
        )}

        {pending.current && (
          <div style={{width: '100%'}}>
            <br />
            <LoadingButton
              fullWidth
              color="error"
              onClick={handleCancelOrderAsync}
              variant="outlined"
              loading={cancelling}>
              CANCEL ORDER
            </LoadingButton>
          </div>
        )}
      </Stack>
      <br />
    </div>
  );
};

export default AccountDoodleRequestOrderScreen;

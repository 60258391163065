import React, {useCallback, useEffect, useState} from 'react';
import {db} from '../../clients/Firebase';
import {
  collection,
  query,
  getDocs,
  where,
  orderBy,
  limit,
} from 'firebase/firestore';
import DoodleRow from '../DoodleRow';

const MoreDoodlesFromThisDoodler = ({doodler, doodlerName, doodle}) => {
  const [doodles, setDoodles] = useState([]);

  const fetchDoodlesAsync = useCallback(async () => {
    const q = query(
      collection(db, 'doodles'),
      where('public', '==', true),
      where('doodler', '==', doodler),
      where('deleted', '==', false),
      orderBy('createdAt', 'desc'),
      limit(20),
    );

    const querySnapshot = await getDocs(q);

    const _doodles = [];

    querySnapshot.forEach(doc => {
      if (doc.id === doodle.id) return;

      _doodles.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    setDoodles(_doodles);
  }, [doodler]);

  useEffect(() => {
    fetchDoodlesAsync().catch(err => console.error(err));
  }, [fetchDoodlesAsync]);

  return <DoodleRow doodles={doodles} title={`More from ${doodlerName}`} />;
};

export default MoreDoodlesFromThisDoodler;

import React, {useEffect, useState} from 'react';
import {Container, Grid} from '@mui/material';
import {collection, getDocs, limit, query, where} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import DoodlerCard from '../../containers/DoodlerCard';
import PageHeader from '../../containers/PageHeader';

const DoodlesForGood = () => {
  const [charities, setCharities] = useState([]);

  useEffect(() => {
    getDocs(
      query(collection(db, 'users'), where('isCharity', '==', true)),
    ).then(snapshot => {
      setCharities(
        snapshot.docs.map(snap => ({
          id: snap.id,
          ...snap.data(),
        })),
      );
    });
  }, []);

  return (
    <Container maxWidth="lg">
      <PageHeader>Doodles for Good</PageHeader>
      <Grid container spacing={2}>
        {charities.map(charity => (
          <Grid key={charity.id} item sm={3} xs={12}>
            <DoodlerCard user={charity} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default DoodlesForGood;

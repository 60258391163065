import {
  Avatar,
  Container,
  Divider,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import algoliasearch from 'algoliasearch';
import PageHeader from '../../containers/PageHeader';
import FallbackAvatar from '../../components/FallbackAvatar';
import {Link, useParams} from 'react-router-dom';
import VerifiedBadge from '../../components/VerifiedBadge';

const client = algoliasearch('73PYMP09OE', 'dbb9a0290082c6b701d9b257a7aec306');
const index = client.initIndex('doodlers');

const SearchScreen = () => {
  const [searching, setSearching] = useState(false);
  const [hits, setHits] = useState([]);

  const {query} = useParams();

  useEffect(() => {
    handleQueryAsync();
  }, [query]);

  const handleQueryAsync = async () => {
    try {
      setSearching(true);
      const res = await index.search(query);
      setHits(res.hits);
      setSearching(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container maxWidth="sm">
      <PageHeader>Search Results: {query}</PageHeader>
      {searching && <LinearProgress />}
      {!searching && hits.length === 0 && (
        <Typography>No search results.</Typography>
      )}
      <Stack spacing={4}>
        {hits.map((hit, index) => (
          <div key={hit.objectID}>
            <Link
              to={`/${hit.isCharity ? 'charity' : 'doodlers'}/${
                hit.username || hit.objectID
              }`}>
              <Stack alignItems="center" spacing={2} direction="row">
                <div>
                  <Avatar sx={{width: 69, height: 69}} src={hit.avatar}>
                    <FallbackAvatar />
                  </Avatar>
                </div>
                <div>
                  <div
                    style={{
                      marginBottom: -6,
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    {hit.displayName}{' '}
                    {hit.verified && <VerifiedBadge width={18} />}
                  </div>
                  <Typography color="textSecondary">
                    {hit.mainCategory}
                  </Typography>
                </div>
              </Stack>
            </Link>
            {index < hits.length - 1 && (
              <Divider style={{marginBottom: -12, paddingBottom: 20}} />
            )}
          </div>
        ))}
      </Stack>
    </Container>
  );
};

export default SearchScreen;

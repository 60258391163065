import React, {useEffect, useState} from 'react';
import {Container, LinearProgress} from '@mui/material';
import {doc, onSnapshot} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import {useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import MoreDoodlesFromThisDoodler from '../../containers/MoreDoodlesFromThisDoodler';
import ProductsRow from '../../containers/ProductsRow';
import AppDrawer from '../../containers/AppDrawer';
import DoodleDetailColumns from '../../containers/DoodleDetailColumns';

const DoodleScreen = () => {
  const [doodle, setDoodle] = useState(null);
  const {doodleId} = useParams();

  useEffect(() => {
    setDoodle(null);

    const subscriber = onSnapshot(doc(db, `doodles`, doodleId), snapshot => {
      setDoodle({
        ...snapshot.data(),
        id: snapshot.id,
      });
    });

    return subscriber;
  }, [doodleId]);

  useEffect(() => {
    if (doodle) {
      window.prerenderReady = true;
    }
  }, [doodle]);

  if (!doodle) return <LinearProgress />;

  const title = doodle.title || doodle.name;

  const description = `${doodle.name} by ${doodle.doodlerName}`;

  return (
    <AppDrawer doodle={doodle}>
      <Container maxWidth="lg">
        <Helmet>
          <title>{doodle.name}</title>
          <meta
            name="description"
            content={`${doodle.name} by ${doodle.doodlerName}`}
          />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={doodle.uri} />
          <meta
            property="og:url"
            content={`https://getadoodle.com/doodles/${doodle.id}`}
          />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@getadoodle" />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content={doodle.uri} />
        </Helmet>

        <DoodleDetailColumns doodle={doodle} />
        <br />
        <ProductsRow doodle={doodle} />
        <br />
        <MoreDoodlesFromThisDoodler
          doodle={doodle}
          doodler={doodle.doodler}
          doodlerName={doodle.doodlerName}
        />
      </Container>
    </AppDrawer>
  );
};

export default DoodleScreen;

import {getIdToken, getAuth} from 'firebase/auth';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const host = 'https://us-central1-getadoodle-69.cloudfunctions.net/app';
// const host = 'http://localhost:5001/getadoodle-69/us-central1/app';

const parseResult = async res => {
  if (res.status >= 400) {
    const err = await res.json();

    throw new Error(err.err);
  }

  return res.json();
};

class FunctionClient {
  static createOrderAsync(payload) {
    return getIdToken(getAuth().currentUser).then(token => {
      headers['Authorization'] = token;

      return fetch(`${host}/create-order`, {
        method: 'POST',
        headers,
        body: JSON.stringify(payload),
      }).then(async res => {
        if (res.status >= 400) {
          const err = await res.json();

          throw new Error(err.raw.message);
        }

        return res.json();
      });
    });
  }

  static cancelOrderAsync(orderId) {
    return getIdToken(getAuth().currentUser).then(token => {
      headers['Authorization'] = token;

      return fetch(`${host}/cancel-request/${orderId}`, {
        method: 'DELETE',
        headers,
      }).then(async res => {
        if (res.status >= 400) {
          const err = await res.json();

          throw new Error(err.message);
        }

        return res.json();
      });
    });
  }

  static updateUsernameAsync(username) {
    return getIdToken(getAuth().currentUser).then(token => {
      headers['Authorization'] = token;

      return fetch(`${host}/username`, {
        method: 'POST',
        headers,
        body: JSON.stringify({username}),
      }).then(parseResult);
    });
  }

  static fetchShippingAsync(payload) {
    return getIdToken(getAuth().currentUser).then(token => {
      headers['Authorization'] = token;

      return fetch(`${host}/printful/shipping`, {
        method: 'POST',
        headers,
        body: JSON.stringify(payload),
      }).then(parseResult);
    });
  }

  static fetchTaxRatesAsync(payload) {
    return getIdToken(getAuth().currentUser).then(token => {
      headers['Authorization'] = token;

      return fetch(`${host}/printful/tax-rates`, {
        method: 'POST',
        headers,
        body: JSON.stringify(payload),
      }).then(parseResult);
    });
  }

  static fetchOrderEstimateAsync(payload) {
    return getIdToken(getAuth().currentUser).then(token => {
      headers['Authorization'] = token;

      return fetch(`${host}/printful/estimate-order-costs`, {
        method: 'POST',
        headers,
        body: JSON.stringify(payload),
      }).then(parseResult);
    });
  }

  static createPrintfulOrderAsync(payload) {
    return getIdToken(getAuth().currentUser).then(token => {
      headers['Authorization'] = token;

      return fetch(`${host}/printful/orders`, {
        method: 'POST',
        headers,
        body: JSON.stringify(payload),
      }).then(parseResult);
    });
  }

  static mintTokenAsync(payload) {
    return getIdToken(getAuth().currentUser).then(token => {
      headers['Authorization'] = token;

      return fetch(`${host}/mint-token`, {
        method: 'POST',
        headers,
        body: JSON.stringify(payload),
      }).then(parseResult);
    });
  }

  static connectWallet(payload) {
    return getIdToken(getAuth().currentUser).then(token => {
      headers['Authorization'] = token;

      return fetch(`${host}/connect-wallet`, {
        method: 'POST',
        headers,
        body: JSON.stringify(payload),
      }).then(parseResult);
    });
  }

  static syncToken(tokenId) {
    return getIdToken(getAuth().currentUser).then(token => {
      headers['Authorization'] = token;

      return fetch(`${host}/sync-token/${tokenId}`, {
        method: 'POST',
        headers,
      }).then(parseResult);
    });
  }

  static listCountries() {
    return fetch(`${host}/list-countries`, {
      method: 'GET',
      headers,
    }).then(async res => {
      if (res.status >= 400) {
        const err = await res.json();

        throw new Error(err.err);
      }

      return res.json();
    });
  }

  static getTaxes(payload) {
    return fetch(`${host}/taxjar/get-taxes`, {
      method: 'POST',
      headers,
      body: JSON.stringify(payload),
    }).then(async res => {
      if (res.status >= 400) {
        const err = await res.json();

        throw new Error(err.err);
      }

      return res.json();
    });
  }
}

export default FunctionClient;

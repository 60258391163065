import {
  Button,
  Container,
  Grid,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {doc, getDoc} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import PageHeader from '../../containers/PageHeader';
import Center from '../../containers/Center';
import {Link, useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';

const DoodleProductsScreen = () => {
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState(null);
  const {doodleId} = useParams();

  useEffect(() => {
    fetchDoodleProducts();
  }, [doodleId]);

  const fetchDoodleProducts = async () => {
    setLoading(true);
    const docRef = doc(db, 'products', doodleId);

    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setProduct(docSnap.data());
    }

    setLoading(false);
  };

  if (loading) return <LinearProgress />;
  if (!loading && !product)
    return (
      <Container maxWidth="lg">
        <PageHeader>No products available</PageHeader>
      </Container>
    );

  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>{product.title}</title>
        <meta name="description" content={product.title} />
        <meta property="og:title" content={product.title} />
        <meta property="og:description" content={product.title} />
      </Helmet>
      <PageHeader>{product.title}</PageHeader>
      <Grid container spacing={4}>
        {product.options.length === 0 && (
          <Center height={300}>
            <Typography color="textSecondary">No products available</Typography>
          </Center>
        )}
        {product.options.map((product, index) => (
          <Grid
            style={{textAlign: 'center'}}
            key={`p-${index}`}
            item
            xs={12}
            sm={6}
            md={4}>
            <img alt="product-thumbnail" src={product.thumbnail} />
            <Stack spacing={2} style={{marginTop: -24}}>
              <Typography variant="h5">{product.title}</Typography>
              <Link
                disabled
                to={{
                  pathname: `/doodles/${doodleId}/products/${product.variant_ids[0]}/purchase`,
                }}>
                <Button variant="contained" fullWidth>
                  PURCHASE ${product.price}
                </Button>
              </Link>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default DoodleProductsScreen;

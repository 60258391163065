import React from 'react';
import {
  Container,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import {Helmet} from 'react-helmet';
import PageHeader from '../../containers/PageHeader';

const FAQScreen = () => {
  return (
    <Container maxWidth="sm" className="blue-links">
      <Helmet>
        <title>General FAQ</title>
      </Helmet>
      <PageHeader>General FAQ</PageHeader>
      <List disablePadding>
        {questions.map((question, index) => (
          <ListItem disableGutters key={`q-${index}`}>
            <ListItemText
              primary={
                <Typography style={{fontWeight: 'bold'}}>
                  {question.q}
                </Typography>
              }
              secondary={
                <Typography
                  variant="text"
                  dangerouslySetInnerHTML={{__html: question.a}}
                />
              }
            />
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

export default FAQScreen;

const questions = [
  {
    q: 'Tell me about Getadoodle™',
    a: 'Getadoodle Inc. is a Global Art Technology company created by Geoff Abbott and Reed Seerman.',
  },
  {
    q: 'What is a Getadoodle™?',
    a: 'A Getadoodle is a one-of-a-kind, personalized work of digital art commissioned by the users of <a href="/">getadoodle.com</a>. ',
  },
  {
    q: 'How do I commission a Getadoodle?',
    a: 'Just log into your Getadoodle account (or create a new one), find a Doodler you like, click the "Request" button, fill out their commission form, and hit “Submit.” We\'ll put a temporary authorization hold on your payment method until the commission has been fulfilled.',
  },
  {
    q: 'How much does a Getadoodle cost?',
    a: 'Each Doodler has the ability to set their own Getadoodle price.',
  },
  {
    q: 'What should I put in my Getadoodle commission?',
    a: 'You are free to commission what you like, so long as your commission contains no links, no photos, and doesn’t ask Doodlers to create anything inappropriate or in violation of our <a href="/acceptable-use">Acceptable Use Policy</a>. Please note Doodlers reserve the right to decline any commissions at their discretion.',
  },
  {
    q: 'Do I need to have a Getadoodle account to use Getadoodle?',
    a: "If you're just browsing the site, you won't need to create an account. When you're ready to commission your first Getadoodle, we ask that you create an account so we can keep you posted on your commission's status and save your Getadoodles to your Getadoodle account.",
  },
  {
    q: 'How can I check the status of my Getadoodle?',
    a: 'You can check the status of your commission through the order link provided in your confirmation email. You can also check right from getadoodle.com - just log in, tap the profile icon in the upper right, then tap  ‘Orders’ from the drop-down menu.',
  },
  {
    q: 'Can I edit a commission after I send it?',
    a: 'If you would like to edit your commission please contact <a href="mailto:help@getadoodle.com">help@getadoodle.com</a>.',
  },
  {
    q: 'Can I cancel my Getadoodle commission?',
    a: "Yes, you can cancel from your “Orders” page on getadoodle.com. You'll just need to be signed in with the same account that you used to make your Getadoodle commission. The hold will be released from your payment method on our end so that you're not charged. This can take anywhere from 3-7 business days, depending on your bank.",
  },
  {
    q: 'How long to get my Getadoodle?',
    a: 'Doodlers have up to 7 days to fulfill Getadoodle commissions.',
  },
  {
    q: 'My commission expired! What do I do?',
    a: 'If you sent your commission through the website, the hold on your card should fall off in a few days depending on your bank.',
  },
  {
    q: 'How will I receive my Getadoodle?',
    a: "Once your commission has been fulfilled, you'll receive a confirmation email letting you know your Getadoodle is ready. From this email, you will see several options, including options for sharing or downloading to your device. You can also access all your Getadoodle orders from your “Orders” page on getadoodle.com.",
  },
  {
    q: "Is there a chance my Getadoodle won't be fulfilled?",
    a: 'It’s possible your commission could expire or be declined by the Doodler. If your commission expires or is vetoed, you\'ll receive an email letting you know. Feel free to reach out to our team at <a href="mailto:help@getadoodle.com">help@getadoodle.com</a> and we\'d be more than happy to help you commission a new Getadoodle.',
  },
  {
    q: 'I don’t live in the US. Can I still get a Getadoodle?',
    a: 'Absolutely.',
  },
  {
    q: 'Do I own my Getadoodle?',
    a: 'No. Getadoodles are licensed, not sold.',
  },
  {
    q: 'What can I do with my Getadoodle?',
    a: 'Personal, non-commercial, and non-promotional uses are all good so feel free to tattoo it, frame it, share it with friends, print it, sleep with it, post it on your socials…your call!',
  },
  {
    q: 'How do I Share my Getadoodle?',
    a: 'Tap any “Share” button on the website for options.',
  },
  {
    q: 'Do I get to keep my Getadoodle forever?',
    a: "Yes, it will live forever in your heart and also your Getadoodle account (so long as you don't delete said account).",
  },
  {
    q: 'Can I hide my Getadoodle from the Doodler’s public profile?',
    a: 'When making a commission, you will be given the option to hide your Getadoodle from the Doodler’s profile; if this option is selected, your Getadoodle will not show in any search results on our website or on the profile of the Doodler who created it. Please note your Getadoodle will still be visible on its Getadoodle webpage. This means anyone who accesses your Getadoodle link will be able to view and share your Getadoodle.',
  },
  {
    q: 'How does payment work for a Getadoodle?',
    a: "When you commission a Getadoodle we'll put a hold on your payment method for the total amount. Once the commission is completed, we'll charge your card. If the commission is declined or expires, we'll release the hold and you won't be charged.",
  },
  {
    q: 'Which payment methods are accepted?',
    a: 'AMEX, Mastercard, VISA, and PayPal.',
  },
  {
    q: 'Can I make my Getadoodle into an NFT?',
    a: 'No.',
  },
  {
    q: 'What is my Getadoodle’s “certification” number?',
    a: 'Each certification number is a unique IFPS (Interplanetary File System) hash representing the address where each Getadoodle’s transaction information is stored. Essentially, IFPS is a system for storing files on a permanent distributed network. Learn more <a href="https://ipfs.io/" target="_blank">here</a>.',
  },
  {
    q: 'Tell me all about the legal stuff.',
    a: 'You can check out our Terms of Service <a href="/terms-of-service">here</a>.',
  },
];

import {useCallback, useEffect, useState} from 'react';

const ContestTimeRemaining = ({date}) => {
  const [timeString, setTimeString] = useState('');

  const setTime = useCallback(() => {
    const dateFuture = +date.toDate();
    const dateNow = +new Date();

    let delta = Math.abs(dateFuture - dateNow) / 1000;

    // calculate (and subtract) whole days
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    let hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    let minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    // what's left is seconds
    let seconds = Math.floor(delta % 60);

    setTimeString(`${days}d, ${hours}h, ${minutes}m`);
  }, []);

  useEffect(() => {
    setTime();

    const interval = setInterval(() => {
      setTime();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [setTime]);

  return timeString;
};

export default ContestTimeRemaining;

import React from 'react';
import {Stack, Typography, Avatar} from '@mui/material';
import {getAvatarById} from '../../utils';
import FallbackAvatar from '../../components/FallbackAvatar';
import {Link} from 'react-router-dom';

const DoodlerFooter = ({doodle}) => (
  <Stack
    style={{marginTop: 8, width: '100%', overflow: 'hidden'}}
    direction="row"
    justifyContent="space-between"
    alignItems="center">
    <Stack
      alignItems="center"
      style={{width: '100%', paddingBottom: 12}}
      direction="row"
      spacing={1}>
      <div>
        <Link to={`/doodlers/${doodle.doodler}`}>
          <Avatar
            sx={{width: 28, height: 28}}
            src={getAvatarById(doodle.doodler)}>
            <FallbackAvatar />
          </Avatar>
        </Link>
      </div>
      <Stack
        style={{
          width: '100%',
          overflow: 'hidden',
          textAlign: 'left',
          whiteSpace: 'nowrap',
        }}
        alignItems="flex-start"
        justifyContent="center">
        <Typography className="doodle-title" variant="caption">
          <Link to={`/doodles/${doodle.id}`}>
            <strong>{doodle.title || doodle.name}</strong>
          </Link>{' '}
        </Typography>
        {doodle.requester && (
          <Typography
            textOverflow="ellipsis"
            variant="caption"
            style={{marginTop: -6}}>
            Commissioned by:{' '}
            <Link to={`/doodlers/${doodle.requester}`}>
              {doodle.requesterName}
            </Link>
          </Typography>
        )}
      </Stack>
    </Stack>
  </Stack>
);

export default DoodlerFooter;

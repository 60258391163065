import {createStore, applyMiddleware} from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducer from './reducers';
import rootAppSaga from './containers/App/sagas';
import web3Saga from './containers/Web3Wrapper/sagas';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(reducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(rootAppSaga);

sagaMiddleware.run(web3Saga);

export default store;

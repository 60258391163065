import {Container, Stack, useMediaQuery} from '@mui/material';
import React from 'react';
import ContestEntries from '../../containers/ContestEntries';

const ContestActiveScreen = ({contest}) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  return (
    <React.Fragment>
      <Container>
        <Stack>
          <img
            src={
              isMobile
                ? require('../../assets/contest-1-banner-mobile-web.jpg')
                : require('../../assets/contest-1-banner-web.jpg')
            }
            style={{
              width: '100%',
              backgroundSize: 'cover',
            }}
          />
          <br />
          <ContestEntries contest={contest} />
        </Stack>
      </Container>
    </React.Fragment>
  );
};

export default ContestActiveScreen;

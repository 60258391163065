import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import {Link} from 'react-router-dom';
import {formattedTimeAgo, getAvatarById} from '../../utils';
import FallbackAvatar from '../../components/FallbackAvatar';
import {DeleteOutline} from '@mui/icons-material';
import React from 'react';

const ReplyLineItem = ({reply, user}) => {
  const handleDelete = () => {
    console.log('delete');
  };

  return (
    <ListItem style={{padding: '0px 0px 0px 44px', alignItems: 'flex-start'}}>
      <ListItemAvatar style={{minWidth: 34}}>
        <Link to={`/doodlers/${reply.userId}`}>
          <Avatar
            sx={{width: 24, height: 24}}
            alt={reply.displayName}
            src={getAvatarById(reply.userId)}>
            <FallbackAvatar />
          </Avatar>
        </Link>
      </ListItemAvatar>
      <ListItemText
        style={{marginTop: -1}}
        primary={
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Typography variant="caption" color="darkgrey">
              <strong>{reply.displayName}</strong>
            </Typography>
            {user && user.id === reply.userId && (
              <IconButton size="small" onClick={handleDelete}>
                <DeleteOutline style={{fontSize: 16}} color="warning" />
              </IconButton>
            )}
          </Stack>
        }
        secondary={
          <Stack style={{marginTop: -5}}>
            <Typography
              sx={{display: 'inline'}}
              component="span"
              variant="body2"
              color="text.primary">
              {reply.text}
            </Typography>
            <Typography color="darkgrey" variant="caption">
              {formattedTimeAgo(reply)}
            </Typography>
          </Stack>
        }
      />
    </ListItem>
  );
};

export default ReplyLineItem;

import React, {useCallback, useEffect, useState} from 'react';
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import DoodleRow from '../DoodleRow';

const DoodlerDoodlesRow = ({doodler}) => {
  const [doodles, setDoodles] = useState([]);

  const loadDoodlesAsync = useCallback(async () => {
    const q = query(
      collection(db, 'doodles'),
      where('doodler', '==', doodler.id),
      where('public', '==', true),
      orderBy('createdAt', 'desc'),
      limit(12),
    );

    const querySnapshot = await getDocs(q);

    const _doodles = [];

    querySnapshot.forEach(doc => {
      _doodles.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    setDoodles(_doodles);
  }, [doodler]);

  useEffect(() => {
    if (doodler) loadDoodlesAsync().catch(err => console.error(err));
  }, [loadDoodlesAsync]);

  return <DoodleRow doodles={doodles} title="Doodles" />;
};

export default DoodlerDoodlesRow;

import {Stack} from '@mui/material';
import CommentButton from '../CommentButton';
import LikeDoodleButton from '../LikeDoodleButton';
import React from 'react';
import DoodleMoreOptions from '../DoodleMoreOptions';
import SignWithSelfieIcon from '../../components/SignWithSelfieIcon';
import ShareDoodleButton from '../ShareDoodleButton';

const DoodleOptionsRow = ({doodle}) => (
  <Stack
    marginTop={1}
    alignItems="center"
    direction="row"
    justifyContent="space-between">
    <Stack direction="row">
      <CommentButton doodle={doodle} />
      <LikeDoodleButton doodle={doodle} />
      <ShareDoodleButton />
      {doodle.signatureImageUri && <SignWithSelfieIcon doodle={doodle} />}
    </Stack>
    <div style={{marginRight: -12}}>
      <DoodleMoreOptions doodle={doodle} />
    </div>
  </Stack>
);

export default DoodleOptionsRow;

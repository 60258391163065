const {Stack} = require('@mui/material');
const React = require('react');
const SocialRow = ({isMobile}) => {
  const marginRight = isMobile ? 24 : 0;

  return (
    <Stack
      justifyContent={isMobile ? 'flex-start' : 'space-between'}
      spacing={2}
      direction="row">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://discord.gg/5k2JCvjZTU">
        <img
          alt="Discord"
          style={{width: 40, marginRight}}
          src={require('../../assets/social/discord.png')}
        />
      </a>
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="https://instagram.com/getadoodle">
        <img
          alt="Instagram"
          style={{width: 40, marginRight}}
          src={require('../../assets/social/instagram.png')}
        />
      </a>
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="https://facebook.com/getadoodle">
        <img
          alt="Fscebook"
          style={{width: 40, marginRight}}
          src={require('../../assets/social/facebook.png')}
        />
      </a>
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="https://tiktok.com/@getadoodle">
        <img
          alt="Tik Tok"
          style={{width: 40, marginRight}}
          src={require('../../assets/social/tiktok.png')}
        />
      </a>
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="https://twitter.com/getadoodle">
        <img
          alt="Twitter"
          style={{width: 40}}
          src={require('../../assets/social/twitter.png')}
        />
      </a>
    </Stack>
  );
};

export default SocialRow;

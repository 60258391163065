import React, {useState} from 'react';
import {
  Button,
  Container,
  Typography,
  Stack,
  useMediaQuery,
  Tab,
  Tabs,
  Box,
} from '@mui/material';
import {createStructuredSelector} from 'reselect';
import {makeSelectUser} from '../../containers/App/selectors';
import {connect} from 'react-redux';
import Center from '../../containers/Center';
import {Link} from 'react-router-dom';
import Avatar from '../../components/Avatar';
import DoodlerDoodlesRow from '../../containers/DoodlerDoodlesRow';
import ProfileTabCollected from '../../containers/ProfileTabCollected';

const ProfileScreen = ({user}) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [value, setValue] = useState('collected');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="lg">
      <br />
      <br />
      <Container disableGutters maxWidth="sm">
        <Center
          style={{
            height: 500,
            textAlign: 'center',
          }}>
          <Stack
            style={{
              maxWidth: 500,
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            spacing={3}>
            <Avatar
              width={isMobile ? 141 : 160}
              alt={user.displayName}
              src={user.avatar || require('../../assets/avatar.svg')}
            />
            <Typography variant="h3">{user.displayName}</Typography>
            <Typography>{user.bio}</Typography>
            <Link style={{width: '100%'}} to={`/account/profile/edit`}>
              <Button size="large" fullWidth variant="contained">
                EDIT PROFILE
              </Button>
            </Link>
          </Stack>
        </Center>
      </Container>
      <br />
      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <Tabs
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example">
          {user.doodler && <Tab label="Created" value="created" />}
          <Tab label="Collected" value="collected" />
        </Tabs>
      </Box>
      {value === 'created' && <DoodlerDoodlesRow doodler={user} />}
      {value === 'collected' && <ProfileTabCollected user={user} />}
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileScreen);

import {createSelector} from 'reselect';

const selectAppDrawer = state => state.appDrawer;

const makeSelectIsDrawerShowing = () =>
  createSelector(
    selectAppDrawer,

    appState => appState.isDrawerShowing,
  );

const makeSelectCommentDoodleId = () =>
  createSelector(
    selectAppDrawer,

    appState => appState.commentDoodleId,
  );

export {makeSelectIsDrawerShowing, makeSelectCommentDoodleId};

import React from 'react';
import {Link} from 'react-router-dom';
import DoodleImage from '../../components/DoodleImage';
import DoodlerFooter from '../DoodlerFooter';

const DoodleItem = ({doodle}) => {
  return (
    <div>
      <Link
        to={{
          pathname: `/doodles/${doodle.id}`,
        }}>
        <DoodleImage doodle={doodle} />
      </Link>
      <DoodlerFooter doodle={doodle} />
    </div>
  );
};

export default DoodleItem;

import React from 'react';
import {Container, Stack, Typography, useMediaQuery} from '@mui/material';
import {Helmet} from 'react-helmet';
import {APP_LOGO} from '../../constants';
import Center from '../../containers/Center';
import AppStoreButton from '../../components/AppStoreButton';

const AboutScreen = () => {
  const isDesktop = useMediaQuery('(min-width:600px)');

  return (
    <div style={{marginBottom: -33}}>
      <Helmet>
        <title>About</title>
        <meta property="og:image" content={APP_LOGO} />
      </Helmet>
      <div
        style={{
          backgroundImage: `url(${
            isDesktop
              ? require('../../assets/about/947y4uye.png')
              : require('../../assets/about/mobile-app-banner.webp')
          })`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}>
        <Center className="about-section" style={{paddingTop: 0}} />
      </div>

      <Center
        style={{
          backgroundColor: '#1d1d1d',
          backgroundSize: 'cover',
          backgroundImage: `url(${require('../../assets/about/bg-option-2.png')})`,
        }}
        className="about-section">
        <Typography
          color="white"
          variant="h3"
          style={{textAlign: 'center', paddingLeft: 6, paddingRight: 6}}>
          Personalized doodles from your favorite people.
        </Typography>
      </Center>

      <Container
        className="about-section"
        maxWidth="lg"
        style={{paddingRight: 0}}>
        <Stack spacing={4}>
          <Typography variant="h3">
            For Fans{' '}
            <span style={{color: 'darkgray'}}>
              Digital pop-culture collectibles.
            </span>
          </Typography>
          <br />
          <div style={{height: 330, overflow: 'hidden'}}>
            <div style={{overflowX: 'auto', overflowY: 'hidden', height: 350}}>
              <div
                style={{
                  width: isDesktop ? 1152 : 990,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <img
                  style={{width: 307}}
                  src={require('../../assets/about/975099489.png')}
                  alt="1"
                />
                <img
                  style={{width: 307}}
                  src={require('../../assets/about/975099491.png')}
                  alt="2"
                />
                <img
                  style={{width: 307}}
                  src={require('../../assets/about/975099490.png')}
                  alt="3"
                />
              </div>
            </div>
          </div>
        </Stack>
      </Container>

      <div style={{backgroundColor: '#1d1d1d'}} className="about-section">
        <Container maxWidth="lg">
          <Typography color="lightGrey" variant="h3">
            <span style={{color: '#3797F0'}}>For Creators</span> A fun new way
            to interact with your audience.
          </Typography>
          <br />
          <br />
          <br />
          <br />
          <div style={{textAlign: 'center'}}>
            <img
              style={
                isDesktop
                  ? {height: 102, width: 'auto'}
                  : {
                      transform: 'rotate(320deg)',
                      marginTop: 60,
                      height: 'auto',
                      width: '100%',
                    }
              }
              src={require('../../assets/about/Apple_Pencil_Mockup.png')}
              alt="3"
            />
          </div>
        </Container>
      </div>

      <div
        style={{
          backgroundImage: `url(${require('../../assets/about/63a2ab42ea6a38ecbc9ad60c-2.png')})`,
          backgroundSize: 'cover',
        }}>
        <div
          style={{backgroundColor: 'rgba(255,93,79,0.8)'}}
          className="about-section">
          <Container maxWidth="lg" style={{paddingRight: 0}}>
            <Typography color="white" variant="h3">
              How it Works
            </Typography>
            <br />
            <div style={{height: 490, overflow: 'hidden'}}>
              <div
                style={{overflowX: 'auto', overflowY: 'hidden', height: 510}}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: isDesktop ? 1152 : 990,
                    textAlign: 'center',
                  }}>
                  <Stack spacing={2}>
                    <img
                      style={{width: 225}}
                      src={require('../../assets/about/Frame 975099479.png')}
                      alt="Commissions"
                    />
                    <Typography color="white" fontWeight={600}>
                      Accept Commission
                    </Typography>
                  </Stack>
                  <Stack spacing={2}>
                    <img
                      style={{width: 225}}
                      src={require('../../assets/about/Frame 975099480.png')}
                      alt="Commissions"
                    />
                    <Typography color="white" fontWeight={600}>
                      Fulfill Request
                    </Typography>
                  </Stack>
                  <Stack spacing={2}>
                    <img
                      style={{width: 225}}
                      src={require('../../assets/about/Frame 975099481.png')}
                      alt="Commissions"
                    />
                    <Typography color="white" fontWeight={600}>
                      Sign w/ Selfie
                    </Typography>
                  </Stack>
                  <Stack spacing={2}>
                    <img
                      style={{width: 225}}
                      src={require('../../assets/about/Frame 975099503.png')}
                      alt="Commissions"
                    />
                    <Typography color="white" fontWeight={600}>
                      Get Paid!
                    </Typography>
                  </Stack>
                </div>
              </div>
            </div>
          </Container>
          <br />
          <br />
        </div>
      </div>

      <div
        style={{
          backgroundImage: `url(${require('../../assets/about/639e71151ccbb24847169ed0-2.png')})`,
          backgroundSize: 'cover',
        }}>
        <div
          style={{backgroundColor: 'rgba(29,29,29,0.8)'}}
          className="about-section">
          <br />
          <Container maxWidth="lg">
            <Typography color="lightGrey" variant="h3">
              <span style={{color: '#FEE133'}}>Fair Play</span> Creators make a
              75% cut of each transaction.
            </Typography>
            <br />
            <br />
            <div style={{height: 360, overflow: 'hidden'}}>
              <div
                style={{overflowX: 'auto', height: 380, overflowY: 'hidden'}}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    textAlign: 'center',
                    width: isDesktop ? 1152 : 970,
                  }}>
                  <Stack spacing={2}>
                    <img
                      style={{width: 307}}
                      src={require('../../assets/about/9750995070.png')}
                      alt="Commissions"
                    />
                    <Typography color="white">
                      <strong>Digital Commissions</strong>
                    </Typography>
                  </Stack>
                  <Stack spacing={2}>
                    <img
                      style={{width: 307}}
                      src={require('../../assets/about/framed-print.webp')}
                      alt="Framed Print"
                    />
                    <Typography color="white">
                      <strong>Framed Print</strong>
                    </Typography>
                  </Stack>
                  <Stack spacing={2}>
                    <img
                      style={{width: 307}}
                      src={require('../../assets/about/unisex-staple-t-shirt-white-front-6424aad26ab7c.webp')}
                      alt="Merchandise"
                    />
                    <Typography color="white">
                      <strong>Merchandise</strong>
                    </Typography>
                  </Stack>
                </div>
              </div>
            </div>
          </Container>
          <br />
          <br />
        </div>
      </div>

      <div
        style={{
          backgroundImage: isDesktop
            ? `url(${require('../../assets/about/get-the-app-bg.webp')})`
            : `url(${require('../../assets/about/mobile-web-footer.webp')})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}>
        <Center className="about-section">
          <Stack alignItems="center" spacing={8}>
            <Typography variant="h3">
              Download <span style={{color: 'darkgray'}}>the App</span>
            </Typography>
            <br />
            <AppStoreButton />
          </Stack>
        </Center>
      </div>
    </div>
  );
};

export default AboutScreen;

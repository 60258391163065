import React from 'react';
import {useEffect, useState} from 'react';
import {Grid, Typography} from '@mui/material';
import {collection, getDocs, orderBy, query, where} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import Center from '../Center';
import DoodleGrid from '../DoodleGrid';

const CharitySupporters = ({charityId}) => {
  const [doodles, setDoodles] = useState([]);

  useEffect(() => {
    getDocs(
      query(
        collection(db, 'doodles'),
        where('charity', '==', charityId),
        where('deleted', '==', false),
        where('public', '==', true),
        orderBy('createdAt', 'desc'),
      ),
    )
      .then(snap => {
        setDoodles(snap.docs.map(doc => ({id: doc.id, ...doc.data()})));
      })
      .catch(err => {
        console.error(err);
      });
  }, [charityId]);

  return (
    <div>
      {!doodles.length && (
        <Center height={300}>
          <Typography color="lightGrey">No supporters</Typography>
        </Center>
      )}
      <DoodleGrid doodles={doodles} />
    </div>
  );
};

export default CharitySupporters;

import {
  CONNECT_WEB3,
  SET_CHAIN,
  SET_CONNECTED_WALLET,
  SET_ETH_BALANCE,
  SET_GETADOODLE_CONTRACT,
  SET_PROVIDER,
  SET_WEB3,
} from './constants';

export const setWeb3 = web3 => ({
  type: SET_WEB3,
  web3,
});

export const connectWeb3 = () => ({
  type: CONNECT_WEB3,
});

export const setProvider = provider => ({
  type: SET_PROVIDER,

  provider,
});

export const setChain = chainId => ({
  type: SET_CHAIN,

  chainId,
});

export const setConnectedWallet = connectedWallet => ({
  type: SET_CONNECTED_WALLET,

  connectedWallet,
});

export const setEthBalance = ethBalance => ({
  type: SET_ETH_BALANCE,

  ethBalance,
});

export const setGetadoodleContract = getadoodleContract => ({
  type: SET_GETADOODLE_CONTRACT,

  getadoodleContract,
});

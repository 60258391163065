import React from 'react';
import {Container, Typography} from '@mui/material';
import Center from '../../containers/Center';

const FourOhFourScreen = () => {
  return (
    <Container>
      <Center height={window.innerHeight - 200}>
        <img
          alt="four-oh-four"
          style={{width: '100%', maxWidth: 600}}
          src={require('../../assets/404.png')}
        />
      </Center>
    </Container>
  );
};

export default FourOhFourScreen;

import React from 'react';
import {
  Container,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import {Helmet} from 'react-helmet';
import PageHeader from '../../containers/PageHeader';
import {APP_LINK} from '../../constants';

const DoodlerFAQScreen = () => {
  return (
    <Container maxWidth="sm" className="blue-links">
      <Helmet>
        <title>Talent FAQ</title>
      </Helmet>
      <PageHeader>Talent FAQ</PageHeader>
      <List disablePadding>
        {questions.map((question, index) => (
          <ListItem disableGutters key={`q-${index}`}>
            <ListItemText
              primary={
                <Typography style={{fontWeight: 'bold'}}>
                  {question.q}
                </Typography>
              }
              secondary={
                <Typography
                  variant="text"
                  dangerouslySetInnerHTML={{__html: question.a}}
                />
              }
            />
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

export default DoodlerFAQScreen;

const questions = [
  {
    q: 'How can I sell Getadoodles™?',
    a: `Download the official <a href="${APP_LINK}" target="_blank">Getadoodle Artist App</a> and activate your account.`,
  },
  {
    q: 'What is Getadoodle Pro?',
    a: 'Getadoodle Pro is a private invite iOS mobile application that includes everything you need to manage commissions, get paid, and create and send Getadoodles.',
  },
  {
    q: 'What devices can I use for the Getadoodle Pro mobile application?',
    a: 'Getadoodle Pro is only available for iPhones and iPads.',
  },
  {
    q: 'When will Getadoodle Pro be available for Android devices?',
    a: 'Soon!',
  },
  {
    q: 'How do I complete a Getadoodle?',
    a: 'All commissions are fulfilled in the Getadoodle mobile app. From the home screen of the app, under “Requests,” select an order then click the “Create” button. Here you’ll be prompted to create your Getadoodle. When you’re ready, press the paper plane button at the bottom of the screen and your work will be delivered instantly!',
  },
  {
    q: "Can I extend the amount of time I have to fulfill a commission for a Getadoodle so it doesn't expire?",
    a: 'Not at this time. The response time must be within 7 days.',
  },
  {
    q: 'How do I know if the purchaser received their Getadoodle?',
    a: 'From the home screen of the App, select “Wallet” then, under “Activity,” select an order for all the details including the “certification” number representing the decentralized storage hash where the transaction and artwork are stored. Learn more about IFPS <a href="https://ipfs.io" target="_blank">here</a>.',
  },
  {
    q: 'How do I decline a Request?',
    a: 'From the App homepage, under “Requests,” select the order then click the “Decline Request” text at the bottom of the screen. You’ll be prompted to provide a reason for declining. All good.',
  },
  {
    q: 'How do I change my Price per Doodle?',
    a: 'app home page  > view profile > edit profile > price',
  },
  {
    q: 'How do I change my Profile Photo?',
    a: 'app home page  > view profile > edit profile > change profile pic',
  },
  {
    q: 'How do I change my Bio?',
    a: 'app home page  > view profile > edit profile > bio',
  },
  {
    q: 'How do I share my Getadooodle Profile?',
    a: 'app home page > view profile> share',
  },
  {
    q: 'How do I pause my Doodler Account?',
    a: 'Pause your account via the “Taking Requests” toggle on the app homepage. A toggle set to “on” means you’re open for business, a toggle set to “off” means your Doodler profile will say “Temporarily Unavailable.”',
  },
  {
    q: 'How much do I earn for each Getadoodle?',
    a: 'For Doodles commissioned through the Getadoodle website, you’ll receive 75%. The remaining 25% goes to Getadoodle and covers service, transaction, and order processing fees.',
  },
  {
    q: 'Who owns the Getadoodle?',
    a: 'You own your IP. Users are buying the right (or license) to use it, not the actual Getadoodle itself. ',
  },
  {
    q: 'How can purchasers use their Getadoodle?',
    a: 'Because Getadoodles are licensed, not sold, they can only be used for personal, non-commercial, and non-promotional purposes by the purchasers. Please note that we plan to introduce an array of Getadoodle purchase options in the future.',
  },
  {
    q: 'Is it possible to limit the number of commissions I accept?',
    a: 'Yep!  From the mobile app homepage select “View Profile,” then select “Edit Profile,” then select “Doodle Request Limit.”  From here you can set the max number of Doodle commissions you’re willing to accept at a given time.',
  },
  {
    q: 'How do I hide a Getadoodle from my profile?',
    a:
      'Before placing an order, purchasers have the option to hide their Getadoodler from a Doodlers page. If they choose this option, you will not be able to make the Getadoodle available on your Doodler profile. However, if the purchaser did not choose to hide their Getadoodle from your page, you will have the option to do so.' +
      ' ' +
      'The purchaser’s decision, and your options, will be reflected on the Getadoodle mobile application at the bottom of your Getadoodle “Submit” screen at the time of fulfillment.' +
      ' ' +
      'Please note that all Getadoodles are visible on their Getadoodle webpage. This means anyone who accesses a Getadoodle link will be able to view and share said Getadoodle.',
  },
  {
    q: 'Can I resell or commercialize my Getadoodles?',
    a: 'You may not sell, resell, commercialize, or encumber your rights in any Getadoodle Offering, including creating a non-fungible token (“NFT”) from any Getadoodle or other offerings except as agreed by Getadoodle in writing. ',
  },
  {
    q: 'Tell me all about the legal stuff.',
    a: 'You can check out our Doodler Terms of Service <a href="/doodler-terms-of-service">here</a>.',
  },
];

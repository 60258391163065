import React from 'react';
import {useEffect, useState} from 'react';
import {Grid, Typography} from '@mui/material';
import DoodlerCard from '../DoodlerCard';
import {collection, getDocs, orderBy, query, where} from 'firebase/firestore';
import {db} from '../../clients/Firebase';
import Center from '../Center';

const CharityDoodlers = ({charityId}) => {
  const [doodlers, setDoodlers] = useState([]);

  useEffect(() => {
    getDocs(
      query(
        collection(db, 'users'),
        where('charity', '==', charityId),
        where('activated', '==', true),
        orderBy('displayName', 'asc'),
      ),
    )
      .then(snap => {
        setDoodlers(snap.docs.map(doc => ({id: doc.id, ...doc.data()})));
      })
      .catch(err => {
        console.error(err);
      });
  }, [charityId]);

  return (
    <Grid spacing={2} container>
      {!doodlers.length && (
        <Center height={300}>
          <Typography color="lightGrey">No talent available</Typography>
        </Center>
      )}
      {doodlers.map(doodler => (
        <Grid key={doodler.id} item xs={6} sm={4} md={3}>
          <DoodlerCard user={doodler} />
        </Grid>
      ))}
    </Grid>
  );
};

export default CharityDoodlers;

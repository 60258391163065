import React, {useState} from 'react';
import {createStructuredSelector} from 'reselect';
import {makeSelectUser} from '../../containers/App/selectors';
import {connect} from 'react-redux';
import {Container, Stack, TextField, Typography} from '@mui/material';
import {getAuth, updateEmail, sendEmailVerification} from 'firebase/auth';
import PageHeader from '../../containers/PageHeader';
import {LoadingButton} from '@mui/lab';
import {Link} from 'react-router-dom';

const AccountScreen = ({user, connectedWallet}) => {
  const [email, setEmail] = useState(user.email);
  const [loading, setLoading] = useState(false);

  const changeEmail = async () => {
    try {
      setLoading(true);

      const auth = getAuth();

      await updateEmail(auth.currentUser, email);

      setLoading(false);

      alert('Email updated');
    } catch (err) {
      setLoading(false);

      alert(err.message);
    }
  };

  const resendVerificationEmail = async () => {
    try {
      const auth = getAuth();

      await sendEmailVerification(auth.currentUser);

      alert('Check your email for verification link');
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <Container maxWidth="sm">
      <PageHeader>Account</PageHeader>
      <Stack spacing={2}>
        <TextField
          label="Email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          variant="outlined"
        />
        {!user.emailVerified && (
          <Typography color="red" variant="text">
            *Please verify your email address. Click{' '}
            <span className="clickable" onClick={resendVerificationEmail}>
              here
            </span>{' '}
            to resend email verification email.
          </Typography>
        )}
        <LoadingButton
          loading={loading}
          disabled={user.email === email}
          size="large"
          variant="contained"
          onClick={changeEmail}>
          SAVE
        </LoadingButton>
        <Typography>
          Get your referral code{' '}
          <Link className="clickable" to="/account/referrals">
            here
          </Link>
          .
        </Typography>
      </Stack>
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});

export default connect(mapStateToProps, null)(AccountScreen);
